import React, {useState,useEffect}  from 'react';
import './LoginModal.css'
import { Modal } from 'antd';
import {LoginApi} from '../../api'
import { WechatLogin, LoginWithPhone, WechatFirstLogin } from './components'
import queryString from 'query-string'

const customStyles = {
  content : {
    
    display: 'flex',
    flex: 1,
    padding: 0,
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: 0, 
    left: 0, 
    right: 0, 
    bottom: 0,
    zIndex: 999,
  }
};

function LoginModal(props)  {

  const [modelIsOpen, setModelIsOpen] = useState(false);
  const [loginStyle, setLoginStyle] = useState(0);
  const [wechatData, setWechatData] = useState(null);
  const locationSearch = queryString.parse(window.location.search);

  useEffect(()=> {
    setModelIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(()=>{
    if(!locationSearch['code']) {
      return
    }
    getWechatInfo(locationSearch['code'])
    if(!localStorage.getItem('access_token')) {
      setLoginStyle(2)
      props.setModalStatus(true)
    }
  }, [locationSearch['code']]);

  const getWechatInfo = async(code) => {
    try {
      const userId = JSON.parse(localStorage.getItem('userInfo')).id
      const res = await LoginApi.wechatLogin(code, userId)
      if(res && res.success) {
        var data = res.data
        if(userId) {
          window.location.search = ""
          return
        }
        if(data['firstLogin']) {
          setWechatData({
            "openid": data['openid'],
            "nickname": data['nickname'],
            "sex": data['sex'],
            "headimgurl": data['headimgurl'],
            "unionid": data['unionid']})
        } else {
          localStorage.setItem("access_token", data.access_token)
          localStorage.setItem("userInfo", JSON.stringify(data.user))
          props.setModalStatus(false)
          window.location.search = ""
        }
      }
    }catch(e) {
      console.log(e, "err")
    }
  }

  const closeModal = () => {
    props.setModalStatus(false)
    setLoginStyle(0)
    if(locationSearch['code']) {
      window.location.search = ""
    }
  }

  const returnLoginCom = () => {
    switch(loginStyle) {
      case 0: 
        return (<LoginWithPhone onCloseModal={() => props.setModalStatus(false)} linkClick={() => props.linkClick()} setLoginStyle={setLoginStyle} />);
        break;
      case 1:
        return (<WechatLogin onCloseModal={() => props.setModalStatus(false)}/>);
        break;
      case 2:
        return (<WechatFirstLogin wechatData={wechatData} onCloseModal={()=>props.setModalStatus(false)} linkClick={() => props.linkClick()}/>)
    }
  }
  
    return (
      <Modal
        visible={modelIsOpen}
        bodyStyle={customStyles}
        width={391}
        onCancel={()=> closeModal()}
        footer={null}
      >
        <div className='login-modal'>
          {returnLoginCom()}
        </div>
      </Modal>
    )
}

export default LoginModal