import Axios from './Axios'
import {HywApi} from './HywApi'

class Note {
    //根据医院名称搜索医院科室
    getHosInfo = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getHosInfo, query)
                .then((res) => {
                    // console.log(res, HywApi.getHosInfo, 'getHosInfo');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getHosInfo, 'err----');
                    reject(err)
                })
        })
    }
    //根据医院科室姓名搜索医生
    getDocInfoByHos = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getDocInfoByHos, query)
                .then((res) => {
                    // console.log(res, HywApi.getDocInfoByHos, 'getDocInfoByHos');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getDocInfoByHos, 'err----');
                    reject(err)
                })
        })
    }

    //疾病症状
    getSickByName = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getSickByName, query)
                .then((res) => {
                    // console.log(res, HywApi.getDocInfoByHos, 'getDocInfoByHos');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getDocInfoByHos, 'err----');
                    reject(err)
                })
        })
    }

    //获取所有治疗方式
    getTreatOptions = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getTreatOptions, query)
                .then((res) => {
                    // console.log(res, HywApi.getTreatOptions, 'getTreatOptions');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getTreatOptions, 'err----');
                    reject(err)
                })
        })
    }

    //获取笔记2.3.17版本新增字段的枚举项
    getNoteNewDataOptions = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getNoteNewDataOptions, query)
                .then((res) => {
                    // console.log(res, HywApi.getTreatOptions, 'getTreatOptions');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getTreatOptions, 'err----');
                    reject(err)
                })
        })
    }
    //  发布笔记
    sendNewNote = (query) => {
        return new Promise((resolve, reject) => {
            Axios.post(HywApi.sendNewNote, query)
                .then((res) => {
                    // console.log(res, HywApi.sendNewNote, 'sendNewNote');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.sendNewNote, 'err----');
                    reject(err)
                })
        })
    }
    //编辑笔记
    editNote = (query) => {
        return new Promise((resolve, reject) => {
            Axios.put(HywApi.sendNewNote, query)
                .then((res) => {
                    // console.log(res, HywApi.sendNewNote, 'sendNewNote');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.sendNewNote, 'err----');
                    reject(err)
                })
        })
    }
    //文件上传
    fileUpload = (file) => {
        const formData = new FormData();
        formData.append('fileName', file);
        return Axios.post('uploads?type=note', formData)
    }
    getNoteList = (page, per_page) => {
    return new Promise((resolve, reject) => {
    Axios.get(HywApi.userNotes + '?page=' + page +'&per_page=' + per_page)
    .then((res) => {
    resolve(res)
})
    .catch((e) => {
    reject(e)
})
})
}
    getNoteDraftList = (page, per_page) => {
    return new Promise((resolve, reject) => {
    Axios.get(HywApi.userDraftNotes + '?page=' + page +'&per_page=' + per_page)
    .then((res) => {
    resolve(res)
})
    .catch((e) => {
    reject(e)
})
})
}
    deleteNote = (data) => {
        return new Promise((resolve, reject) => {
        Axios.delete(HywApi.userNotes, data).then((res)=>{
        resolve(res)
    })
        .catch((e)=>{
        reject(e)
    })
    })
    }
    deleteNoteDraft = (data) => {
    return new Promise((resolve, reject) => {
    Axios.delete(HywApi.userDraftNotes, data).then((res)=>{
    resolve(res)
})
    .catch((e)=>{
    reject(e)
})
})
}
    getTags = (
    positive
    )=> {
    return new Promise((resolve, reject) => {
        let query = {
            positive: positive
        }
    Axios.get(HywApi.getTags, query)
    .then((res) => {
    resolve(res)
})
    .catch((e) => {
    reject(e)
})
})
}
    sendEvaluate = (
        total,
        d_service,
        treat_level,
        d_labels,
        h_service,
        convenient,
        facility,
        environment,
        transport,
        h_labels,
        id,
        )=> {
        let query = {
        total: total,
        d_service: d_service,
        treat_level: treat_level,
        d_labels: d_labels,
        h_service: h_service,
        convenient: convenient,
        facility: facility,
        environment: environment,
        transport: transport,
        h_labels: h_labels,
    };
        return new Promise((resolve, reject) => {
        Axios.post(HywApi.sendEvaluate +"/"+id+"/comments",query )
        .then((res) => {
        resolve(res)
    })
        .catch((e) => {
        reject(e)
    })
    })
    }
    commitEvaluateNew = (postData) => {
        return new Promise((resolve, reject) => {
            Axios.post(HywApi.COMMIT_EVALUATE_NEW,postData)
                .then((res) => {
                    resolve(res)
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }
    fetchNoteDetail = (id) => {
        return new Promise((resolve, reject) => {
        Axios.get(`${HywApi.noteDetail}/${id}`).then((res)=>{
        resolve(res)
    }).catch((e)=>{
        reject(e)
    })
    })
    }
    getComments = (
        page,
        per_page,
        owner_id,
        classify,
        )=> {
        return new Promise((resolve, reject) => {
        Axios.get(HywApi.addComments+'?page=' + page + '&per_page=' + per_page + '&owner_id=' + owner_id + '&classify=' + classify)
        .then((res) => {
        resolve(res)
    })
        .catch((e) => {
        reject(e)
    })
    })
    }
    getCommentReply = (
        page,
        per_page,
        comment_id
        )=> {
        return new Promise((resolve, reject) => {
        Axios.get(HywApi.getCommentReply+'?page=' + page + '&per_page=' + per_page + '&comment_id=' + comment_id)
        .then((res) => {
        resolve(res)
    })
        .catch((e) => {
        reject(e)
    })
    })
    }

    //笔记单个文章详情
    getArticleDetail = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getArticleDetail, query)
                .then((res) => {
                    // console.log(res, HywApi.getArticleDetail, 'getArticleDetail');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getArticleDetail, 'err----');
                    reject(err)
                })
        })
    }
    //  获取笔记图库
    getNoteHosPic = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getNoteHosPic, query)
                .then((res) => {
                    // console.log(res, HywApi.getNoteHosPic, 'getNoteHosPic');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getNoteHosPic, 'err----');
                    reject(err)
                })
        })
    }
    //PC端获取上传二维码
    getPicUpLoadQrCode = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getPicUpLoadQrCode, query)
                .then((res) => {
                    // console.log(res, HywApi.getPicUpLoadQrCode, 'getPicUpLoadQrCode');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getPicUpLoadQrCode, 'err----');
                    reject(err)
                })
        })
    }
    //获取二维码上传结果
    getQrCodeImgResult = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getQrCodeImgResult, query)
                .then((res) => {
                    // console.log(res, HywApi.getQrCodeImgResult, 'getQrCodeImgResult');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getQrCodeImgResult, 'err----');
                    reject(err)
                })
        })
    }
    //保存笔记草稿
    saveNoteDraft = (query) => {
        return new Promise((resolve, reject) => {
            Axios.post(HywApi.saveNoteDraft, query)
                .then((res) => {
                    // console.log(res, HywApi.saveNoteDraft, 'saveNoteDraft');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.saveNoteDraft, 'err----');
                    reject(err)
                })
        })
    }

    //草稿笔记详情
    getDraftDetail = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getDraftDetail, query)
                .then((res) => {
                    // console.log(res, HywApi.getDraftDetail, 'getDraftDetail');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getDraftDetail, 'err----');
                    reject(err)
                })
        })
    }

    //获取医院主页信息
    getHosDetail = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getHosDetail + '/' + query.id, {})
                .then((res) => {
                    // console.log(res, HywApi.getDraftDetail, 'getDraftDetail');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getDraftDetail, 'err----');
                    reject(err)
                })
        })
    }
    //获取医生主页信息
    getDocInfo = (query) => {
        return new Promise((resolve, reject) => {
            Axios.get(HywApi.getDocInfo + '/' + query.id, {})
                .then((res) => {
                    // console.log(res, HywApi.getDraftDetail, 'getDraftDetail');
                    resolve(res)
                })
                .catch((err) => {
                    // console.log(err, HywApi.getDraftDetail, 'err----');
                    reject(err)
                })
        })
    }

}



export const NoteApi = new Note()
