import React, {useState,useEffect,useRef}  from 'react'
import  * as styles from './License.module.css'
// import { Images } from '../../utils/Images';
import { JZLicense } from '../../utils/Config'

function License() {

  return(
    <div className={styles.container}>
      <img className={styles.license_img} alt='' src={JZLicense} />
    </div>
  )
}

export default License
