

export const HywApi = {
  //获取验证码
  msgCode :  '/sms',   //get
  //登录
  accountLogin: '/login',
  //获取用户信息
  userInfo: '/users/',  //get
  //用户实名制
  auth: '/auth',  //post
  //获取相册图片
  atlas: '/atlas',
  //获取相册占用空间
  getImgSize: '/atlas/size', //get
  // 通过code拿微信登录信息
  wechatLogin: '/wechat',
  //获取消息列表
  getNews: '/news',
  //未读消息数
  notReadNews: '/news/count',
  //根据医院名称搜索医院科室
  getHosInfo: '/note/hospital/departments',
  //根据医院科室姓名搜索医生
  getDocInfoByHos: '/note/doctors',
  //疾病症状
  getSickByName: '/note/sickness',
  //获取所有治疗方式
  getTreatOptions: '/note/treats',
  //获取笔记2.3.17版本新增字段的枚举项
  getNoteNewDataOptions: '/treat/options',
//  发布笔记
  sendNewNote: '/notes',
  //点赞或者取消点赞
  commentOperation: '/comment/support',
  //添加或者回复评论, 获取某个内容下面的评论
  addComments: '/comments', //post
  // 发布笔记数
  userNotes: '/user/notes',
  // 草稿箱笔记数
  userDraftNotes: '/user/drafts',
  //获取笔记评价纬度和标签
  getTags: '/note/tags',

  //提交医生或医院的评价
  sendEvaluate: '/articles', //post
  //提交医生医院评价 新（2.3.17）
  COMMIT_EVALUATE_NEW: '/note/comments',
  // 笔记详细
  noteDetail: '/notes',
  // 获取评论下的所有回复
  getCommentReply: '/comments/replays',
  //笔记单个文章详情
  getArticleDetail:'/note/article/detail',
//  获取笔记图库
  getNoteHosPic: '/photos',
  //PC端获取上传二维码
  getPicUpLoadQrCode: '/qrcode',
  //获取二维码上传结果
  getQrCodeImgResult: '/polling',
  //保存笔记草稿
  saveNoteDraft: 'user/drafts',
  //草稿笔记详情
  getDraftDetail: 'note/draft/detail',
//获取医院主页信息
  getHosDetail: 'hospitals', //get
  //获取医生主页信息
  getDocInfo: 'doctors'
}
