import NoteAction from './NoteAction'

const defaultState = {
    //草稿列表数据
    draftNotes: [],
    //草稿总数
    draftTotal: 0,
    // 笔记列表
    noteList: [],
    // 笔记数量
    noteTotal: 0,

    currentModifyNote: null,
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case NoteAction.actions.GET_DRAFT_NOTE:
            return {...state,draftNotes:action.payload}
        case NoteAction.actions.GET_DRAFT_TOTAL:
            return {...state,draftTotal:action.payload}
        case NoteAction.actions.GET_NOTE_LIST:
            return {...state, noteList:action.payload}
        case NoteAction.actions.GET_NOTE_TOTAL:
            return {...state, noteTotal:action.payload}
        case NoteAction.actions.SET_CURRENT_MODIFY_NOTE:
            const curData = action.currentModifyNote
            return {...state,currentModifyNote:curData};
        default:
            return state;
    }
};

export default reducer;
