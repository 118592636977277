import React from 'react'
import  * as styles from './DoctorCard.module.css'
import {Images, Tools} from '@/utils'

function DoctorCard(props) {
    const { detail } = props
    return (
        <div className={styles.cardContentContainner}>
            <div className={styles.cardContent}>
                <img className={styles.cardImage} src={detail.doctor.picture || Images.note.doctorDefault} alt=""/>
                <div className={styles.cardInfo}>
                    <div className={styles.cardDoctorTitle}>{detail.doctor.name}</div>
                    <div className={styles.cardDoctorLevel}>{detail.doctor.department_name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{detail.doctor.title}</div>
                    <div className={styles.cardDoctorTotal}>
                        医生评价：
                        {detail.is_commented ?
                        <div className={styles.cardDoctorTotalStatus}>“{Tools.getHospitalTotal(detail.total)}”</div>
                        :
                        <div className={styles.cardDoctorCommented}>
                            作者暂未评价
                        </div>
                        }
                    </div>
                    <div className={styles.cardDoctorLabel}>{(detail.labels).map((res,index)=> {
                        if(index < 4){
                            return (<div className={styles.cardDoctorLabelItem} key={index}>{res.name}</div>)
                        }
                    })}</div>
                </div>


            </div>
            <div style={{marginTop: 36}}>
                {
                    detail?.is_commented  && <div className={styles.cardScore}>
                        {
                            detail?.levels?.map((i, ind) => {
                                return (
                                    <div className={styles.cardScoreItem} key={ind}>
                                        {i.label}:
                                        <img className={styles.cardScoreItemIcon} src={Tools.ScoreText[i.level]?.icon} alt=""/>
                                        <div className={styles.cardScoreItemText}>{Tools.ScoreText[i.level]?.text}</div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }
            </div>
        </div>
    )
}

export default DoctorCard
