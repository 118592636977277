import Axios from './Axios'
import {HywApi} from './HywApi'

class Login {
  getMsgCode = (tel) => {
    return new Promise((resolve, reject) => {
      
      Axios.get(HywApi.msgCode+ '?phone='+ tel)
          .then((res) => {
              console.log(res, HywApi.msgCode, 'getMsgCode');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.msgCode, 'err----');
              reject(err)
          })
    })
  }
  accountLogin = (tel,code,wechat_data) => {
    return new Promise((resolve, reject) => {
      const userData = {
        user: {
          phone: tel,
          sms_code: code,
          wechat_data: wechat_data
        }
      }
      
      Axios.post(HywApi.accountLogin, userData)
          .then((res) => {
              console.log(res, HywApi.msgCode, 'getMsgCode');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.msgCode, 'err----');
              reject(err)
          })
    })
  }
  wechatLogin = (code, user_id) => {
    return new Promise((resolve, reject) => {
      const data = {
        code: code,
        user_id: user_id
      }
      Axios.post(HywApi.wechatLogin, data).then((res)=> {
        resolve(res)
      }).catch((res)=> {
        reject(res)
      })
    })
  }
}

export const LoginApi = new Login()