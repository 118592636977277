import _ from 'lodash'
const actions = {
    GET_DRAFT_NOTE: 'GET_DRAFT_NOTE',
    GET_DRAFT_TOTAL: 'GET_DRAFT_TOTAL',
    GET_NOTE_LIST: 'GET_NOTE_LIST',
    GET_NOTE_TOTAL: 'GET_NOTE_TOTAL',
    SET_CURRENT_MODIFY_NOTE: 'SET_CURRENT_MODIFY_NOTE',
}

const getDraftNote = (data) => {
    return dispatch => {
        dispatch({
            type: actions.GET_DRAFT_NOTE,
            payload: data
        })
    }
}
const getDraftTotal = (data) => {
    return dispatch => {
        dispatch({
            type: actions.GET_DRAFT_TOTAL,
            payload: data
        })
    }
}
const getNoteList = (data) => {
    return dispatch => {
        dispatch({
            type: actions.GET_NOTE_LIST,
            payload: data
        })
    }
}

const getNoteTotal = (data) => {
    return dispatch => {
        dispatch({
            type: actions.GET_NOTE_TOTAL,
            payload: data
        })
    }
}

//设置与更新当前被修改的笔记
const setCurrentModifyNote = (curNoteData) => {
    return (dispatch)=> {
        // console.log('setCurrentModifyNote----', curNoteData)
        dispatch({
            type: actions.SET_CURRENT_MODIFY_NOTE,
            currentModifyNote: _.cloneDeep(curNoteData),
        })
    }
}


export default {
    getDraftNote,
    getDraftTotal,
    getNoteList,
    getNoteTotal,
    setCurrentModifyNote,
    actions
}
