import React from 'react';

const origin = window.location.origin
const appid = 'wxb585682de2da13cc'
const WECHAT_LOGIN_URL = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${encodeURIComponent(origin)}&response_type=code&scope=snsapi_login&state=hyw#wechat_redirect`

function WechatLogin(props)  {
    return (
        <div>
            <iframe className="wechat-login-iframe"
                    frameBorder="0"
                    sandbox="allow-scripts allow-same-origin allow-top-navigation"
                    scrolling="no"
                    src={WECHAT_LOGIN_URL}></iframe>
        </div>
    )
}

export default WechatLogin