import BraftEditor from "braft-editor";
import React, {useCallback, useEffect, useImperativeHandle, useRef, useState, forwardRef} from "react";
import 'braft-editor/dist/index.css'
import * as styles from './HywEditor.module.css'
import { ContentUtils } from 'braft-utils'
import {message, Upload, Modal, Row, Col, Tabs} from 'antd'
import {CheckCircleTwoTone} from '@ant-design/icons'
import {NoteApi} from "../../api";
import {
    PictureOutlined,
} from '@ant-design/icons';
import {Images} from "../../utils";
import Axios from "axios";
import useInterVal from "../../utils/useInterVal";


//tips: rn 组件 image显示 需要指定宽高 （html标签中设定好）
const { TabPane } = Tabs;
function HywEditor(props, ref) {
    // const {initHtml} = props
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null))
    // const [outputHTML, setOutHTML] = useState('<div></div>')
    const [imgModalVisiable, setImgModalVisiable] = useState(false)
    const [hywImgModal, setHywImgModal] = useState(false)
    const editorRef = useRef();
    const dropDownRef = useRef();
    const [headColor, setHeadColor] = useState(undefined)
    const [hywImgData, setHywImgData] = useState([])
    const [hywSelected, setHywSelected] = useState([])
    const [uploadQrcode, setUploadQrcode] = useState(null)
    const intervalRef = useRef(null);
    let leave = (source='') => {
        clearInterval(intervalRef.current);
        console.log("leave result:",source,intervalRef);
    }

    // const { innerRef } = props;
    const innerFn = useCallback(() => {
        const htmlContent = editorState.toHTML()
        // console.log(htmlContent, 'htmlContent')
        // setOutHTML(htmlContent)
        // props.getHTML(htmlContent)

        return htmlContent
    });
    useImperativeHandle(ref, () => ({
        innerFn,
    }), [innerFn])
    // useImperativeHandle(ref, () => ({
    //     formFields: form,
    // }));

    let enter = (key) => {
        let id = setInterval(() => {
            // console.log(`auto carried out`,intervalRef, key) //timer Number Id
            NoteApi.getQrCodeImgResult({
                key: key
            }).then((res) => {
                console.log(res, 'getQrCodeImgResult--')
                if (res?.data) {
                    leave(`Time to`);
                    if (res?.data?.status === 1) {
                        uploadOtherImg(res?.data?.data)
                    } else {
                        message.error(res?.data?.error || '上传出错啦')
                    }


                }

            })
            //
        }, 3000);
        intervalRef.current = id;
    }
    //test mobile html <div><p><span>123</span><b style="font-weight: bold;">45</b></p><h3><span style="font-weight: bold; font-size: 30px">asd</span></h3><p><span>123</span></p><p><span>asdasd</span></p><p><span>asd</span></p><img src="https://qiniu.hywapp.com/note/758c5e60-33ba-11eb-9c0c-4a6d7c432c81.png" width="600" height="1299"/></div>
    //外层套了div web编辑器不正常 故先去掉显示
    const initHtmlStr = (htmlStr) => {
        let trimStr = htmlStr.trim()
        if (trimStr.startsWith('<div>')) {
            trimStr = trimStr.substr(5).substr(0, trimStr.length - 5)
        }
        return trimStr
    }
    useEffect(() => {
        // console.log('useRef', editorRef, props?.initHtml)
        // console.log(editorRef.current?.controlBarInstance)
        // console.log(initHtmlStr(`<div><p><span>123</span><b style="font-weight: bold;">45</b></p><h3><span style="font-weight: bold; font-size: 30px">asd</span></h3><p><span>123</span></p><p><span>asdasd</span></p><p><span>asd</span></p><img src="https://qiniu.hywapp.com/note/758c5e60-33ba-11eb-9c0c-4a6d7c432c81.png" width="600" height="1299"/></div>`))
        const testStr = initHtmlStr(props?.initHtml)
        setEditorState(BraftEditor.createEditorState(testStr))
    }, [props?.initHtml])
    const checkImageWH = (file) => {
        const value =  file;
        return new Promise((resolve, reject) => {
            const filereader = new FileReader();
            filereader.onload = (e) => {
                const src = e.target.result;
                const image = new Image();
                image.onload = () => {
                    value.width = image.width;
                    value.height = image.height;
                    resolve();
                };
                image.onerror = reject;
                image.src = src;
            };
            filereader.readAsDataURL(value);
        });
    };
    // 限制图片的格式，size，分辨率
    const handleBeforeUpload = (file, FileList) => {
        const isJPG = file.type === 'image/jpeg';
        const isJPEG = file.type === 'image/jpeg';
        const isGIF = file.type === 'image/gif';
        const isPNG = file.type === 'image/png';
        if (!(isJPG || isJPEG || isGIF || isPNG)) {
            message.error('只能上传JPG 、JPEG 、GIF、 PNG格式的图片~');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        console.log(isLt2M, 'isLt2M----')
        if (!isLt2M) {
            message.error('超过5M限制，不允许上传~');
            return false;
        }
        return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && checkImageWH(file);
    };
    const uploadFile = (file, cb) => {
        const fileFormate = file
        checkImageWH(fileFormate)
        console.log(fileFormate, 'fileFormate')

        NoteApi.fileUpload(fileFormate).then((res) => {
            console.log(res, 'res-----上传成功')
            // setAvatar(res?.data)
            // form.setFieldsValue({headImg: res?.data})
            message.success(`上传成功`);
            cb && cb(res?.data, fileFormate)
        }).catch((e) => {
            console.log(e, 'e----')
            message.error(`上传失败`);
        })
    }
    const getInitWidth = (w,h) => {
        let maxWidth = 750
        return {
            width: w > maxWidth ? maxWidth : w,
            height: w > maxWidth ? (maxWidth/w)*h : h
        }
    }
    const uploadHandler = (param) => {

        if (!param.file) {
            return false
        }
        uploadFile(param.file, (url, fileFormate) => {
            console.log(url, fileFormate, 'irl--')
            setEditorState(ContentUtils.insertMedias(editorState, [{
                    type: 'IMAGE',
                    url: url,
                    // width: fileFormate.width,
                    // height: fileFormate.height
                    ...getInitWidth(fileFormate.width, fileFormate.height)
            }]))
        })

        setImgModalVisiable(false)


    }

    const controls = [
        {
            key: 'bold',
            text: <b>B</b>
        },
        {
            key: 'my-button', // 控件唯一标识，必传
            type: 'button',
            title: '标题', // 指定鼠标悬停提示文案
            className: 'my-button', // 指定按钮的样式名
            html: null, // 指定在按钮中渲染的html字符串
            text:  <b style={{color: headColor}}>H</b>, // 指定按钮文字，此处可传入jsx，若已指定html，则text不会显示
            onClick: () => {
                // editorRef.current?.controlBarInstance?.applyControl('header-three', 'block-type');
                if (editorRef && editorRef.current && editorRef.current.controlBarInstance && editorRef.current.controlBarInstance.applyControl) {
                    editorRef.current.controlBarInstance.applyControl('header-three', 'block-type');
                    setHeadColor('#3498db')
                }
                // console.log('Hello World!', props, editorRef);
            },
        },
        {
            key: 'custom-dropdown',
            type: 'dropdown',
            text: <PictureOutlined />,
            autoHide: true,
            ref: dropDownRef,
            component: (
                <div className={styles.imgMenu} onClick={(e) => {
                    // console.log(e, dropDownRef, 'e----')
                    dropDownRef.current.hide()
                }}>
                    <div className={styles.imgMenuItem} onClick={(e) => {
                        setImgModalVisiable(true)

                        NoteApi.getPicUpLoadQrCode({}).then((res) => {
                            console.log(res, 'res---getPicUpLoadQrCode')
                            setUploadQrcode('data:image/png;base64,' + res?.data?.img)

                            enter(res?.data?.key)
                        })
                    }}>
                        上传图片
                    </div>
                    <div className={styles.imgMenuItem} onClick={(e) => {
                        if (props.hosInfo && props.hosInfo.hosId) {
                            setHywImgModal(true)
                            NoteApi.getNoteHosPic({
                                hospital_id: props.hosInfo.hosId
                            }).then((res) => {
                                console.log(res, props.hosInfo.hosId, 'ress')
                                setHywImgData(addSortNum(res?.data))
                            })
                        } else {
                            message.error('请先选择就诊医院及科室！')
                        }

                    }}>
                        插入互医网官方图片
                    </div>
                </div>
            )
        },
        // {
        //     key: 'headings',
        //     text: <b>H</b>,
        // },
        // {
        //     key: 'headings',
        //     title: <b>H</b>,
        //     type: 'headings'
        // },
        'list-ul',
        'remove-styles', 'undo', 'redo',

        // {
        //     key: 'antd-uploader',
        //     type: 'component',
        //     component: (
        //         <Upload
        //             accept="image/*"
        //             showUploadList={false}
        //             customRequest={uploadHandler}
        //             // onChange={handleUpload}
        //         >
        //             {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
        //             <button type="button" className="control-item button upload-button" data-title="插入图片">
        //                 插入图片
        //             </button>
        //         </Upload>
        //     )
        // }

    ]

    const handleEditorChange = (editorState) => {
        // console.log(editorState, 'editorState', editorRef?.current?.controlBarInstance, editorState.toHTML())
        setEditorState(editorState)
        if (ContentUtils.getSelectionBlockType(editorState) == 'unstyled') {
            setHeadColor(undefined)
        }
        const htmlContent = editorState.toHTML()
        console.log(htmlContent, 'htmlContent')
        // setOutHTML(htmlContent)
        props.getHTML(htmlContent)
    }
    const addSortNum = (dataArry) => {
        return dataArry.map((item, section) => {
            return {
                ...item,
                show: section >=2 ? false :  true,
                data: item.data.map((item, index) => {
                    return {
                        ...item,
                        index: index + section * dataArry[section].data.length,
                    }
                })
            }
        })
    }
    const addHywPhoto = (url) => {

        if (hywSelected.includes(url)) {
            hywSelected.splice(hywSelected.indexOf(url), 1);
            setHywSelected([...hywSelected])
            return
        }
        if (hywSelected.length >= 9) return
        hywSelected.push(url)

        setHywSelected([...hywSelected])
        console.log(hywSelected, 'console.log(hywSelected, hywSelected.includes(index))')

    }
    const getImgInfo = (url) => {
        const initurl = url.substring(0, url.indexOf('?') <0 ? url.length : url.indexOf('?'))
        // console.log(initurl, url, 'initurl---')
        return Axios.get(initurl+'?imageInfo',null)
    }
    //获取返回数据中的图片链接
    const getImgsUrlArryInContent = (imgUrlArry) => {
        let returnImgsArry = []
        let promiseArry = []
        if (Array.isArray(imgUrlArry)) {
            imgUrlArry.forEach((item) => {
                    promiseArry.push(getImgInfo(item))
                    returnImgsArry.push(item)
            })
        }
        console.log(returnImgsArry, promiseArry, 'returnText')

        return {
            returnImgsArry,
            promiseArry
        }
    }
    const getImgObjArry = (promiseArry) => {
        return Promise.all(promiseArry).then((result) => {
            console.log(result)       // [ '3秒后醒来', '2秒后醒来' ]
            return result
        }).catch((error) => {
            console.log(error)
            return error
        })
    }
    const uploadOtherImg = async (imgUrlArry) => {
        const {returnImgsArry, promiseArry} = getImgsUrlArryInContent(imgUrlArry)
        const imgs = returnImgsArry
        const data = await getImgObjArry(promiseArry)
        // console.log(imgs, data, 'ids---')
        let noteImgs = []
        for (let i=0;i<imgs.length;i++)
        {
            let obj = {
                type: 'IMAGE',
                url: imgs[i],
                // width: data[i]?.data?.width,
                // height: data[i]?.data?.height,
                ...getInitWidth(data[i]?.data?.width, data[i]?.data?.height)
            }
            noteImgs.push(obj)
        }
        setEditorState(ContentUtils.insertMedias(editorState, noteImgs))
        setHywImgModal(false)
        setImgModalVisiable(false)
    }
    return(
        <div className="my-component">
            <BraftEditor
                ref={editorRef}
                controls={controls}
                className={styles.customEditor}
                // headings={['header-three']}
                value={editorState}
                defaultValue={BraftEditor.createEditorState(props?.initHtml)}
                onChange={handleEditorChange}
                onBlur={() => {
                    // alert('22')
                }}
                // onSave={this.submitContent}
                controlBarStyle={{
                    backgroundColor: '#F3F5F8',
                    boxShadow: "none",
                    borderColor: '#DCDFE6',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomWidth: 0,
                }}
                contentStyle={{
                    minHeight: 210,
                    height: "auto",
                    borderColor: '#DCDFE6',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    // backgroundColor: 'red'
                }}
            />
            <Modal
                title={null}
                centered
                visible={imgModalVisiable}
                // onOk={() => setVisible(false)}
                onCancel={() => {
                    setImgModalVisiable(false)

                }}
                afterClose={() => {
                    leave("手动点击的关闭")
                }}
                width={490}
                footer={null}
            >
                <div className={styles.picTit}>上传图片</div>
                <div className={styles.picSub}>每张图片大小不能超过30M</div>
                <Row gutter={20} justify={'space-between'}>
                    <Col>
                        <div className={styles.imgModalItem}>
                            {
                                uploadQrcode && <img src={uploadQrcode} width={190} height={190}/>
                            }
                        </div>
                        <div className={styles.imgModalItemTxt}>微信扫码上传手机照片</div>
                    </Col>
                    <Col>
                        <div className={styles.imgModalItem}>
                            <Upload
                                accept="image/*"
                                showUploadList={false}
                                customRequest={uploadHandler}
                                beforeUpload={handleBeforeUpload}
                                // onChange={handleUpload}
                            >
                                {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                                <button type="button" className={styles.uploadButton} data-title="插入图片">
                                    <img src={Images.note.uploadImg} width={87} height={72}/>
                                </button>
                            </Upload>
                        </div>
                        <div className={styles.imgModalItemTxt}>上传本地图片</div>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title={null}
                centered
                visible={hywImgModal}
                // onOk={() => setVisible(false)}
                onCancel={() => setHywImgModal(false)}
                width={1000}
                footer={null}
            >
                <div className={styles.titleLeft}>以下图片拍摄于<span className={styles.titleLeftRig}>{props?.hosInfo?.showValue}</span></div>
                <Tabs defaultActiveKey="0" tabPosition={'left'} style={{ minHeight: '800px' }}>
                    {
                        hywImgData.map((item, i) => {
                            return (
                                <TabPane tab={item.name} key={i}>
                                    <Row>
                                        {
                                            item.data.map((it,ind) => {
                                                // console.log(it, hywSelected, hywSelected.includes(it.url), '22')
                                                return (
                                                    <Col flex={1} key={ind}>
                                                        <div className={styles.imgCon} onClick={() => addHywPhoto(it.url)}>
                                                            <img src={it.url} width={180} height={180} />
                                                            {
                                                                !hywSelected.includes(it.url) ? <div className={styles.imgRight}></div> :
                                                                    <CheckCircleTwoTone twoToneColor="#40A6FF" className={styles.imgRight}/>
                                                            }
                                                        </div>

                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
                <Row className={styles.btmSty} justify="space-between" align="center">
                    <Col flex={1} className={styles.btmCol}>
                        <button type="button" className={styles.btmBtn}>
                            取消
                        </button>
                    </Col>
                    <Col flex={1} className={styles.btmCol}>
                        <button
                            type="button"
                            className={styles.btmBtnSure}
                            onClick={() => {
                                uploadOtherImg(hywSelected)
                            }}>
                            确定 {hywSelected.length > 0 ? `(${hywSelected.length})` : null}
                        </button>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default forwardRef(HywEditor)
