import React  from 'react'
import * as styles from './SatisfiedEval.module.css'
import { Images, Tools } from '@/utils';




const SatisfiedEval = React.memo((props) => {
    const {fraction, id} = props
  const scoreAltet = Tools.ScoreText
  const renderTags = (key) => {
      const { tagData, setChooseTags, id } = props
      if (tagData?.[key]?.length> 0) {
          return (
              <div className={styles.tags_view}>
                  {
                      tagData?.[key]?.map((item) => {
                          return (
                              <div
                                  key={item.id.toString()}
                                  className={item.choosed ? styles.active_tag : styles.tag_btn}
                                  onClick={() => setChooseTags(item.id, key, id)}
                              >
                                  {item.name}
                              </div>
                          )
                      })
                  }
              </div>
          )
      }
  }
  if(props.like === 0) {
    return (
            <div>
              <div className={styles.container} style={props.viewStyle}>
                <span className={styles.title}>{props.title}:</span>
                <img
                  className={styles.img_view}
                  src={fraction === 55 ? Images.note.score_six : (fraction === 20 ? Images.note.score_five : Images.note.score_one)}
                  alt= ''
                  onClick = {() => {
                    props.setScore(55, id)
                  }}
                />
                <img
                  className={styles.img_view}
                  src={fraction === 20 ? Images.note.score_five : Images.note.score_one}
                  alt= ''
                  onClick = {() => {
                    props.setScore(20, id)
                  }}
                />
                <span className={styles.eval_text}>{scoreAltet[fraction]?.text??''}</span>
              </div>
                {renderTags(fraction)}
                </div>
    )
  }


  return (
      <>
    <div className={styles.container} style={props.viewStyle}>
      <span className={styles.title}>{props.title}:</span>
      <img
        className={styles.img_view}
        src={fraction >=65 ? (fraction >=85 ? ( fraction >=100 ? Images.note.score_four : Images.note.score_three)  : Images.note.score_two )  :Images.note.score_one}
        alt= ''
        onClick = {() => {
          props.setScore(65, id)
        }}
      />
      <img
        className={styles.img_view}
        src={fraction >=85 ? (fraction >=100 ? Images.note.score_four : Images.note.score_three) : Images.note.score_one}
        alt= ''
        onClick = {() => {
          props.setScore(85, id)
        }}
      />
      <img
        className={styles.img_view}
        src={fraction >=100 ? Images.note.score_four : Images.note.score_one}
        alt= ''
        onClick = {() => {
          props.setScore(100, id)
        }}
      />
      <span className={styles.eval_text}>{scoreAltet[fraction]?.text??''}</span>
    </div>
          {renderTags(fraction)}
          </>
  )
})
export default SatisfiedEval
