import React from 'react';
import * as styles from './ImgModal.module.css'
import Modal from 'react-modal';
import { Images } from '../../utils/Images';

const customStyles = {
  content : {
    
    display: 'flex',
    flex: 1,
    padding: 0,
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: 0, 
    left: 0, 
    right: 0, 
    bottom: 0,
    zIndex: 999,
  }
};

function ImgModal(props) {

  return (
    <Modal
      isOpen={props.modelIsOpen}
      // onCancel={()=> props.closeModal()}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={styles.imgView}>
        <img 
          className={styles.closeIcon} 
          src={Images.album.imgClose} 
          alt=''
          onClick={()=> props.closeModal()} 
        />
        <img className={styles.imgItem} src={props.imgSrc}  alt='' />
      </div>
      
    </Modal>
  )
}

export default ImgModal