import React, {useState,useEffect,useRef}  from 'react'
import { Link,useHistory } from 'react-router-dom';
import { message,Modal,Spin, Input} from 'antd';
import 'antd/dist/antd.css';
import  * as styles from './Eval.module.css'
import { Images } from '@/utils';
import { Footer, SatisfiedEval, ToTop } from '@/components';
import {NoteApi} from '@/api/NoteApi';
import queryString from 'query-string';
import { LoadingOutlined } from '@ant-design/icons';


const totalData = [
  {
    text: '满意',
    total: 1,
    imgUrl: {
      0: Images.note.score_four_grey,
      1: Images.note.score_four,
      null: Images.note.score_four_grey,
    },
  },
  {
    text: '不满意',
    total: 0,
    imgUrl: {
      0: Images.note.score_six,
      1: Images.note.score_six_grey,
      null: Images.note.score_six_grey,
    },
  }
]

const Eval = React.memo((props) => {
  let url = props.location.search;
  let params = queryString.parse(url);
  let history = useHistory();

  // const {noteId, articleId, docName, hosName} = props.location.state

  // console.log(noteId,articleId, docName, hosName, "哈哈哈哈")

  const [total, setTotal] = useState(null)




  const [loadding, setLoading] = useState(false)
  const [doctorLikeTagData, setDoctorLikeTagData] = useState([])
  const [doctorUnlikeTagData, setDoctorUnlikeTagData] = useState([])
  const [hospitalLikeTagData, setHospitalLikeTagData] = useState([])
  const [hospitalUnlikeTagData, setHospitalUnlikeTagData] = useState([])
  const [experienceLikeTagData, setExperienceLikeTagData] = useState([])
  const [experienceUnlikeTagData, setExperienceUnlikeTagData] = useState([])

  let inputVal = ''

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    getCommentDimenAndTagData()
  },[])
//获取笔记评价纬度和标签
  const getCommentDimenAndTagData = () => {
    NoteApi.getTags(1).then((res) => {
      console.log(res, 're---')
      setDoctorLikeTagData(res?.data?.doctor??[])
      setHospitalLikeTagData(res?.data?.hospital??[])
      setExperienceLikeTagData(res?.data?.experience??[])
    })

    NoteApi.getTags(0).then((res) => {
      setDoctorUnlikeTagData(res?.data?.doctor??[])
      setHospitalUnlikeTagData(res?.data?.hospital??[])
      setExperienceUnlikeTagData(res?.data?.experience??[])
    })
  }








  const HeaderItem = () => {
    return (
      <div className={styles.header_view}>
        <div className={styles.header_title}>感谢分享，祝您健康！</div>
        <div className={styles.header_tip}>笔记将于1-3天内完成审核，您的分享会对其他患友们带来非常大的帮助</div>

        <Link to={`/noteList/${params.noteId}`}>
          <div className={styles.view_note}>
            <span className={styles.view_text}>查看笔记</span>
            <img className={styles.goin} src={Images.note.blueGoin} alt='' />
          </div>
        </Link>
      </div>
    )
  }




  //评价 type 评价类型（医生:doctor_comments 医院:hospital_comments 就医流程:experience_comments）
  const renderEval = (type) => {


    const {docName, hosName} = params
    const sourceData = {
      'doctor_comments': {
        title: '请您评价医生：',
        name: docName,
        sourceList: total === 1 ? doctorLikeTagData : doctorUnlikeTagData,
        sourceKeyFun: total === 1 ? setDoctorLikeTagData : setDoctorUnlikeTagData
      },
      'hospital_comments': {
        title: '请您评价医院：',
        name: hosName,
        sourceList: total === 1 ? hospitalLikeTagData : hospitalUnlikeTagData,
        sourceKeyFun: total === 1 ? setHospitalLikeTagData : setHospitalUnlikeTagData
      },
      'experience_comments': {
        title: '请您评价就医流程：',
        name: ' ',
        sourceList: total === 1 ? experienceLikeTagData : experienceUnlikeTagData,
        sourceKeyFun: total === 1 ? setExperienceLikeTagData : setExperienceUnlikeTagData
      },
    }
    const sourceList = sourceData[type].sourceList
    const sourceKeyFun = sourceData[type].sourceKeyFun
    const title = sourceData[type].title
    const name = sourceData[type].name
    if (typeof total === 'number') {
      return (
          <div className={styles.eval_contain}>
            <div className={styles.eval_targer}>{title}{name}</div>
            <div className={styles.eval_item}>
              {sourceList.map((item,index) => {
                return (
                    <SatisfiedEval
                        like={total}
                        key={item.label_id}
                        id={item.label_id}
                        viewStyle= {{marginTop: '17px'}}
                        title={item.label}
                        fraction={item?.level}
                        tagData={item.data}
                        setScore={(score, id) => {
                          //id 维度id
                          // console.log(score, id)
                          sourceKeyFun(sourceList?.map((it) => {
                            return {
                              ...it,
                              level: it.label_id === id ? score : it?.level,
                            }
                          }))
                        }}
                        setChooseTags={(tagId,scoreKey,id) => {
                          //id 维度id scoreKey 得分
                          sourceKeyFun(sourceList?.map((it) => {
                            return {
                              ...it,
                              data: it.label_id === id ? {
                                ...it.data,
                                [scoreKey]: it.data[scoreKey]?.map((ii) => {
                                  return {
                                    ...ii,
                                    choosed: ii.id === tagId ? !ii.choosed : (ii?.choosed??false)
                                  }
                                })
                              }: it.data,
                            }
                          }))

                        }}
                    />
                )
              })}
            </div>
          </div>
      )
    }


  }

  const renderTucao = () => {
    return (
        <div className={styles.eval_contain}>
          <div className={styles.eval_targer}>就医吐槽</div>
          <Input.TextArea
              rows={4}
              placeholder="请谈谈您想吐槽的…"
              maxLength={300}
              style={{marginTop: 12}}
              onChange={(e) => {
                inputVal = (e.target.value)
              }}
          />
        </div>
    )
  }

  const RenderView = () => {
    return (
      <div className={styles.render_view}>
        <div className={styles.title}>您对本次就医体验满意吗？</div>
        <div className={styles.select_wrap}>
          {
            totalData.map((item) => {
              return (
                  <div
                      key={item.total}
                      className={total === item.total ? styles.satisfied_btn : styles.displeasure_btn}
                      style={{marginRight: '15px'}}
                      onClick={() => setTotal(item.total)}
                  >
                    <img className={styles.satisfied_icon} src={item.imgUrl[total]} alt='' />
                    <span className={styles.satisfied_text} style={{color: total === item.total ? '#FF8533' : '#162533'}}>{item.text}</span>
                  </div>
              )
            })
          }

        </div>
        {total !== 1 && total !== 0 && <div className={styles.select_tip}>您也可以稍后前往【笔记管理-发布的笔记】进行就医评价</div>}

        {renderEval('doctor_comments')}
        {renderEval('hospital_comments')}
        {renderEval('experience_comments')}
        {typeof total === 'number' && renderTucao()}
      </div>
    )
  }


  //三个维度校验
  const checkDemiession = (type, callback) => {
    const sourceListData = {
      'doctor_comments': {
        sourceList: total === 1 ? doctorLikeTagData : doctorUnlikeTagData,
        messageAlert: '请完成医生评价',
      },
      'hospital_comments': {
        sourceList: total === 1 ? hospitalLikeTagData : hospitalUnlikeTagData,
        messageAlert: '请完成医院评价',
      },
      'experience_comments': {
        sourceList: total === 1 ? experienceLikeTagData : experienceUnlikeTagData,
        messageAlert: '请完成就医流程评价',
      },
    }
    const sourceList = sourceListData[type].sourceList
    const messageAlert = sourceListData[type].messageAlert
    const noLevelList = sourceList.filter(i => !i.level)

    if (noLevelList.length > 0) {
      return message.error(messageAlert)
    }
    let allDocChoosed = true
    //只要有一组标签未选择 提示去选择
    const hasLevelList = sourceList.filter(i => !!i.level)
    for (let curData of hasLevelList) {
      const tags = curData.data[curData.level]
      if (tags.length > 0) {
        const choosedTags = tags.filter(i => !!i.choosed)
        // console.log(choosedTags, tags, 'choosedTags----')
        if (choosedTags.length === 0) {
          allDocChoosed = false
          break
        }
      }
    }
    if (!allDocChoosed) {
      return message.error('请选择评价标签')
    }
    callback()
  }

  const onSubmitClick = () => {
    checkDemiession('doctor_comments', () => {
      checkDemiession('hospital_comments', () => {
        checkDemiession('experience_comments', () => {
          const {articleId,} = params;
          if (inputVal.trim().length<=0) {
            return message.error('请输⼊就医吐槽')
          }
          const sourceDataForDoc = total === 1 ? doctorLikeTagData : doctorUnlikeTagData
          const sourceDataForHos = total === 1 ? hospitalLikeTagData : hospitalUnlikeTagData
          const sourceDataForExp = total === 1 ? experienceLikeTagData : experienceUnlikeTagData
          const postData = {
            article_id: articleId,
            total: total,
            doctor_comments: sourceDataForDoc?.map((i) => {
              return {
                label_id: i.label_id,
                level: i.level,
                tag_ids: i.data[i.level]?.filter(i => !!i.choosed)?.map(i=> i.id)??[]
              }
            }),
            hospital_comments: sourceDataForHos?.map((i) => {
              return {
                label_id: i.label_id,
                level: i.level,
                tag_ids: i.data[i.level]?.filter(i => !!i.choosed)?.map(i=> i.id)??[]
              }
            }),
            experience_comments: sourceDataForExp?.map((i) => {
              return {
                label_id: i.label_id,
                level: i.level,
                tag_ids: i.data[i.level]?.filter(i => !!i.choosed)?.map(i=> i.id)??[]
              }
            }),
            roast: inputVal.trim(),
          }
          // return console.log(postData)
          NoteApi.commitEvaluateNew(postData).then((res) => {
            if(res && res.success) {
              setLoading(false)
              Modal.confirm({
                title: '感谢您的评价',
                content: '',
                okText: '查看笔记',
                cancelText: '关闭窗口',
                onOk: ()=> history.push(`/noteList/${params.noteId}`),
                onCancel: () =>window.close(),
              });

            }else {
              // message.info()
            }
          })
        })
      })
    })
  }




  const SubmitView = () => {
    if(total !== 1 && total !== 0) {
      return <></>
    }
    return (
      <div className={styles.submit_view}>
        <button className={styles.submit_btn} onClick={() => onSubmitClick()}>提交评价</button>
      </div>
    )
  }



  return (
    <div className={styles.container}>
      <HeaderItem />
      <RenderView />
      <Footer style={{marginTop: '80px',marginBottom: '100px'}} />
      <SubmitView />
      <ToTop onToTopClick={() => window.scrollTo(0, 0)}/>
      {loadding && <Spin indicator={antIcon} spinning={loadding} />}
    </div>
  )
})

export default Eval
