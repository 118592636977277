import React from 'react'
import  * as styles from './index.module.css'
import {Images, Tools} from '@/utils'

function ProcessCard(props) {
    const { detail } = props
    return (
        <div className={styles.cardContentContainner}>
            <div className={styles.cardContent}>
                <div className={styles.cardInfo}>
                    <div className={styles.cardDoctorTotal}>
                        就医流程评价：
                        {detail.is_commented ?
                            <div className={styles.cardDoctorTotalStatus}>“{Tools.getHospitalTotal(detail.total)}”</div>
                            :
                            <div className={styles.cardDoctorCommented}>
                                作者暂未评价
                            </div>
                        }
                    </div>
                    {
                        detail.labels?.length > 0 && <div className={styles.cardDoctorLabel}>{(detail.labels).map((res,index)=> {
                            if(index < 4){
                                return (<div className={styles.cardDoctorLabelItem} key={res.id}>{res.name}</div>)
                            }
                        })}</div>
                    }

                </div>

            </div>
            <div style={{marginTop: 36}}>
                {
                    detail?.is_commented  && <div className={styles.cardScore}>
                        {
                            detail?.levels?.map((i, ind) => {
                                return (
                                    <div className={styles.cardScoreItem} key={ind}>
                                        {i.label}:
                                        <img className={styles.cardScoreItemIcon} src={Tools.ScoreText[i.level]?.icon} alt=""/>
                                        <div className={styles.cardScoreItemText}>{Tools.ScoreText[i.level]?.text}</div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }
            </div>
        </div>
    )
}

export default ProcessCard
