import React, {useEffect}  from 'react'
import './FifthFrame.css'
import { Images, Tools } from '../../../utils'

export default function FifthFrame(props) {
  return (
    <div className='five-contain'>
      <img className='five-img' alt="" src={Images.home.fifthGif}/>
      <div className="five-info">
        <div className="five-title">
          在线复诊
        </div>
        <div className="five-tip">
          汇集全国千家重点医院，上万名权威<br/>
          医生，为您找到好医院、好医生
        </div>
      </div>
    </div>
  );
}