import React, {useEffect}  from 'react'
import './ThirdFrame.css'
import { Images, Tools } from '../../../utils'

export default function FifthFrame(props) {
  return (
    <div className='five-contain'>
      <img className='five-img' alt="" src={Images.home.thirdGif}/>
      <div className="five-info">
        <div className="five-title">
          就医分享
        </div>
        <div className="five-tip">
          海量用户分享真实就医经验，篇篇有<br/>
          干货，助您看病不走弯路
        </div>
      </div>
    </div>
  );
}