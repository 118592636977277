import React, {useState,useEffect,useRef}  from 'react'
import  * as styles from './Service.module.css'
import { Images } from '../../utils/Images';
import { Footer } from '../../components';

function Service() {

  return(
    <div className={styles.container}>
      <div className={styles.service_wrap}>
        <img className={styles.service_top} alt="" src={Images.home.serviceTop}/>
        <img className={styles.service_top} alt="" src={Images.home.serviceMid}/>
      </div>

      <Footer/>
    </div>
      
    
  )
}

export default Service
