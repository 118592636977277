import React from 'react';
import * as styles from './HywIframe.module.css'
import {Image} from "antd";
import moment from "moment";
import {NoteApi} from "../../api";
import {Images} from "../../utils";


const InfoItem = (props) => {
    const {label, text, clickEnable=false, labelStyle, textStyle, questions} = props
    return (
        <div className={styles.cureItem}>
            <div className={styles.cureTitle} style={labelStyle}>{label}</div>
            {
                clickEnable ? (
                    <div className={styles.cureDetail} style={textStyle}>{text ? text+ ' ＞' : ''}</div>
                ) : (
                    <div className={styles.cureDetail} style={textStyle}>{text || ''}</div>
                )
            }

        </div>
    )
}

export default class HywIframe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initHtml: this.props.initHtml,
            hosInfo: null,
            docInfo: {
                temporary_doctor: props?.showData?.temporary_doctor
            },
        }
    }
    componentDidMount() {
        // console.log(this.props.temporary_doctor, this.props.showData, 'temporary_doctor')
        if (this.props.showData?.hosId) {
            NoteApi.getHosDetail({
                id: this.props.showData?.hosId
            }).then((res) => {
                // console.log(res, 'res-----hosId')
                this.setState({hosInfo: res?.data})
            })
        }
        if (this.props.showData?.docId) {
            NoteApi.getDocInfo({
                id: this.props.showData?.docId
            }).then((res) => {
                // console.log(res, 'res-----docId')
                this.setState({docInfo: res?.data})
            })
        }

    }


    render() {
        // console.log(this.props.showData, this.state,'this.props.url')
        const nowStateText = {
            1: '已痊愈',
            2: '有好转',
            3: '病情稳定',
        }
        const {
            noteSubTitle= '',
            noteCost ='',
            noteSick = null,
            noteTreatTime,
            noteTreatType =[],
            noteSymptom =[],
            noteDuration =null,
            noteNowState=null,

            medical_cost,
            see_doc_rea,
            sec_hos_rea,
            appointment,
            transport,
            xdData,
            question,
            friendly_reminder
        } = this.props.showData
        return (
            <div className={styles.showMobilePreview}>
                <div className={styles.mobile_preview_header}><i className={styles.mobile_preview_header_icon}></i></div>
                <div className={styles.mobile_preview_frame}>
                    {/*<iframe*/}
                    {/*    className={styles.YuFrameMobilePreview}*/}
                    {/*    name={'YuFrameMobilePreview'}*/}
                    {/*    src={this.props.url}*/}
                    {/*    id="otherPage"*/}
                    {/*    ref={this.myRef}*/}
                    {/*    onLoad={() => {*/}
                    {/*        console.log(document.getElementById("otherPage"), window.frames, this.myRef.current.contentWindow, 'document.getElementById("otherPage")')*/}
                    {/*        // window.frames.sessionStorage.setItem('data', this.props.initHtml)*/}
                    {/*        window.frames.postMessage('hello', '*')*/}
                    {/*    }}*/}
                    {/*    sandbox="allow-same-origin allow-scripts allow-top-navigation"*/}
                    {/*>*/}
                    {/*</iframe>*/}
                    <div className={styles.headerWrap}>
                        <div className={styles.treatmentItem}>
                            <div className={styles.headerTitle}>
                                {noteSubTitle}
                            </div>
                            {/*<div className={styles.treatmentTime}>*/}
                            {/*    time 发布*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className={styles.infoWrap}>
                        <InfoItem
                            label={'就诊时间：'}
                            text={noteTreatTime ? moment(noteTreatTime).format('YYYY-MM-DD') : ''}
                        />
                        <InfoItem
                            label={'症状表现：'}
                            text={noteSymptom.map(i=> i.label).join("、")}
                        />
                        <InfoItem
                            label={'确诊疾病：'}
                            text={Array.isArray(noteSick) ? noteSick.map(i=> i.label).join() : noteSick?.label??''}
                        />
                        <InfoItem
                            label={'就医原因：'}
                            text={see_doc_rea?.value}
                        />
                        <InfoItem
                            label={'选择该医院原因：'}
                            text={sec_hos_rea?.value}
                        />
                        <InfoItem
                            label={'预约方式：'}
                            text={appointment?.value}
                        />
                        <InfoItem
                            label={'交通方式：'}
                            text={transport?.value}
                        />
                        <InfoItem
                            label={'携带材料：'}
                            text={xdData?.join('、')}
                        />
                        <InfoItem
                            label={'问诊问题：'}
                            text={question?.length > 0 ? `${question?.length??0}个` : ''}
                            clickEnable={true}
                            questions={question??[]}
                            textStyle={{
                                color: '#40A6FF'
                            }}
                        />
                        <InfoItem
                            label={'本次就医花费：'}
                            text={(Number(noteCost??0) + Number(medical_cost??0))+"元"}
                        />
                        <InfoItem
                            label={'医保：'}
                            text={medical_cost ? `${medical_cost??0}元` : ''}
                        />
                        <InfoItem
                            label={'自费：'}
                            text={noteCost ? `${noteCost??0}元` : ''}
                        />
                        <InfoItem
                            label={'累计就诊时长：'}
                            text={Array.isArray(noteDuration) ? noteDuration.map(i=> i.label).join() : noteDuration?.label??''}
                        />
                        <InfoItem
                            label={'治疗方式：'}
                            text={noteTreatType.map(i=> i.label).join("、")}
                        />
                        <InfoItem
                            label={'目前病情状况：'}
                            text={nowStateText[noteNowState]}
                        />
                        <InfoItem
                            label={'友情提醒：'}
                            labelStyle={{
                                color: '#FF8533'
                            }}
                            text={friendly_reminder}
                            textStyle={{
                                color: '#FF8533'
                            }}
                        />
                    </div>
                    <div className={styles.evalWrap}>
                        <div className={styles.evalTop}>
                            <div className={styles.evalTitle}>医院评价：</div>
                            <div className={styles.evalTip}>发布后可评价</div>
                        </div>
                        <div className={styles.hosInfo}>
                            <div className={styles.hosItem} >
                                <Image
                                    className={styles.hosImg}
                                    width={63}
                                    height={48}
                                    src={this.state.hosInfo?.thumbnail || Images.note.hospitalDefault}
                                />
                                <div className={styles.hosAdsWrap}>
                                    <div
                                        className={styles.hosName}
                                    >{this.state.hosInfo?.name}</div>
                                    <div className={styles.hosAds} >
                                        <div className={styles.hosLever}>{this.state.hosInfo?.level}</div>
                                        <div>
                                            <div className={styles.hosLever}>{this.state.hosInfo?.area_name}</div>
                                            {/*<Text style={styles.hosLever}>距离1.4km</Text>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.evalWrap}>
                        <div className={styles.evalTop}>
                            <div className={styles.evalTitle}>医生评价：</div>
                            <div className={styles.evalTip}>发布后可评价</div>
                        </div>
                        <div className={styles.hosInfo}>
                            <div className={styles.hosItem} >
                                <Image
                                    className={styles.hosImg}
                                    width={50}
                                    height={50}
                                    src={this.state.docInfo?.picture || Images.note.doctorDefault}
                                />
                                <div className={styles.hosAdsWrap}>
                                    <div
                                        className={styles.hosName}
                                    >{this.state.docInfo?.name || this.state.docInfo?.temporary_doctor}</div>
                                    <div className={styles.hosAds} >
                                        <div className={styles.hosLever}>{this.state.docInfo?.department_name} {this.state.docInfo?.title}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.process}>
                        <span className={styles.lineLabel}>就医流程评价：</span>
                        <span className={styles.lineText}>发布后可评价</span>
                    </div>
                    <div className={styles.process}>
                        <span className={styles.lineLabel}>个人感受：</span>
                        <span className={styles.lineText}>发布后可评价</span>
                    </div>
                    <div style={{borderBottom: '10px solid #F5F7FA', marginTop: '10px'}}></div>
                    <div className={styles.noteTypeWrap}>
                        {
                            Array.isArray(this.props.showData?.noteType) && this.props.showData?.noteType.map((item,index) => {
                                return (
                                    <div className={styles.noteTag} key={index}>
                                        <div className={styles.noteType}>
                                            {`#${item}`}
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.noteTitleView}>
                        <div className={styles.noteTitleSpe}></div>
                        <div style={{textAlign: 'center', position: 'relative'}}>
                           <div className={styles.bgLiner}></div>
                            <span className={styles.titleText}>就医过程</span>
                        </div>
                        <div className={styles.noteTitleSpe}></div>
                    </div>
                    <div className={styles.innerhtml} dangerouslySetInnerHTML={{__html:`${this.props.initHtml}`}}></div>
                    <div style={{
                        textAlign: 'center',
                        color: '#879099',
                        marginTop: '32px',
                        marginBottom: '32px',
                    }}>
                        —— 本文结束 ——
                    </div>
                </div>
                <div className={styles.mobile_preview_footer}><i className={styles.mobile_preview_footer_icon}></i></div>

            </div>
            // <iframe
            //     style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
            //     // onLoad={() => {
            //     //     const obj = ReactDOM.findDOMNode(this);
            //     //
            //     //     this.setState({
            //     //         "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
            //     //     });
            //     // }}
            //     ref="iframe"
            //     src={this.html}
            //     width="100%"
            //     height={this.state.iFrameHeight}
            //     scrolling="no"
            //     frameBorder="0"
            // />
        );
    }
}
