import React, {useState} from 'react'
import {Images} from '@/utils'
import './NoteCard.css'
import {Button,Modal,message} from "antd"
import {Link} from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {NoteApi} from '@/api/index'


function NoteCard(props) {
    const { isDraft, data } = props
    const [noteData, setNoteData] = useState(props.data)
    // console.log(noteData, props, "noteData")
    let goEvaluate = {
        pathname: '/eval',
        search: "?noteId="+noteData.note_id+'&articleId='+noteData.article_id+'&docName='+noteData?.doctor?.name+'&hosName='+noteData?.hospital?.name,
    }
    let goNoteSend = {
        pathname: '/note',
        search: "?noteId="+noteData.note_id+'&articleId='+(noteData?.article_id??0) +'&draftId='+(noteData?.draft_id??0)
    }
    let goNoteSendWithNewArticle = {
        pathname: '/note',
        search: "?noteId="+noteData.note_id+'&noteTitle='+noteData.note_title
    }

    var defaultImage = noteData.images[0] ? noteData.images[0]['url'] : Images.note.cardDefault
    const getCardStatus = ()=> {
        switch (data.status) {
            case 0:
                return <div className="card-status card-review">审核中</div>
            case 1:
                return <div className="card-status card-success">已发布</div>
            case 2:
                return <div className="card-status card-success">最后编辑</div>
            case -1:
                return <div className="card-status card-failed">审核失败</div>
            case -3:
                return <div className="card-status card-delete">已删除</div>
        }
    }
    const deleteClick = ()=> {
        Modal.confirm({
            title: '你确定要删除吗',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: ()=> isDraft ? onDeleteNoteDraft() : onDeleteNote()
          });
    }
    const onDeleteNote = ()=> {
        NoteApi.deleteNote({"note_articles": [{"note_id": data.note_id,"article_id": data.article_id}]}).then((res)=>{
            if(res.success){
                message.success('删除成功');
            } else {
                message.error('删除失败');
            }
            props.deleteCallBack()
        })
    }
    const onDeleteNoteDraft = ()=> {
        NoteApi.deleteNoteDraft({"draft_articles": [{"note_id": data.note_id,"draft_id": data.draft_id}]}).then((res)=>{
            if(res.success){
                message.success('删除成功');
            } else {
                message.error('删除失败');
            }
            props.deleteCallBack()
        })
    }


    return (
        <div className="container">
            <div className="container-left">
                <Link to={isDraft ? goNoteSend :`/noteList/${data.note_id}`} target={isDraft ? '_blank' : ''}>
                    <div className="container-title">{data.note_title? data.note_title : "未命名标题"}{data.article_title ? "("+data.article_title+")" : "" }</div>
                </Link>
                <div className="container-left-footer">
                    <div className="card-left-flex">
                        <div className="card-left-bottom">
                            {getCardStatus()}
                            <div className="card-time">{data.create_time}</div>
                        </div>
                        {data.status != -3 &&
                        <div className="card-left-footer">
                            { noteData.comment_flag == 0 && <Link to={goEvaluate} target='_blank'>
                                <Button type="primary" className="card-btn-review margin-right-20 card-btn-class">就医评价</Button>
                            </Link>}
                            { noteData.status == 1 && <Link to={goNoteSendWithNewArticle} target='_blank'><Button className="card-btn-class margin-right-20">追加发布</Button></Link>}
                            { [2, 1, -1].indexOf(noteData.status)!=-1 && <Link to={goNoteSend} target='_blank'><Button className="card-btn-class">编辑</Button></Link>}
                        </div>
                        }
                    </div>
                    <div className="card-left-flex">
                        <Button icon={<DeleteOutlined />} className="card-delete-btn card-btn-class" onClick={()=>deleteClick()}>删除</Button>
                    </div>
                </div>
            </div>
            <Link to={isDraft ? goNoteSend :`/noteList/${data.note_id}`} target={isDraft ? '_blank' : ''}>
                <div>
                    <img className="image-card" alt='' src={defaultImage} />
                </div>
            </Link>
        </div>
    )
}

export {NoteCard}
