import BrowserLogger from 'alife-logger';

const logger = (() => {
  const __bl = BrowserLogger.singleton(
    {
      pid: "hgus6xj3qv@dd35dc635f30b2a",
      appType: "web",
      imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
      sendResource: true,
      enableLinkTrace: true,
      environment: process.env.REACT_APP_HOST == 'production' ? 'prod' : 'daily',
      behavior: true,
      enableSPA: true,
      parseHash: (hash)=>{ return hash ? hash.replace(/^#/, '').replace(/\?.*$/, '') : '';}
    }
  );
})();

export default logger