import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      // 在路由变化时将滚动位置置顶
      window.scrollTo(0, 0);
    });

    // 清除effect
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default ScrollToTop;