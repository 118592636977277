import axios from 'axios'
import { message } from 'antd'

const host = process.env.REACT_APP_HOST

console.log(host)

const BackendHost = {
  DEV: 'https://staging.hywapp.com/pc/r/v1',
  PRO: 'https://api.hywapp.com/pc/r/v1',
}

const ApiHost = host == 'production' ? BackendHost.PRO: BackendHost.DEV

export const previewH5Host  =  host == 'production' ? 'http://h5.pro.hywapp.com': 'https://h5.staging.hywapp.com'

export const IntruUrl =  host == 'production' ? 'https://h5.pro.hywapp.com/#/pages/notes-use/notes-use': 'https://h5.staging.hywapp.com/#/pages/notes-use/notes-use'


// 自定义判断元素类型JS
function toType(obj) {
  // @ts-ignore
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
function filterNull(o, method) {
  Object.keys(o).map(key => {
    if (o[key] === null) {
      delete o[key]
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim()
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key])
    } else if (toType(o[key]) === 'array') {
      o[key] = method === 'GET' ? JSON.stringify(filterNull(o[key])) : filterNull(o[key])
    }
  })
  return o
}
/**
 *
 *
 * @param {String} method Ajax请求类型 'POST'|'PUT'|'GET'|'DELETE'
 * @param {String} url 请求地址
 * @param {Object} params  参数
 * @returns Promise<T>
 */

function apiAxios(method, url, params, responseType = 'json') {
  if (params) {
    params = filterNull(params, method)
  }
  return new Promise((resolve, reject) => {
    // if (!Login.default.GetLoginState()) {
    // }
    // console.log(method, url, params, "参数")
    axios.defaults.baseURL = ApiHost;
    axios.defaults.headers.common.Authorization = localStorage.getItem('access_token');
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    // axios.defaults.headers.common['token'] = token
    // console.log(localStorage, 'localStorage', method, url, params)
    // @ts-ignore
    axios({
      method,
      url,
      data: method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH' ? params : null,
      params: method === 'GET' ? params : null,
      withCredentials: true,
      responseType: responseType,
    })
      .then((res) => {
        // console.log(res, url, params, 'res---')
        if (res.data.msg === 'Token已过期') {
          message.error('登录已过期，请重新登录')
          window.location.href = '/#/home'

          localStorage.removeItem('access_token')
          localStorage.removeItem('userInfo')
          localStorage.removeItem("isReal")
        }
        if (res.status === 200) {
          // console.log(res, url, res?.request?.responseURL, 'res---')
          if (res?.data && res?.data.success) {
            resolve(res?.data)
          } else if(res?.data && responseType === 'blob') {
            resolve(res?.data)
          } else {
            // console.log(res?.data)

            if (res?.data?.code === 1001 || res?.data?.code === 1002 ||res?.data?.code === 1003||res?.data?.code === 1004||res?.data?.code === 1005||res?.data?.code === 1006) {
              // console.log(document.getElementsByClassName('ant-message').length, 'lalsldals')
              if (document.getElementsByClassName('ant-message').length === 0) {
                message.error(res?.data?.message || `错误码${res?.data?.code}`)
              }
              window.location.href = '/#/home'
              localStorage.removeItem('access_token')
              localStorage.removeItem('userInfo')
              localStorage.removeItem("isReal")
              reject(res)
            } else {
              message.error(res?.data?.message || `错误码${res?.data?.code}`)
              reject(res)
            }
            // reject(res?.data?.message || `错误码${res?.data?.code}`)
          }

        } else {
          reject('Axios返回状态不对，查看请求处理过程．．．．')
        }
      }, err => {
        reject(err)
        return console.log(err, 'err---------')
        const errCode = err.response.status
        const msg = err.response.message
        if (msg === 'Token已过期') {
          message.error('身份过期，请重新登录')
          window.location.href = '#/login'
        }
        switch (errCode) {
          case 400:
            console.log('错误请求')
            break
          case 401:
            // 权限处理 重新登录 清空token
            message.error('请检查用户名和密码')
            window.location.href = '#/login'
            break
          case 403:
            message.error('身份过期请重新登录', 3)
            window.location.href = '#/login'
            break
          case 404:
            message.error('请求错误,未找到该资源')
            console.log('请求错误,未找到该资源')
            break
          case 405:
            console.log('请求方法未允许')
            break
          case 408:
            console.log('请求超时')
            break
          case 500:
            message.error('服务器端出错')
            console.log('服务器端出错')
            break
          case 501:
            console.log('网络未实现')
            break
          case 502:
            console.log('网络错误')
            break
          case 503:
            console.log('服务不可用')
            break
          case 504:
            console.log('网络超时')
            break
          default:
            message.error('未知错误')
        }

      })
      .catch((err) => {
        const errInfo = { 'err': err.response }
        reject(errInfo)
      })
  })
}
export default {
  get: (url, params, responseType = null) => {
    return apiAxios('GET', url, params, responseType)
  },
  post: (url, params) => {
    return apiAxios('POST', url, params)
  },
  put: (url, params) => {
    return apiAxios('PUT', url, params)
  },
  delete: (url, params) => {
    return apiAxios('DELETE', url, params)
  },
  patch: (url, params) => {
    return apiAxios('PATCH', url, params)
  }
}
