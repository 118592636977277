import Axios from './Axios'
import {HywApi} from './HywApi'

class user {
  
  getUserInfo = (id) => {
    return new Promise((resolve, reject) => {
      Axios.get(HywApi.userInfo + id)
          .then((res) => {
              console.log(res, HywApi.userInfo, 'userInfo');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.userInfo, 'err----');
              reject(err)
          })
    })
  }
  sendIdCard= (
    name,
    id_card
  ) => {
    let query = {
      name: name,
      id_card: id_card,
    }
    return new Promise((resolve, reject) => {
      Axios.post(HywApi.auth, query)
          .then((res) => {
              console.log(res, HywApi.auth, 'auth');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.auth, 'err----');
              reject(err)
          })
    })
  }
  getNotReadNews= () => {
    return new Promise((resolve, reject) => {
      Axios.get(HywApi.notReadNews)
          .then((res) => {
              console.log(res, HywApi.notReadNews, 'notReadNews');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.notReadNews, 'err----');
              reject(err)
          })
    })
  }
}

export const UserApi = new user()