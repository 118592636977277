import React, {useState,useEffect,useRef}  from 'react'
import  * as styles from './Certificate.module.css'
import { JZCertificate } from '../../utils/Config'

function Certificate() {
  return(
    <div className={styles.container}>
      <img className={styles.certificate_img} alt='' src={JZCertificate} />
    </div>
  )
}

export default Certificate
