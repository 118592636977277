import React, {useCallback, useEffect, useRef, useState} from 'react'
import  * as styles from './NotePage.module.css'
import {Images} from '../../utils'
import {connect} from "react-redux";
import moment from "moment";
import 'moment/locale/zh-cn';

import {
    Input,
    Divider,
    Checkbox,
    Row,
    Col,
    Cascader,
    Select,
    Spin,
    DatePicker,
    InputNumber,
    Form,
    Button,
    message, Modal, Image,
    Radio
} from 'antd';
import NoteAction from "../../redux/note/NoteAction";
import {NoteApi, UserApi} from "../../api";
import {Footer, HywEditor, HywIframe, InputView, ToTop} from "../../components";
import _ from 'lodash';
import dayjs from "dayjs";
import {convertToObject, convertToHtmlString} from "../../utils/Convertors";
import Axios from "axios";
import queryString from "query-string";
import {
    DownOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
import {Link,useHistory} from "react-router-dom";
import {IntruUrl} from "../../api/Axios";

moment.locale('zh-cn');

const { debounce } = _;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;





const defaultDuration = [
    '1小时以内',
    '1-2小时',
    '2-4小时',
    '4小时以上',
]
const defaultTypeOption = [
    {
        id: 1,
        name: '经验指导',
    },
    {
        id: 2,
        name: '心情分享',
    },
    {
        id: 3,
        name: '住院经历',
    },
    {
        id: 3,
        name: '就医对比',
    },
]
const defaultNowState = [
    {
        id: 1,
        name: '已痊愈',
    },
    {
        id: 2,
        name: '有好转',
    },
    {
        id: 3,
        name: '病情稳定',
    },
]



function NotePage(props) {
    // console.log(props)


    const [form] = Form.useForm();

    const editRef = useRef({});
    // const { currentModifyNote } = props
    let url = props.location.search;
    let params = queryString.parse(url);

    params.articleId = parseInt(params.articleId)
    params.noteId = parseInt(params.noteId)
    params.draftId = parseInt(params.draftId)
    const [noteId, setNoteId] = useState(params?.noteId??0)
    const [draftId, setDraftId] = useState(params?.draftId??0)
    const [title, setTitle] = useState('')
    const [checkedList, setCheckedList] = useState([])
    const [hosOptions, setHosOptions] = useState([])
    const [docDatas, setDocDatas] = useState([])
    const [noteQuery, setNoteQuery] = useState({})
    const [hosValue, setHosValue] = useState('')
    const [docValue, setDocValue] = useState([])
    const [docFetch, setDocFetch] = useState(false)
    const [cumDocName, setCumDocName] = useState('')
    const [docMenuOpen, setDocMenuOpen] = useState(false)
    const [treatTypeOpen, setTreatTypeOpen] = useState(false)
    //症状表现
    const [symptomDatas, setSymptomDatas] = useState([])
    const [symptomValue, setSymptomValue] = useState([])
    const [symptomFetch, setSymptomFetch] = useState(false)
    //确诊疾病
    const [sickDatas, setSickDatas] = useState([])
    const [sickValue, setSickValue] = useState([])
    const [sickFetch, setSickFetch] = useState(false)
    //治疗方式
    const [treatTypeOptions, setTreatTypeOptions] = useState([])
    const [treatType, setTreatType] = useState([])
    //累计时长
    const [treatDuration, setTreatDuration] = useState('')

    //携带材料
    const [xdDataOptions, setXdDataOptions] = useState([])
    //问诊问题
    const [questionOptions, setQuestionOptions] = useState([])
    //就诊原因
    const [seeDocReaOptions, setSeeDocReaOptions] = useState([])
    //当选择就诊原因其他时需要临时数据
    const [seeDocQiTaInput, setSeeDocQitaInput] = useState(false)
    //选择该医院原因
    const [secHosReaOptions, setSecHosReaOptions] = useState([])
    //当选择该医院原因其他时需要临时数据
    const [seeHosQiTaInput, setSeeHosQitaInput] = useState(false)
    //预约方式
    const [appointmentOptions, setAppointmentOptions] = useState([])
    //交通方式
    const [transportOptions, setTransportOptions] = useState([])

    const [allCost, setAllCost] = useState(0)

    const [noteHTML, setNoteHTML] = useState('')
    const [initHtml, setInitHtml] = useState('')


    const [fillModal, setFillModal] = useState(false)
    const [autoSaveFlag, setAutoSaveFlag] = useState(0)

    const [previewModal, setPreviewModal] = useState(false)

    const intervalRef = useRef(null);
    const maxLength = 30

    // const isReal = localStorage.getItem('isReal')
    const routerHistory = useHistory();
    const [isRealModal,setIsRealModal] = useState(false)

    const titleOnchange = (e) => {
        const value = e.target.value || "";
        setTitle(value)
    }
    const initOptions = (options) => {
        if (!Array.isArray(options)) {
            return []
        }
        return options.map((item) => {
            if (item.children) {
                return {
                    ...item,
                    label: item.name,
                    value: item.id,
                    children: initOptions(item.children)
                }
            } else {
                return {
                    ...item,
                    label: item.name,
                    value: item.id,
                }
            }

        })
    }
    const getHosOptionInfo = (name) => {
        NoteApi.getHosInfo({
            hospital_name: name,
        }).then((res) => {
            // console.log(res, 'res000011', initOptions(res?.data))
            setHosOptions(initOptions(res?.data))

        })
    }
    const getTreatTypeOptions = () => {
        NoteApi.getTreatOptions({
        }).then((res) => {
            // console.log(res, 'res000011', initOptions(res?.data))
            setTreatTypeOptions(initOptions(res?.data))
        })
    }
    //获取笔记2.3.17版本新增字段的枚举项
    const getNoteNewDataOptions = (callback) => {
        NoteApi.getNoteNewDataOptions({}).then((res) => {
            // console.log(res?.data, 'getNoteNewDataOptions----')
            setXdDataOptions(res?.data?.data??[])
            setQuestionOptions(res?.data?.question??[])
            setSeeDocReaOptions(res?.data?.see_doc_rea??[])
            setSecHosReaOptions(res?.data?.sec_hos_rea??[])
            setAppointmentOptions(res?.data?.appointment??[])
            setTransportOptions(res?.data?.transport??[])
            callback&& callback(res?.data?.see_doc_rea??[], res?.data?.sec_hos_rea??[], res?.data?.question??[])
        })
    }
    const getDocData = (name) => {
        setDocDatas([])
        setDocFetch(true)

        setCumDocName(name)

        // return console.log(name, noteQuery, 'noteQuery name')
        NoteApi.getDocInfoByHos({
            hospital_id: noteQuery.hosId,
            department_id: noteQuery.depId,
            doctor_name: name,
            page: 1,
            per_page: 20,
        }).then((res) => {
            // console.log(res, name, initOptions(res?.data), 'getDocOptionInfo')
            if (res?.data?.length > 0) {
                setDocDatas(initOptions(res?.data))
                setDocFetch(false)
            }

        }).catch((e) => {
            console.log(e, 'e0000')
        })
    }
    const getSymptomData = (name) => {
        setSymptomDatas([])
        setSymptomFetch(true)

        NoteApi.getSickByName({
            type: 'symptom',
            name: name,
            page: 1,
            per_page: 20,
        }).then((res) => {
            console.log(res, 'getSymptomData')
            setSymptomDatas(res?.data)
            setSymptomFetch(false)

        }).catch((e) => {
            console.log(e, 'e0000')
        })
    }

    const getSickData = (name) => {
        setSickDatas([])
        setSickFetch(true)

        NoteApi.getSickByName({
            type: 'disease',
            name: name,
            page: 1,
            per_page: 20,
        }).then((res) => {
            console.log(res, 'disease')
            setSickDatas(res?.data)
            setSickFetch(false)

        }).catch((e) => {
            console.log(e, 'e0000')
        })

    }

    const symptomOnChange = (value) => {
        setSymptomDatas([])
        setSymptomFetch(false)
        setSymptomValue(value)

        console.log(value, '---symptomOnChange')
    }

    const sickOnChange = (value) => {
        setSickDatas([])
        setSickFetch(false)
        setSickValue(value)

        console.log(value, '---sickOnChange')
    }

    const treatOnChange = (value) => {
        //治疗方式 暂未治疗与其他的互斥
        // console.log(value, '---treatOnChange')
        if (value && value[0]&& value[0].label === '暂未治疗') {
            form.setFieldsValue({
                noteTreatType: value.slice(1, value.length),
            })
            // return value.slice(1, value.length)
        }

    }
    const treatOnSelect = (value) => {
        console.log(value, '---treatOnSelect')
        if (value.label === '暂未治疗') {
            setTreatTypeOpen(false)
            return form.setFieldsValue({
                noteTreatType: [value],
            })
        }

    }
    //格式化html 数据
    const initHtmlParam = (htmlStr) => {
        const trimStr = htmlStr.trim()
        if (trimStr.startsWith('<div>')) {
            return trimStr
        }
        console.log(`<div>${trimStr}</div>`, 'asdasdasdasds')
        return `<div>${trimStr}</div>`
    }

    useEffect(() => {
        // console.log('123123123123------', props.location, noteHTML)
        clearTimer()


        autoSave()
        //医院科室
        getHosOptionInfo('')
    //
        getTreatTypeOptions()
        // console.log(params, 'params---1123123useEffect', params && params.noteId, params.articleId, params && !params.articleId, noteId, draftId)
        getNoteNewDataOptions((docOptions, hosOptions, quesOptions) => {
            if (params && params.noteTitle) {
                form.setFieldsValue({
                    noteTitle: params.noteTitle || '',
                })
                setTitle(params.noteTitle || '')
                return
            }
            if (params && params.noteId) {

                let funcGet = null
                if (params.articleId) {
                    funcGet = NoteApi.getArticleDetail({
                        note_id: params.noteId,
                        article_id: params.articleId,
                    })
                }
                if (params.draftId) {
                    funcGet = NoteApi.getDraftDetail({
                        note_id: params.noteId,
                        draft_id: params.draftId,
                    })
                }
                // console.log(funcGet, 'funcGet----')
                funcGet.then((res) => {
                    const {
                        article,
                        draft_id,
                        note_id,
                        title,
                    } = res?.data
                    console.log(res?.data, seeDocReaOptions, quesOptions, 'temporary_doctor')
                    if (article?.treatment?.see_doc_rea && docOptions.indexOf(article?.treatment?.see_doc_rea) < 0) {
                        setSeeDocQitaInput(true)
                    }
                    if (article?.treatment?.sec_hos_rea && hosOptions.indexOf(article?.treatment?.sec_hos_rea) < 0) {
                        setSeeHosQitaInput(true)
                    }

                    const allQuetions = article?.treatment?.question??[]
                    const defaultChoosedQuestions = allQuetions?.filter(i => quesOptions.indexOf(i) >= 0)
                    const inputQuestions = allQuetions?.filter(i => quesOptions.indexOf(i) < 0)
                    // console.log(defaultChoosedQuestions, inputQuestions, 'inputQuestions')

                    setTitle(title)
                    setInitHtml(initHtmlParam(article?.content))
                    setNoteHTML(initHtmlParam(article?.content))
                    setHosValue(article?.treatment?.hospital_name ? article?.treatment?.hospital_name + ' / ' + article?.treatment?.department_name : '')
                    setNoteQuery({...noteQuery,
                        hosId: article?.treatment?.hospital_id,
                        depId: article?.treatment?.department_id,
                        docId: article?.treatment?.doctor_id,
                        hosName: article?.treatment?.hospital_name,
                    })
                    form.setFieldsValue({
                        noteTitle: title,
                        noteType: defaultTypeOption.filter((item) => article.articles_type_ids.some((it) => it === item.id)).map(it => it.name),
                        noteSubTitle: article.title,
                        noteHos: article?.treatment?.hospital_id ? [article?.treatment?.hospital_id, article?.treatment?.department_id] : undefined,
                        noteDoc: {
                            key: article?.treatment?.doctor_id ? article?.treatment?.doctor_id : article?.treatment?.temporary_doctor,
                            label: article?.treatment?.doctor_name ? article?.treatment?.doctor_name : article?.treatment?.temporary_doctor,
                            value: article?.treatment?.doctor_id ? article?.treatment?.doctor_id : article?.treatment?.temporary_doctor,
                        },
                        noteTreatTime: article?.treatment?.treat_time ? moment(article?.treatment?.treat_time, 'YYYY-MM-DD') : undefined,
                        noteSymptom: initOptions(article?.treatment?.treat_symptoms),
                        noteSick: initOptions(article?.treatment?.treat_diseases),
                        noteTreatType: initOptions(article?.treatment?.treat_methods),
                        noteCost: article?.treatment?.cost,
                        noteDuration: article?.treatment?.spend_time ? [{
                            key: article?.treatment?.spend_time,
                            label: article?.treatment?.spend_time,
                            value: article?.treatment?.spend_time,
                        }] : undefined,
                        noteNowState: article?.treatment?.now_state ?? undefined,
                        //2.3.17新增字段
                        see_doc_rea: article?.treatment?.see_doc_rea ? (
                            docOptions.indexOf(article?.treatment?.see_doc_rea) < 0 ? {
                            key: '其他',
                            label: '其他',
                            value: '其他',
                        }: {
                            key: article?.treatment?.see_doc_rea,
                            label: article?.treatment?.see_doc_rea,
                            value: article?.treatment?.see_doc_rea,
                        }) : undefined,
                        see_doc_rea_other: article?.treatment?.see_doc_rea && docOptions.indexOf(article?.treatment?.see_doc_rea) < 0 ? article?.treatment?.see_doc_rea : null,
                        sec_hos_rea: article?.treatment?.sec_hos_rea ? (hosOptions.indexOf(article?.treatment?.sec_hos_rea) < 0 ? {
                            key: '其他',
                            label: '其他',
                            value: '其他',
                        } : {
                            key: article?.treatment?.sec_hos_rea,
                            label: article?.treatment?.sec_hos_rea,
                            value: article?.treatment?.sec_hos_rea,
                        }) : undefined,
                        sec_hos_rea_other: article?.treatment?.sec_hos_rea && hosOptions.indexOf(article?.treatment?.sec_hos_rea) < 0 ? article?.treatment?.sec_hos_rea : null,
                        appointment: article?.treatment?.appointment ? {
                            key: article?.treatment?.appointment,
                            label: article?.treatment?.appointment,
                            value: article?.treatment?.appointment,
                        } : undefined,
                        transport: article?.treatment?.transport ? {
                            key: article?.treatment?.transport,
                            label: article?.treatment?.transport,
                            value: article?.treatment?.transport,
                        } : undefined,
                        xdData: article?.treatment?.data??undefined,
                        question: defaultChoosedQuestions??[],
                        names: inputQuestions??[],
                        medical_cost: article?.treatment?.medical_cost??undefined,
                        friendly_reminder: article?.treatment?.friendly_reminder??undefined,
                    })

                    getAllMoney()
                })
                    .catch((e) => {
                        console.log(e, 'erere')
                        // props.history.push('/empty')
                        // setTimeout(() => {
                        //     props.history.replace(`/note`)
                        // });
                        props.history.replace(`/note`)
                    })
            }
        })


    }, [props.location.search])
    useEffect(() => {
        // if(isReal === 'false'){
        //     setIsRealModal(true)
        // }
        const userId = JSON.parse(localStorage.getItem('userInfo')).id
        UserApi.getUserInfo(userId).then(res => {
            if(res.success) {
                setIsRealModal(!res.data.identity_authentication,)
            }
        })
    }, [])


    const hosOnChange = (value, selectedOptions) => {

        const getLabeledArry = selectedOptions.map((item) => item.label)
        const getLabeledValue = getLabeledArry.join(' / ')

        setNoteQuery({
            ...noteQuery,
            hosId: value[0],
            depId: value[1],
            hosName: getLabeledArry[0] || ''
        })
        console.log(value, selectedOptions, getLabeledValue, noteQuery, 'value---', getLabeledArry)
        setHosValue(getLabeledValue)




        form.setFieldsValue({
            noteHos: getLabeledValue
        })
    }
    useEffect(() => {
        if (noteQuery && noteQuery.hosId && noteQuery.depId) {
            // console.log(noteQuery, 'noteQuery---123123')
            getDocData('')
        }
    }, [noteQuery.hosId, noteQuery.depId])
    const handleDocChange = (value) => {
        console.log(value, 'handleDocChange----')

        setDocFetch(false)
        setDocDatas([])
        setDocValue(value)

        setNoteQuery({
            ...noteQuery,
            docId: value?.value
        })

    }

    const onDateChange = (date, dateString) => {
        // console.log(date, dateString, 'onDateChange');
        setNoteQuery({
            ...noteQuery,
            dateStr: dateString,
        })
    }
    const getImgInfo = (url) => {
        const initurl = url.substring(0, url.indexOf('?') <0 ? url.length : url.indexOf('?'))
        // console.log(initurl, url, 'initurl---')
        return Axios.get(initurl+'?imageInfo',null)
    }
    //获取返回数据中的图片链接
    const getImgsUrlArryInContent = (curItemValues) => {
        let returnImgsArry = []
        let promiseArry = []
        if (Array.isArray(curItemValues)) {
            curItemValues.forEach((item) => {
                if (item.component == 'image') {
                    promiseArry.push(getImgInfo(item.url))
                    returnImgsArry.push(item.url)
                }
            })
        }
        // console.log(returnImgsArry, promiseArry, curItemValues, 'returnText')

        return {
            returnImgsArry,
            promiseArry
        }
    }
    const getImgObjArry = (promiseArry) => {
        return Promise.all(promiseArry).then((result) => {
            // console.log(result)       // [ '3秒后醒来', '2秒后醒来' ]
            return result
        }).catch((error) => {
            // console.log(error)
            return error
        })
    }
    const goToEvaluate = (res) => {
        message.success('发布成功')
        const docName = form.getFieldValue('noteDoc').label
        const hosName = noteQuery.hosName

        form.resetFields()
        setHosValue('')
        setInitHtml('')



        props.history.replace(`/eval?noteId=${res?.data?.note_id}&articleId=${res?.data?.article_id}&docName=${docName}&hosName=${hosName}`)
        // props.history.push({
        //     path: '/eval'
        // })
    }
    const initHtmlStr = (htmlStr) => {
        let trimStr = htmlStr.trim()
        if (!trimStr.startsWith('<div>')) {
            trimStr =  `<div>${trimStr}</div>`
        }
        return trimStr
    }
    const getAllMoney = () => {
        let all = 0
        const medical_cost = form.getFieldValue('medical_cost') || 0
        const noteCost = form.getFieldValue('noteCost') || 0
        // console.log(medical_cost, noteCost)
        all = medical_cost + noteCost
        setAllCost(all)
    }

    // console.log(noteQuery, initHtml, 'noteQuery---')
    const onFinish = async (values, forSave = false) => {
        //类似form.getValue  获取定时器当时的html value(定时器初始值html 获取不到)
        const noteHtmlValue = editRef.current.innerFn && editRef.current.innerFn()
        console.log(noteId, params, noteHTML, noteHtmlValue, autoSaveFlag, 'onFinish----')
        // if (!noteHTML) {
        //     return console.log(noteHTML, 'log------123123123')
        // }
        // return console.log(form.getFieldValue('noteDoc'), form.getFieldValue('noteHos'))
        // console.log('Received values of form: ', values, hosValue);
        if (seeDocQiTaInput && !form.getFieldValue('see_doc_rea_other')) {
            return message.error('请输入就医原因！')
        }
        if (seeHosQiTaInput && !form.getFieldValue('sec_hos_rea_other')) {
            return message.error('请输入选择该医院原因！')
        }
        let {
            noteTitle,
            noteType,
            noteSubTitle,
            noteHos,
            noteDoc,
            noteTreatTime,
            noteSymptom,
            noteSick,
            noteTreatType,
            noteCost,
            noteDuration,
            noteNowState,

            medical_cost,
            see_doc_rea,
            see_doc_rea_other,
            sec_hos_rea,
            sec_hos_rea_other,
            appointment,
            transport,
            xdData,
            question,
            names,
            friendly_reminder,
        } = values
        let noteTypeIds = undefined
        if(noteType) {
            noteTypeIds = defaultTypeOption.filter((item) => noteType.some((it) => it === item.name)).map(it => it.id)
        }

        const noteObjValue = convertToObject(initHtmlStr(noteHtmlValue))
        if (!noteHtmlValue && !forSave) {
            return message.error('请填写笔记内容！')
        }
        const {returnImgsArry, promiseArry} = getImgsUrlArryInContent(noteObjValue)
        const imgs = returnImgsArry
        const data = await getImgObjArry(promiseArry)
        // console.log(imgs, data, convertToHtmlString(noteObjValue), 'ids---')
        let noteImgs = []
        for (let i=0;i<imgs.length;i++)
        {
            let obj = {
                url: imgs[i],
                wide: data[i]?.data?.width,
                high: data[i]?.data?.height,
                size: data[i]?.data?.size,
            }
            noteImgs.push(obj)
        }
        // const noteImgs = []
        if (!Array.isArray(noteHos)) {
            noteHos = [noteQuery.hosId, noteQuery.depId]
        }
        if (!Array.isArray(noteTreatType)) {
            noteTreatType= [noteTreatType]
        }
        if (!Array.isArray(noteSick)) {
            noteSick= [noteSick]
        }
        if (!Array.isArray(noteSymptom)) {
            noteSymptom= [noteSymptom]
        }
        if (!Array.isArray(noteDuration)) {
            noteDuration= [noteDuration]
        }
        // if (!Array.isArray(noteNowState)) {
        //     noteNowState= [noteNowState]
        // }

        const query = {
            "note_id": noteId,
            "draft_id": draftId,
            "title": noteTitle,
            "article": {
                // "article_id": params?.articleId ? params.articleId : undefined,
                "title": noteSubTitle,
                "articles_type_ids":noteTypeIds,
                "content":convertToHtmlString(noteObjValue),
                "images": noteImgs,
                "contentValue": null,
                "treatment":{
                    "hospital_id":noteHos[0],
                    "department_id":noteHos[1],
                    "doctor_id":noteDoc?.value === noteDoc?.label ? null : noteDoc.value,
                    "temporary_doctor":noteDoc?.value === noteDoc?.label ? noteDoc?.value : null,
                    "treat_time":noteTreatTime ? moment(noteTreatTime).format('YYYY-MM-DD') : undefined,
                    "treat_methods":noteTreatType?.map((item) => item?.value),
                    "treat_diseases":noteSick?.map((item) => item?.value),
                    "treat_symptoms":noteSymptom?.map((item) => item?.value),
                    "cost":noteCost,
                    "spend_time":noteDuration[0]?.value,
                    "now_state":noteNowState,

                    "medical_cost": medical_cost,
                    "see_doc_rea": see_doc_rea?.value === '其他' ? see_doc_rea_other : see_doc_rea?.value,
                    "sec_hos_rea": sec_hos_rea?.value === '其他' ? sec_hos_rea_other : sec_hos_rea?.value,
                    "appointment": appointment?.value,
                    "transport": transport?.value,
                    "data": xdData,
                    "question": !Array.isArray(question) ? question : (!Array.isArray(names) ? [...question]: [...question, ...names]),
                    "friendly_reminder": friendly_reminder
                }
            }
        }
        console.log(query, 'query')
        //保存
        if (forSave) {
            setAutoSaveFlag(1)
            return NoteApi.saveNoteDraft(query).then((res) => {
                // console.log(res, query, params, '保存新笔记')
                setNoteId(res?.data?.note_id)
                setDraftId(res?.data?.draft_id)
                setAutoSaveFlag(2)
                // console.log(!params || !params.noteId || !params.draftId, '!params || !params.noteId || !params.draftId')
                //第一次保存使用replacē 添加路由参数不会触发重载而进入生命周期 故先以一下方式先push在replace的方案
                if (!params || !params.noteId || !params.draftId) {
                    props.history.replace(`/note?noteId=${res?.data?.note_id}&draftId=${res?.data?.draft_id}`)
                    // props.history.push('/empty')
                    // setTimeout(() => {
                    //     props.history.replace(`/note?noteId=${res?.data?.note_id}&draftId=${res?.data?.draft_id}`)
                    // });
                } else {
                    // console.log('123123123123 跳出')
                }


                setTimeout(() => {
                    setAutoSaveFlag(0)
                }, 3000)

                // message.success('保存成功')
            }).catch((e) => {
                // console.log(e, query, 'error')
                message.error('保存失败')
                setAutoSaveFlag(0)
            })
        }
        //发布新笔记
        if (!params || !params.noteId)  {
            return NoteApi.sendNewNote(query).then((res) => {
                // console.log(res, query, '发布新笔记')
                goToEvaluate(res)

            }).catch((e) => {
                // console.log(e, query, 'error')
                message.error('发布失败')
            })
        }
    //    追加笔记
        if (params && params.noteId && !params.articleId) {
            return NoteApi.sendNewNote(query).then((res) => {
                // console.log(res, query, '追加笔记')
                goToEvaluate(res)

            }).catch((e) => {
                // console.log(e, query, 'error')
                message.error('发布失败')
            })
        }
    //    编辑笔记
        if (params && params.noteId && params.articleId) {
            query.article.article_id = params.articleId
            return NoteApi.editNote(query).then((res) => {
                console.log(res, query, '编辑笔记')
                goToEvaluate(res)
            }).catch((e) => {
                console.log(e, query, 'error')
                message.error('发布失败')
            })
        }
    }
    const saveNote = () => {
        // console.log(noteHTML, 'noteHTML------save')
        const values = form.getFieldsValue()
        const initValues = {
            ...values,
            noteTitle: values.noteTitle ? values.noteTitle : '',
            // noteType: values.noteType ? values.noteType : [],
            // noteSubTitle: values.noteSubTitle ? values.noteSubTitle : '',
            // noteHos,
            // noteDoc,
            // noteTreatTime,
            // noteSymptom,
            // noteSick,
            // noteTreatType,
            // noteCost,
            // noteDuration,
            // noteNowState,
        }
        onFinish(initValues, true)
    }
    const autoSave = () => {

        // return console.log(params, noteId, noteHTML, 'autosave-----')
        //只有已经是草稿了，才触发自动保存
        if (!params || !params.noteId || !params.draftId) {
            return clearTimer()
        }
        let id = setInterval(() => {
            // console.log(`auto carried out`,intervalRef, props.history, noteId,) //timer Number Id
            if (props?.history?.location?.pathname !== '/note') {
                clearTimer()
            } else {
                // console.log(noteId, 'noteId------')
                // return console.log(editRef.current.innerFn && editRef.current.innerFn())
                setNoteHTML(editRef.current.innerFn && editRef.current.innerFn())
                saveNote()
            }
            //
        }, 180000);
        intervalRef.current = id;
    }
    let clearTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            // console.log("leave result:",intervalRef);
        }

    }

    const showIframe = () => {

        // if (!params || !params.noteId) {
        //     return message.error('请先保存！')
        // }

        // console.log(noteHTML, 'initHtml-----',  form.getFieldsValue(), form.getFieldsValue().noteDoc, form.getFieldsValue().noteDoc ? (form.getFieldsValue().noteDoc.value === form.getFieldsValue().noteDoc.label ? form.getFieldsValue().noteDoc.value : null) : null)
        // localStorage.setItem('htmlStr', noteHTML)
        // window.frames.postMessage('some messages', 'http://localhost:10086/#/pages/notes-detail/preview/preview');
        // window.name = noteHTML
        // setTimeout(() => {
        //     window.parent.postMessage(noteHTML, 'http://localhost:10086')
        // }, 2000)

        // window.addEventListener( "message",
        //     function(e){
        //         console.log(e, '监听加载完毕');
        //         window.postMessage(noteHTML, 'https://www.baidu.com')
        //         // if(e.data == 'loaded') {
        //         //     return window.postMessage(noteHTML, '*')
        //         // }
        //         return
        //     },false);


        // saveNote()
        setPreviewModal(true)
    }

    const seeDocReasonChange = (value) => {
       console.log(value, 'value----')
        if (value.value === '其他') {
            setSeeDocQitaInput(true)
        } else {
            setSeeDocQitaInput(false)
        }
    }
    const seeHosReasonChange = (value) => {
        console.log(value, 'value----')
        if (value.value === '其他') {
            setSeeHosQitaInput(true)
        } else {
            setSeeHosQitaInput(false)
        }
    }


    return (
        <div className={styles.container}>
            <Form
                form={form}
                name="register"
                onFinish={onFinish}
                autoComplete="off"
                requiredMark={false}
            >
            <div className={styles.user_wrap}>
                <div className={styles.user_wrap_inner}>


                <div className={styles.line1}>
                    <div className={styles.title_text}>就医分享标题</div>
                    <Link to={`/noteList?tabKey=2`} target='_blank'>
                        <div className={styles.trash_text}>
                            进入草稿箱 ({props?.draftNotes.length || 0})
                            <img src={Images.note.rightArrow} className={styles.rightArrow} />
                        </div>
                    </Link>
                </div>
                <Form.Item
                    name="noteTitle"
                    rules={[{ required: true, message: '请输入标题!' }]}
                >

                        <Input
                            placeholder="为您的笔记取个标题吧"
                            onChange={titleOnchange}
                            bordered={false}
                            size={"large"}
                            className={styles.titlt_input}
                            maxLength={maxLength}
                            suffix={
                                <span className={styles.title_shenyu}>{title.length}/{maxLength}</span>
                            }
                        />

                </Form.Item>


                <Divider className={styles.divider}/>


                <div className={styles.line2}>
                    {/*<div className={styles.type_title}>笔记类型</div>*/}
                    <Form.Item
                        name="noteType"
                        label={<div className={styles.type_title}>就医分享类型（可多选）</div>}
                        labelAlign={'left'}
                        labelCol={{
                            className: styles.type_title
                        }}
                        colon={false}
                        rules={[{ required: true, message: '请选择类型!' }]}
                    >
                        <CheckboxGroup
                            options={defaultTypeOption.map(it => it.name)}
                            // value={checkedList}
                            onChange={(list) => {
                                setTimeout(() => {
                                    console.log('123', list)
                                }, 0)
                                // console.log('123', list)
                                // setCheckedList(list)
                                // form.setFieldsValue({
                                //     noteType: list,
                                // });

                            }}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    name="noteSubTitle"
                    rules={[{ required: true, message: '请输入小标题!' }]}
                >
                <div className={styles.line2}>
                    <div className={styles.type_title}>就医分享小标题</div>
                    <Row style={{flex: '1'}}>
                        <Form.Item
                            name="noteSubTitle"
                            style={{flex: '1'}}
                        >
                            <Input
                                placeholder="6个字以内，如：就诊、术后康复"
                                onChange={titleOnchange}
                                className={styles.titlt_input_type}
                                bordered={false}
                                maxLength={6}
                            />
                        </Form.Item>
                        <Divider className={styles.divider_type}/>
                    </Row>

                </div>
                </Form.Item>

                <Row justify="space-between" className={styles.choose_row}>
                    <Col span={8}>
                        <div className={styles.titleWithMar}>就诊医院及科室</div>
                        <Form.Item
                            name="noteHos"
                            rules={[{ required: true, message: '请输入就诊医院及科室!' }]}
                        >
                            <div style={{width: '95%'}}>
                            <Cascader
                                options={hosOptions}
                                onChange={hosOnChange}
                                // className={styles.colContent}

                            >

                                <InputView
                                    placeholder="请选择"
                                    onChange={(e) => {

                                        console.log(e.target.value, 'e----111')
                                        setHosValue(e.target.value)

                                        getHosOptionInfo(e.target.value)
                                    }}
                                    value={hosValue}
                                    onKeyDown={e => e.stopPropagation()}
                                    suffix={
                                        hosValue?.length > 0 ?  (
                                                <CloseCircleOutlined
                                                    style={{color: '#d9d9d9', fontSize: '12px'}}
                                                    onClick={() => {
                                                        setHosValue('')
                                                        form.setFieldsValue({
                                                            noteHos: ''
                                                        })
                                                    }}
                                                />
                                        ) :
                                            <DownOutlined style={{color: '#d9d9d9', fontSize: '12px'}} />
                                    }
                                />

                            </Cascader>
                            </div>
                        </Form.Item>

                    </Col>
                    <Col span={8}>
                        <div className={styles.titleWithMar}>接诊医生</div>
                        <Form.Item
                            name="noteDoc"
                            rules={[{ validator: (rule, value) => {
                                    console.log(rule, value, checkedList, 'pppp', form.getFieldsValue(),form.getFieldValue('noteHos'))
                                    if (!form.getFieldValue('noteHos')) {
                                        return Promise.reject('请先选择就诊医院及科室!');
                                    }
                                    if (!value) {
                                        return Promise.reject('请输入接诊医生!');
                                    }
                                    return Promise.resolve()
                                }}]}
                            // rules={[{ required: true, message: '请输入接诊医生!' }]}
                        >
                        <Select
                            className={styles.colContent}
                            labelInValue
                            value={docValue}
                            open={docMenuOpen}
                            onDropdownVisibleChange={open => {
                                setDocMenuOpen(open)
                            }}
                            // onFocus={debounce(getDocData, 300)}
                            placeholder="请选择"
                            notFoundContent={docFetch ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={debounce(getDocData, 300)}
                            onChange={handleDocChange}
                            showSearch={true}
                            dropdownRender={(menu) => {
                                return (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div
                                            style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, color: '#000' }}
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={() => {
                                                setDocValue([{
                                                    key: cumDocName,
                                                    label: cumDocName,
                                                    value: cumDocName,
                                                }])
                                                setDocDatas([])
                                                setDocFetch(false)
                                                setDocMenuOpen(false)
                                                form.setFieldsValue({
                                                    noteDoc: {
                                                        key: cumDocName,
                                                        label: cumDocName,
                                                        value: cumDocName,
                                                    },
                                                });
                                                // console.log('111', cumDocName)
                                            }}
                                        >
                                            {cumDocName}(手动输入)
                                        </div>
                                    </div>
                                )
                            }}
                        >
                            {docDatas.map((d,ind) => (
                                <Option key={d.value}>{d.name}</Option>
                            ))}
                        </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className={styles.titleWithMar}>就诊时间</div>
                        <Form.Item
                            name="noteTreatTime"
                            rules={[{ required: true, message: '请输入就诊时间!' }]}
                        >
                            <DatePicker
                                className={styles.colContent}
                                onChange={onDateChange}
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" className={styles.choose_row}>
                    <Col span={8}>
                        <div className={styles.titleWithMar}>症状表现（可多选）</div>
                        <Form.Item
                            name="noteSymptom"
                            rules={[{ required: true, message: '请输入症状表现!' }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="请选择"
                                labelInValue
                                value={symptomValue}
                                notFoundContent={symptomFetch ? <Spin size="small" /> : null}
                                filterOption={false}
                                allowClear
                                className={styles.colContent}
                                onChange={symptomOnChange}
                                onSearch={debounce(getSymptomData, 300)}

                            >
                                {symptomDatas.map((d,ind) => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className={styles.titleWithMar}>确诊疾病</div>
                        <Form.Item
                            name="noteSick"
                            rules={[{ required: true, message: '请输入确诊疾病!' }]}
                        >
                        <Select
                            // mode="multiple"
                            placeholder="请选择"
                            labelInValue
                            value={sickValue}
                            notFoundContent={sickFetch ? <Spin size="small" /> : null}
                            filterOption={false}
                            allowClear
                            className={styles.colContent}
                            onChange={sickOnChange}
                            onSearch={debounce(getSickData, 300)}
                            showSearch={true}
                        >
                            {sickDatas.map((d,ind) => (
                                <Option key={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                        </Form.Item>
                    </Col>
                    {/*<Col span={8}>*/}
                    {/*    <div className={styles.titleWithMar}>治疗方式</div>*/}
                    {/*    <Form.Item*/}
                    {/*        name="noteTreatType"*/}
                    {/*        rules={[{ required: true, message: '请输入治疗方式!' }]}*/}
                    {/*    >*/}
                    {/*    <Select*/}
                    {/*        mode="multiple"*/}
                    {/*        placeholder="请选择"*/}
                    {/*        labelInValue*/}
                    {/*        className={styles.colContent}*/}
                    {/*        onChange={treatOnChange}*/}
                    {/*        onSelect={treatOnSelect}*/}
                    {/*        open={treatTypeOpen}*/}
                    {/*        onDropdownVisibleChange={open => {*/}
                    {/*            setTreatTypeOpen(open)*/}
                    {/*        }}*/}
                    {/*        showSearch={false}*/}
                    {/*    >*/}
                    {/*        {treatTypeOptions.map((d,ind) => (*/}
                    {/*            <Option key={d.id}>{d.name}</Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={8}>
                        <div className={styles.titleWithMar}>就医原因</div>
                        <Form.Item
                            name="see_doc_rea"
                            rules={[{ required: true, message: '请选择就医原因!' }]}

                        >
                            <Select
                                placeholder="请选择"
                                labelInValue
                                className={styles.colContent}
                                onChange={seeDocReasonChange}
                                showSearch={false}
                            >
                                {seeDocReaOptions.map((d,ind) => (
                                    <Option key={ind} value={d}>{d}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {
                            seeDocQiTaInput &&
                            <Form.Item
                                name="see_doc_rea_other"
                                rules={[{ required: true, message: '请输入就医原因!' }]}
                            >
                                <Input
                                    maxLength={50}
                                    style={{marginTop: 10, width: '95%'}}
                                    placeholder="请输入就医原因"
                                />
                            </Form.Item>
                        }

                    </Col>
                </Row>
                <Row justify="space-between" className={styles.choose_row}>
                    <Col span={8}>
                        <div className={styles.titleWithMar}>选择该医院原因</div>
                        <Form.Item
                            name="sec_hos_rea"
                            rules={[{ required: true, message: '请选择该医院原因!' }]}
                        >
                            <Select
                                placeholder="请选择"
                                labelInValue
                                className={styles.colContent}
                                onChange={seeHosReasonChange}
                                showSearch={false}
                            >
                                {secHosReaOptions.map((d,ind) => (
                                    <Option key={ind} value={d}>{d}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {
                            seeHosQiTaInput &&
                            <Form.Item
                                name="sec_hos_rea_other"
                                rules={[{ required: true, message: '请输入选择该医院原因!' }]}
                            >
                                <Input
                                    maxLength={50}
                                    style={{marginTop: 10, width: '95%'}}
                                    placeholder="请输入选择该医院原因"
                                />
                            </Form.Item>
                        }
                    </Col>
                    <Col span={8}>
                        <div className={styles.titleWithMar}>就医准备-预约方式</div>
                        <Form.Item
                            name="appointment"
                            rules={[{ required: true, message: '请选择预约方式!' }]}
                        >
                            <Select
                                placeholder="请选择"
                                labelInValue
                                className={styles.colContent}
                                // onChange={treatOnChange}
                                showSearch={false}
                            >
                                {appointmentOptions.map((d,ind) => (
                                    <Option key={ind} value={d}>{d}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className={styles.titleWithMar}>就医准备-交通方式</div>
                        <Form.Item
                            name="transport"
                            rules={[{ required: true, message: '请选择交通方式!' }]}
                        >
                            <Select
                                placeholder="请选择"
                                labelInValue
                                className={styles.colContent}
                                // onChange={treatOnChange}
                                showSearch={false}
                            >
                                {transportOptions.map((d,ind) => (
                                    <Option key={ind} value={d}>{d}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {/*<Row justify="space-between">*/}
                {/*    <Col span={8}>*/}
                {/*        <div className={styles.titleWithMar}>本次就医花费</div>*/}
                {/*        <Form.Item*/}
                {/*            name="noteCost"*/}
                {/*            rules={[{ required: true, message: '请输入就医花费!' }]}*/}
                {/*        >*/}
                {/*            <InputNumber*/}
                {/*                className={styles.colContent}*/}
                {/*                precision={2}*/}
                {/*                // defaultValue={''}*/}
                {/*                formatter={value => {*/}
                {/*                    return `¥ ${value}`*/}
                {/*                }}*/}
                {/*                // parser={value => {*/}
                {/*                //     console.log(value, 'value---parser')*/}
                {/*                //     return `¥ ${value}`.replace('¥ ', '')*/}
                {/*                // }}*/}
                {/*            />*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <div className={styles.titleWithMar}>累计就诊时长</div>*/}
                {/*        <Form.Item*/}
                {/*            name="noteDuration"*/}
                {/*            rules={[{ required: true, message: '请输入累计就诊时长!' }]}*/}
                {/*        >*/}
                {/*        <Select*/}
                {/*            placeholder="请选择"*/}
                {/*            labelInValue*/}
                {/*            className={styles.colContent}*/}
                {/*            // onChange={treatOnChange}*/}
                {/*            showSearch={false}*/}
                {/*        >*/}
                {/*            {defaultDuration.map((d,ind) => (*/}
                {/*                <Option key={d}>{d}</Option>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <div className={styles.titleWithMar}>目前病情状况</div>*/}
                {/*        <Form.Item*/}
                {/*            name="noteNowState"*/}
                {/*            rules={[{ required: true, message: '请输入目前病情状况!' }]}*/}
                {/*        >*/}
                {/*        <Select*/}
                {/*            placeholder="请选择"*/}
                {/*            labelInValue*/}
                {/*            className={styles.colContent}*/}
                {/*            // onChange={treatOnChange}*/}
                {/*            showSearch={false}*/}
                {/*        >*/}
                {/*            {defaultNowState.map((d,ind) => (*/}
                {/*                <Option key={d.id}>{d.name}</Option>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                    <Row justify="space-between" className={styles.choose_row}>
                        <Col span={24}>
                            <div className={styles.titleWithMar}>就医准备-携带材料（可多选）</div>
                            <Form.Item
                                name="xdData"
                                rules={[{ required: true, message: '请选择携带材料!' }]}
                            >
                                <CheckboxGroup
                                    options={xdDataOptions}
                                    // value={checkedList}
                                    onChange={(list) => {
                                        setTimeout(() => {
                                            console.log('123', list)
                                        }, 0)
                                        // console.log('123', list)
                                        // setCheckedList(list)
                                        // form.setFieldsValue({
                                        //     noteType: list,
                                        // });

                                    }}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row justify="space-between" className={styles.choose_row}>
                        <Col span={24}>
                            <div className={styles.titleWithMar}>就医准备-问诊问题（非必选）</div>
                            <Form.Item
                                name="question"
                                rules={[{ required: false, message: '请选择问诊问题!' }]}
                            >
                                <CheckboxGroup
                                    options={questionOptions}
                                    // value={checkedList}
                                    onChange={(list) => {
                                        setTimeout(() => {
                                            console.log('123', list)
                                        }, 0)
                                        // console.log('123', list)
                                        // setCheckedList(list)
                                        // form.setFieldsValue({
                                        //     noteType: list,
                                        // });

                                    }}
                                />
                            </Form.Item>
                            <Form.List name="names">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "请输入",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input
                                                        placeholder="其他问题（请输入）"
                                                        style={{ width: '40%', marginTop: '12px' }}
                                                        // onBlur={() => {
                                                        //     const currentInputValue = form.getFieldValue(['names', field.name])
                                                        //
                                                        //     if (questionOptions.indexOf(currentInputValue) >=0) {
                                                        //         return message.error('选项已存在！')
                                                        //     } else {
                                                        //         setQuestionOptions([...questionOptions, currentInputValue])
                                                        //     }
                                                        //
                                                        // }}
                                                    />
                                                </Form.Item>
                                                <Button
                                                    type="text"
                                                    danger
                                                    onClick={() => {
                                                        const currentInputValue = form.getFieldValue(['names', field.name])
                                                        remove(field.name)
                                                        // setQuestionOptions(questionOptions.filter(i => i !== currentInputValue))
                                                        // //若已选中的选项被删除 需要冲洗设置选中项 不然提交时被删除选项不会被剔除
                                                        // const hasChooseOptions = form.getFieldValue('question')
                                                        // // console.log(hasChooseOptions, 'hasChooseOptions----')
                                                        // if (hasChooseOptions && hasChooseOptions.indexOf(currentInputValue) >=0) {
                                                        //     form.setFieldsValue({
                                                        //         question: hasChooseOptions.filter(i => i !== currentInputValue),
                                                        //     })
                                                        // }

                                                    }}
                                                >
                                                    删除
                                                </Button>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    let alllength = 0
                                                    alllength = form.getFieldValue('names')?.length??0
                                                    if (alllength > 9) {
                                                        return message.error('无法添加更多')
                                                    }
                                                    add()
                                                }}
                                                style={{ backgroundColor: 'rgba(64, 166, 255, 0.1)', color: 'rgba(64, 166, 255, 1)', border: 'none', fontWeight: '400', marginTop: '12px' }}
                                            >
                                                继续添加
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>

                    </Row>
                    <Row justify="space-between" className={styles.choose_row}>
                        <Col span={24}>
                            <div className={styles.titleWithMar}>就医准备-友情提醒（非必选）</div>
                            <Form.Item
                                name="friendly_reminder"
                                rules={[{ required: false, message: '' }]}
                            >
                                <Input.TextArea
                                    placeholder="请输入"
                                    maxLength={300}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" className={styles.choose_row}>
                        <Col span={12}>
                            <div className={styles.titleWithMar}>本次就医花费<span className={styles.titleExtra}>总费用：<span className={styles.titleMoney}>¥{allCost}</span></span></div>
                            <Row justify="space-between">
                                <Col span={12}>
                                    <Form.Item
                                        label="医保"
                                    >
                                        <Row>
                                            <Form.Item name="medical_cost" rules={[{ required: true, message: '请输入' }]}>
                                                <InputNumber
                                                    placeholder="请输入"
                                                    onBlur={getAllMoney}
                                                />
                                            </Form.Item>
                                            <span className={styles.unitText}>元</span>
                                        </Row>

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="自费"
                                    >
                                        <Row>
                                            <Form.Item name="noteCost" rules={[{ required: true, message: '请输入' }]}>
                                                <InputNumber
                                                    placeholder="请输入"
                                                    onBlur={getAllMoney}
                                                />
                                            </Form.Item>
                                            <span className={styles.unitText}>元</span>
                                        </Row>

                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>
                        <Col span={12}>
                            <div className={styles.titleWithMar}>累计就医时长</div>
                            <Form.Item
                                name="noteDuration"
                                rules={[{ required: true, message: '请输入累计就诊时长!' }]}
                            >
                                <Select
                                    placeholder="请选择"
                                    labelInValue
                                    className={styles.colContent}
                                    // onChange={treatOnChange}
                                    showSearch={false}
                                >
                                    {defaultDuration.map((d,ind) => (
                                        <Option key={ind} value={d}>{d}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" className={styles.choose_row}>
                        <Col span={24}>
                            <div className={styles.titleWithMar}>治疗⽅式（可多选）</div>
                            <Form.Item
                                name="noteTreatType"
                                rules={[{ required: true, message: '请输入治疗方式!' }]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="请选择"
                                    labelInValue
                                    className={styles.colContent}
                                    onChange={treatOnChange}
                                    onSelect={treatOnSelect}
                                    open={treatTypeOpen}
                                    onDropdownVisibleChange={open => {
                                        setTreatTypeOpen(open)
                                    }}
                                    showSearch={false}
                                >
                                    {treatTypeOptions.map((d,ind) => (
                                        <Option key={d.id} value={d.id}>{d.name}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" className={styles.choose_row}>
                        <Col span={24}>
                            <div className={styles.titleWithMar}>目前病情状况</div>
                            <Form.Item
                                name="noteNowState"
                                rules={[{ required: true, message: '请输入目前病情状况!' }]}
                            >
                                {/*<Select*/}
                                {/*    placeholder="请选择"*/}
                                {/*    labelInValue*/}
                                {/*    className={styles.colContent}*/}
                                {/*    // onChange={treatOnChange}*/}
                                {/*    showSearch={false}*/}
                                {/*>*/}
                                {/*    {defaultNowState.map((d,ind) => (*/}
                                {/*        <Option key={d.id}>{d.name}</Option>*/}
                                {/*    ))}*/}
                                {/*</Select>*/}
                                <Radio.Group
                                    // options={defaultNowState.map(it => it.name)}
                                    // value={checkedList}

                                >
                                    {defaultNowState.map((d,ind) => (
                                        <Radio key={ind} value={d.id}>{d.name}</Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div className={styles.editContainer}>


                    <HywEditor initHtml={initHtml} ref={editRef} hosInfo={{hosId: noteQuery?.hosId, showValue: hosValue}} getHTML={(html) => {
                        const getHtml = html
                        // console.log(html,'html', getHtml)

                        setNoteHTML(getHtml)
                    }}/>
                </div>
            </div>
            <Footer style={{paddingBotttom: '10rem', backgroundColor: 'transparent'}}/>

            <div className={styles.fabuLine}>
                <div className={styles.fabuLineContent}>
                    <div className={styles.saveAuto}>
                        {
                            autoSaveFlag === 0 ? '' : (autoSaveFlag === 1 ? '自动保存中…' : '保存成功')
                        }

                    </div>
                    <div className={styles.saveBtn} onClick={saveNote}>
                        保存
                    </div>
                    <div className={`${styles.saveBtn} ${styles.btnMar}`} onClick={showIframe}>
                        手机端预览
                    </div>
                    <Form.Item>
                        <Button className={`${styles.saveBtn} ${styles.fabuBtn}`} type="primary" htmlType="submit">
                            发布
                        </Button>
                    </Form.Item>

                </div>

            </div>
            </Form>

            
            <ToTop
                showFillIn={true}
                onFillInClick={() => {
                    setFillModal(!fillModal)
                }}
                onToTopClick={() => window.scrollTo(0, 0)}
            />
            <Modal
                title={null}
                centered
                visible={fillModal}
                // onOk={() => setVisible(false)}
                onCancel={() => {
                    setFillModal(false)
                }}
                width={600}
                footer={null}
            >
                <iframe src={IntruUrl} width={'90%'} height={700} sandbox='allow-scripts allow-modal' loading='eager'></iframe>
            </Modal>
            <Modal
                title={null}
                centered
                visible={previewModal}
                onCancel={() => {
                    setPreviewModal(false)
                }}
                bodyStyle={{
                    padding: 0,
                }}
                className={styles.iframeModal}
                width={391}
                footer={null}
            >
                <HywIframe initHtml={noteHTML} showData={{
                    ...form.getFieldsValue(),
                    hosId: noteQuery.hosId,
                    docId: form.getFieldsValue().noteDoc ? (form.getFieldsValue().noteDoc.value === form.getFieldsValue().noteDoc.label ? null : form.getFieldsValue().noteDoc.value) : null,
                    temporary_doctor: form.getFieldsValue().noteDoc ? (form.getFieldsValue().noteDoc.value === form.getFieldsValue().noteDoc.label ? form.getFieldsValue().noteDoc.value : null) : null,
                }}/>
            </Modal>
            <Modal
                title='您还没有实名认证'
                closable={false}
                visible={isRealModal}
                centered
                footer={null}
                width={360}
                onOk={() => {

                }}
            >
                <div className={styles.real_text}>
                    根据国家相关规定，使用该功能需要进行实名认证哦~
                </div>
                <div className={styles.real_btn_wrap}>
                    <button
                        className={styles.real_btn}
                        onClick={() => {setIsRealModal(false)
                            routerHistory.push('/setting')
                        }}
                    >实名认证</button>
                </div>
            </Modal>
        </div>
    )
}
export default connect(state => {
    return {
        currentModifyNote: state.noteState.currentModifyNote,
        draftNotes: state.noteState.draftNotes
    }
}, dispatch => {
    return {
        getDraftTotal: (data) => dispatch(NoteAction.getDraftTotal(data)),
    }
})(NotePage)
