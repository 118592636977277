import React from 'react';
import  * as styles from './Protocol.module.css'
import Modal from 'react-modal';
import {Images} from '../../utils'

const customStyles = {
    content : {
      
      display: 'flex',
      flex: 1,
      padding: 0,
      margin: 0,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.6)',
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0,
      zIndex: 999,
    }
};

function ProtocolModal(props)  {
  
  return (
    <Modal
      isOpen={props.isOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={styles.protocol_modal}>
        <div className={styles.close_wrap}>
          <div className={styles.close_item} onClick={() => props.onCloseModal()}>
            <img className={styles.close_icon} alt='' src={Images.modal.closeIcon} />
          </div>
        </div>
        <div className={styles.title}>用户协议</div>
        <div className={styles.content}
          onScroll={(e) => {
            e.preventDefault();
            e.stopPropagation()
            return false
          }}
          onTouchMove={(e) => {
            e.preventDefault();
            e.stopPropagation()
            return false
          }}
          onMouseMove={(e) => {
            e.preventDefault();
            e.stopPropagation()
            return false
          }}
        > 
          <p className={styles.sub_title}>【协议说明】</p>
          <p className={styles.content_del}>本协议是您与互医网之间关于使用本服务所订立的协议。</p>
          <p className={styles.content_del}>
            在您注册并体验本产品之前，应当认真阅读本协议。请您
            务必审慎阅读本协议全部内容，充分透彻理解每项条款的内容。当您按照注册页面提示填写信息、阅读
            并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，与互医网
            达成一致，成为我们的平台用户。一旦注册，将被认为您是对本协议全部条款无保留的接受和遵守。在
            阅读本协议的过程中，如果您对本协议中的任何条款和约定持有质疑或不同意的意见，应立即停止注册。
          </p>
          <p className={styles.content_del}>
            如对协议有任何疑问，均可向互医网客服进行咨询。咨询方式为：4008657399
          </p>
          <p className={styles.sub_title}>【适用范围】</p>
          <p className={styles.content_del}>
            本协议仅适用于互医网的互联网在线服务及商品。对于通过本平台接入第三方服务和广告服务的其他公
            司或机构所收集提供的信息不适用。
          </p>
          <p className={styles.sub_title}>【词汇定义】</p>
          <p className={styles.content_del}>在本协议中的词汇定义如下：</p>
          <p>
            <span className={styles.sub_title}>互医网</span>
            <span className={styles.content_del}>
              ：是指上海忞竺科技有限公司所发布的互医网网站及移动客户端，及其他现在或将来推出的基于互医网
              的其他服务平台。
            </span>
          </p>
          <p>
            <span className={styles.sub_title}>用户</span>
            <span className={styles.content_del}>
              ：是指经过互医网注册程序后，使用互医网各项服务的单位或个人。在本协议中以下更多称为“您”或者“用户”
            </span>
          </p>
          <p>
            <span className={styles.sub_title}>关联公司</span>
            <span className={styles.content_del}>
              ：上海忞竺科技公司直接或者间接控股的公司，参股或形成经营、协作的，具有关联关系的企业。
            </span>
          </p>
          <p className={styles.sub_title}>一、本协议的确认和接纳</p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  互医网平台的互联网电子服务或商品的所有权和运作权归互医网所有。您同意所有注册协议条款并
              完成注册程序，才能成为互医网平台的正式用户。您确认：本协议条款是处理双方权利义务的契约，始
              终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  您点击登录，即视为您确认自己具有享受互医网平台服务、下单购买等相应的权利能力和行为能力，能
              够独立承担法律责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  互医网保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除
              或编辑内容或取消订单的权利。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4.  互医网运用自己的操作系统通过国际互联网为您提供互联网电子服务或商品，并承担本协议和其它服务协议中
              对您的责任和义务。为使用本服务，您必须能够自行通过有法律资格的第三方对您提供互联网接入服务，并自行承
              担以下内容：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            1） 自行配备上网所需的设备，包括个人电脑，调制解调器及其他必要的设备装置。
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            2）  自行承担上网所需的相关必要费用，如：电话费用、网络费用等。
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            3）  本协议中规定的您的其他责任和义务。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5.  您应保证：提供详尽、真实、准确和完整的个人资料以符合实名认证的要求。如果
              资料发生变动，您应及时更改。若您提供任何错误、不实、过时或不完整的资料，并为互
              医网所确知，或者互医网有合理理由怀疑前述资料为错误、不实、过时或不完整的资料，
              互医网有权暂停或终止对您的帐号提供服务，并拒绝现在或将来申请使用本服务的全部或
              一部分的请求。在此情况下，您可通过互医网的申诉途径与互医网取得联系并修正个人资
              料经互医网核实后恢复账号使用。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              6.  本服务不会提供给被暂时中止或永久终止资格的互医网平台用户。
            </span>
          </p>
          <p className={styles.sub_title}>
            二、用户的权利和义务
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  您有权利拥有自己在互医网的用户名及密码，并有权利使用自己的用户名及密码随时
              登陆互医网服务。对互医网而言，您帐号和密码是唯一验证您真实性的依据，只要使用了正
              确的您帐号和密码无论是谁登录均视为已经得到您本人的授权。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  您的帐号和密码由您负责保管。您要对任何以您帐号进行的活动和事件负全责，且您
              有权随时根据指示更改您的密码。若发现任何非法使用您的帐号或存在安全漏洞的情况，请
              立即通知互医网。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  您不得以任何形式转让或授权他人使用自己的互医网账号，亦不得盗用他人帐号，由以
              上行为造成的后果由用户自行承担。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4.  您有权根据互医网管理规定发布、获取信息，进行医患互通交流等。但您的使用应符合
              网络道德，遵守中华人民共和国的相关法律法规。您的用户名和昵称中不能含有威胁、淫秽、
              谩骂、非法、侵害他人正当权益等有争议性的文字。您在互医网上的言论不得违法、不得违
              反公序良俗、不得使用攻击性语言恶意中伤他人，或作出虚假性陈述。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5.  您保证您在互医网上提供的信息的真实性、合法性和有效性。您单独承担在互医网上发
              布内容的一切相关责任。您使用或提供的服务应遵守所有适用于互医网的地方法律、国家法
              律和国际法律标准。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              6.  医疗纠纷的问题或其它有责任争议的问题在互医网发布，关于医疗纠纷的问题，请另
              行咨询律师或相关主管部门寻求援助，互医网有权不经您同意给予删除。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              7.  您同意您勾选知情同意选项或采纳医生建议即视为风险提示已告知并获得您的知情同意。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              8.  在接受互联网咨询服务时，用户应提供相关咨询者初诊数据，并保证所提供的初诊数据
              的完整性、真实性、合法性和准确性。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              9.  您的授权行为：用户同意授权互医网获取患者数据，并为患者服务的目的使用所有医疗
              数据，包括用户在其他实体及非实体医疗机构的数据。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              10.  根据相关法律法规规定，用户应理解互联网咨询不涉及面诊、触诊、闻诊、初诊，医
              生仅根据患者提供的信息和医疗数据提供咨询服务，用户应自行判断医生所给的咨询建议的
              准确性及合理性再对建议予以接受或拒绝。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              11.  为了保护用户创造的内容、维护良好的社区氛围，您确保在互医网平台的发言内容友
              好，无以下行为或类似以下行为：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （1） 违反法律法规：发布违反国家相关法律法规及「七条底线」、「九不准」管理规定的信息，
            主要表现为：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            1） 反对宪法所确定的基本原则
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            2） 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            3） 损害国家荣誉和利益
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            4） 煽动民族仇恨、民族歧视，破坏民族团结
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            5） 侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            6） 破坏国家宗教政策，宣扬邪教和封建迷信
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            7） 散布谣言，扰乱社会秩序，破坏社会稳定
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            8） 宣扬淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            9） 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            10） 诽谤他人，泄露他人隐私，侵害他人合法权益
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            11） 含有法律、行政法规禁止的其他内容的信息
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （2） 不友善行为：不尊重互医网用户及其所贡献内容的行为，主要表现为：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            1） 轻蔑：贬低、轻视他人及其劳动成果
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            2） 诽谤：捏造、散布虚假事实，损害他人名誉
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            3） 嘲讽：以比喻、夸张、侮辱性的手法对他人或其行为进行揭露或描述，以此来激怒他人
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            4） 挑衅：以不友好的方式激怒他人，意图使对方对自己的言论作出回应，蓄意制造事端
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            5） 羞辱：贬低他人的能力、行为、生理或身份特征，让对方难堪
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            6） 谩骂：以不文明的语言对他人进行负面评价
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            7） 歧视：针对他人的民族、种族、宗教、性取向、性别、年龄、地域、生理特征等身份或
            者归类的攻击
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            8） 威胁：许诺以不良的后果来迫使他人服从自己的意志
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （2） 发布垃圾广告信息：用户以推广曝光为目的，发布影响用户体验、扰乱互医网社区秩
            序的内容，或进行相关行为。
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              1） 多次发布包含售卖产品、提供服务、宣传推广内容的垃圾广告。包括但不限于以下几
              种形式：；单个帐号多次发布包含垃圾广告的内容；多个广告帐号互相配合发布包含垃圾
              广告的内容；多次发布包含欺骗性外链的内容，如未注明的购物链接、跳转网站等，诱骗
              用户点击链接；发布大量包含 SEO 推广链接、产品、品牌等内容获取搜索引擎中的不正
              当曝光
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              2） 购买或出售帐号之间虚假地互动，发布干扰社区秩序的推广内容及相关交易。包括
              但不限于以下几种形式：购买机器注册帐号，或人工操控帐号的关注，伪造在社区内的影
              响力，谋求回答的不正当曝光；使用机器、恶意程序手段或人为有组织性地操控帐号进行
              点赞、回答等扰乱秩序的行为
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              3） 使用严重影响用户体验的违规手段进行恶意营销。包括但不限于以下几种形式：不
              规范转载或大篇幅转载他人内容同时加入推广营销内容；发布包含欺骗性的恶意营销内容，
              如通过伪造经历、冒充他人等方式进行恶意营销
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              4） 使用特殊符号、图片等方式规避垃圾广告内容审核的广告内容
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              5） 恶意注册使用互医网帐号，包括但不限于机器注册、频繁注册、批量注册、滥用多个
              互医网帐号
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              （4） 恶意行为：滥用产品功能，进行影响用户体验，危及平台安全及损害他人权益的行为。主要表现为：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              1） 恶意编辑，指清空或删除有效内容，添加无关信息，破坏内容结构等降低公共编辑内容质量的编辑
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              2） 冒充他人，通过头像、用户名等个人信息暗示自己与他人或机构相等同或有关联
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              3） 重复发布干扰正常用户体验的内容。包括但不限于以下几种形式：重复的回答内容多次发布在不同问
              题下；频繁发布难以辨识涵义影响阅读体验的字符、数字等无意义乱码；骚扰他人，以评论、@ 他人、私
              信等方式对他人反复发送重复或者相似的诉求；诱导投票或关注，如通过点赞抽奖、集赞更新等形式诱导
              投票或者诱导关注
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              4） 制作及传播外挂或者用于操作帐号功能的恶意程序或相关教程
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              5） 发布含有潜在危险的内容，如钓鱼网站、木马、病毒网站等
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （5） 色情低俗，主要表现为：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              1） 包含自己或他人性经验的细节描述或露骨的感受描述
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              2） 涉及色情段子、两性笑话的低俗内容
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              3） 配图、头图中包含庸俗或挑逗性图片的内容
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （6） 存在不实信息，主要表现为：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              1） 互医网帐号存在伪造身份、冒充他人等行为
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              2） 可能存在事实性错误或者造谣等内容
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
              3） 内容中存在事实夸大、伪造虚假经历并存在误导他人
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （7） 发布封建迷信内容，比如找人算命、测字、占卜、解梦、化解厄运、使用迷信方式治病；
            宣扬鼓励封建迷信等活动，推广算命等理论知识学习及讨论：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （8）   文章标题党，主要表现为：存在各种夸张、猎奇、不合常理的内容表现手法等行为来诱
            导用户；内容与标题之间存在严重不实或者原意扭曲
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              12.  如您违反上述任一规则，互医网有权不经您同意给予删除；若情节严重导致相应法律后
              果的发生，您将以自己的名义独立承担所有法律责任，并确保互医网经营者及其关联公司免于因
              此产生任何损失。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              13.  平台通过主动发现和接受用户举报两种方式发现违规行为。您发现其他用户有违法或违反
              互医网管理规定的行为，可以点击“举报”按钮，随时向互医网进行反映要求处理。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              14.  若您的行为不符合本协议的规定，互医网有权做出独立判断，并立即停止向该您帐号提供
              服务。您需对自己在网上的行为承担法律责任。您若在互医网上散布和传播反动、色情或其他违
              反国家法律的信息，互医网有权不经您同意给予删除相关记录，并按照规则的规定对您予以处罚；
              情节严重的，互医网将有可能收集您的系统记录并作为您违反法律的证据进行公示。
            </span>
          </p>
          <p className={styles.sub_title}>
            三、互医网的权利和义务
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  互医网将协助医患之间进行合法的交流，并提供必要的网络技术帮助；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  互医网有义务在现有技术上维护整个网络平台的正常运行，并努力提升和改进技
              术，使您与医生的网上交流得以顺利进行；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  互医网作为医患互通服务的平台，不对您发布的信息的来源和正确性负责，不参
              与医患交流，不对医患交流的结果承担任何责任；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4.  对于您在互医网上的不当行为或其它任何互医网认为应当终止服务的情况，互医
              网有权随时作出删除相关信息、终止服务提供等处理，而无需征得您的同意；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5.  互医网没有义务对所有用户的注册数据、所有的活动行为以及与之有关的其它事项
              进行审查，但如存在下列情况，互医网有权根据不同情况选择保留或删除相关信息或继
              续、停止对该用户提供服务，并追究相关法律责任：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （1） 您或其它第三方通知互医网，认为某个具体用户、具体行为、具体事项可能存在重大问题；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （2） 您或其它第三方向互医网告知网络平台上有违法或不当行为的，互医网以普通非专业医疗
            人员的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为具有违法或不当性质的。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              6.  用户在互医网上如与其它用户产生纠纷，请求互医网从中予以调处，经互医网审核后，互
              医网有权向纠纷双方了解情况，并将所了解的情况互相通知对方；互医网所作出的调处行为不
              具有法律效力，调处结果系由纠纷双方自愿作出，互医网仅协助提供信息的沟通，不对调处结
              果承担相应法律责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              7.  用户因互医网展示的内容与其他用户发生纠纷的，司法部门可以要求互医网根据法律程序
              提供该案件所需证据材料。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              8.  互医网有权对用户的注册数据及活动行为进行查阅，发现注册数据或活动行为中存在任何
              问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除等处理；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              9.  经国家生效法律文书或行政处罚决定确认用户存在违法行为，或者互医网有足够事实依据
              可以认定用户存在违法或违反互医网管理规定的行为的，互医网有权以合理方式公布用户的违
              法行为；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              10.  因不可抗力（如火灾、水灾、暴动、骚乱、战争、自然灾害等）导致互医网的服务中断或
              者用户数据损坏、丢失等，互医网不承担任何责任；
            </span>
          </p>
          <p className={styles.sub_title}>
            四、服务变更、中断和终止条款
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  鉴于移动互联网服务的特殊性，互医网有权随时变更、中止或终止部分或全部的服务。如变更、
              中止或终止的服务属于免费服务，互医网无需通知您，也无需对您或任何第三方承担任何责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  因系统维护或者升级，需要定期或不定期地对提供本服务的平台或相关的设备进行检修或者维护，
              如因此类情况而造成本服务在合理时间内的中断，互医网将尽可能事先通知，但无需为此承担任何责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  如果您在服务变更、中断、终止前，在互医网平台上违反规定的行为，互医网仍可行使本条款所规定的权利。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4.  如发生下列任何一种情形，互医网有权随时中断或终止向您提供本协议项下的服务而无需对您或任
              何第三方承担任何责任：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （1） 您违反国家有关法律法规或互医网平台规定，侵害他人合法权益的；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （2） 您提供的个人资料不真实；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （3） 您盗用他人账号、发布违禁信息、造成他人财务损失的；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （4） 您违反本协议中的规定；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （5） 您违反互医网发布的政策；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （6） 其他 互医网认为不适宜的地方。
            </span>
          </p>
          <p className={styles.sub_title}>
            五、隐私保护
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  我们将极力尊重用户个人隐私信息的私有性，互医网将按法律法规保护您的个人隐私。除
              法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，互医网将不会对外公
              开或向第三方透露您的个人隐私信息。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.   为了运营和改善互医网的技术与服务，互医网将可能会自行收集使用或向第三方提供用户
              的非个人隐私信息，这将有助于互医网向用户提供更好的用户体验和服务质量。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.   您使用或继续使用我们的服务，即意味着同意我们按照《互医网隐私保护政策》收集、使用、
              储存和分享您的相关信息。具体请参见《互医网隐私保护政策》。
            </span>
          </p>
          <p className={styles.sub_title}>
            六、收费声明
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  互医网平台的相关服务中，有以收费方式提供的。如您使用收费服务，请遵守相关的协议。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  在支付费用时，请您仔细阅读收费服务的相关协议和服务须知。除约定的情况外，否则，
              费用不予退回。退费的方式视支付方式的不同而不同。用户应严格依据退费方式及退费周期
              收回退款。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  互医网可能根据实际需要对收费服务的收费标准、方式进行修改和变更，互医网也可能
              会对部分免费服务开始收费。前述修改、变更或开始收费前，互医网将在相应服务页面进行
              通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
            </span>
          </p>
          <p className={styles.sub_title}>
            七、免责声明：
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  您有权选择是否遵从医师作出的咨询意见。如果您在未与互医网开展合作的医疗机构获
              取药品、接受治疗的，即便您依据的处方或治疗方案来源于互医网和/或在线医生，互医网和/
              或医生亦不承担相应责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  互医网不承诺网页上设置的外部链接的准确性和完整性，同时，对于该等外部链接指向
              的不由互医网实际控制的任何网页上的内容，互医网不承担任何责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  对于因不可抗力或互医网不能控制的原因造成的网络服务中断或其它缺陷，互医网不承
              担任何责任，但将尽力减少因此而给您造成的损失和影响。
            </span>
          </p>
          <p className={styles.sub_title}>
            八、违约赔偿：
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  您同意保障和维护互医网及其他用户的利益，如因您违反有关法律、法规或互医网管理
              规定而给互医网或任何其他第三人造成损失，您同意承担由此造成的损害赔偿责任。
            </span>
          </p>
          <p className={styles.sub_title}>
            九、第三方责任
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  因服务必要，互医网可能会将相关网站链接至互医网开展合作的伙伴的网站（统称“第三方网站”）。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  如果您使用互医网提供的链接访问第三方网站，这些网站的运营商可能会收集您的个人信息。互
              医网尽力确保所有链接的第三方网站采用同等的个人信息保护措施，但是互医网不对这些第三方网站
              上的活动、隐私权政策或隐私保护水平承担任何法律或其他责任。
            </span>
          </p>
          <p className={styles.sub_title}>
            十、知识产权条款
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  您一旦接受本协议，即表明您主动将其在任何时间段在互医网平台发表的任何形式的信息内容
              （包括但不限于用户评价、用户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，
              如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信
              息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全
              部独家且不可撤销地转让给互医网所有，您同意互医网有权就任何主体侵权而单独提起诉讼。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）
              及相关法律规定的著作财产权等权利转让书面协议，其效力及于您在互医网平台上发布的任何受著作权
              法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  您同意并已充分了解本协议的条款，承诺不将已发表于互医网平台的信息，以任何形式发布或授
              权其它主体以任何方式使用（包括但限于在各类网站、媒体上使用）。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4.  互医网是互医网平台的制作者，拥有互医网平台内容及资源的著作权等合法权利，受国家法律保护，
              有权不时地对本协议及互医网平台的内容进行修改，并在互医网平台张贴，无须另行通知您。在法律允
              许的最大限度范围内，互医网对本协议及互医网平台的内容拥有解释权。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5.  除法律另有强制性规定外，未经互医网明确的特别书面许可，任何单位或个人不得以任何方式非法
              地全部或部分复制、转载、引用、链接、抓取或以其他方式使用互医网平台的信息内容，否则，互医网
              有权追究其法律责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              6.  互医网平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字
              下载、数据编辑和软件），均是互医网或其内容提供者的财产，受中国和国际版权法的保护。互医网
              平台上所有内容的汇编是互医网的排他财产，受中国和国际版权法的保护。互医网平台上的所有软件
              都是互医网或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。
            </span>
          </p>
          <p className={styles.sub_title}>
            十一、互医网拒绝提供担保
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  您对移动网络服务的使用承担风险，并承担因为使用移动网络服务而产生的一切后果。互医网对
              此不作任何类型的担保，不论是明确的或隐含的，但是不对商业性的隐含担保、特定目的和不违反规
              定的适当担保作限制。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  互医网不担保本服务一定能完全满足您的要求，也不担保本服务不会受网络、通信等原因而中断，
              对服务的及时性、安全性、错误程序的发生都不作担保。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  互医网因受合作医疗机构等资源限制，就您所提的请求不担保服务一定能够成功。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4.  互医网对在互医网平台上得到的任何有关健康的咨询意见等，不作担保。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5.  您不能从互医网得到口头或书面的意见或信息（增加除外条款，而并非全部），
              互医网也不会在此做明确担保。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              6.  除非另有明确的书面说明，互医网平台及其所包含的或以其它方式通过互医网平
              台提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和
              “按现有”的基础上提供的。除非另有明确的书面说明，互医网不对互医网平台的运营
              及其包含在互医网平台上的信息、内容、材料、产品（包括软件）或服务作任何形式
              的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              7.  互医网不担保互医网平台包含的或以其它方式通过互医网平台提供给您的全部信
              息、内容、材料、产品（包括软件）和服务、其服务器或从互医网平台发出的电子信
              件、信息没有病毒或其他有害成分。如因不可抗力或其它互医网平台无法控制的原因
              使互医网平台系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记
              录等，互医网会合理地尽力协助处理善后事宜。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              8.  互医网平台所承载的内容（文、图、视频、音频），不对其真实性、科学性、严
              肃性做任何形式保证。
            </span>
          </p>
          <p className={styles.sub_title}>
            十二、责任限制
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  互医网平台服务是按照现有技术和条件所能达到的水平提供的服务。互医网会尽最大
              努力为您提供服务，确保服务的连贯性和安全性；但互医网不能随时预见和防范法律、技术
              以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务
              瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。互医网所不
              能控制的事件而影响互医网提供服务，互医网无须承担任何责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  互医网对您使用网络服务而受到的任何直接、间接、偶然、特殊及继起的损害（互医
              网违反法律、法规和本协议的条款除外）不负责任，这些损害可能来自：不正当使用网络
              服务，私自在网上进行交易，非法使用网络服务或您传送的信息有所变动。这些行为都有
              可能会导致互医网的形象受损，所以互医网事先提出这种损害的可能性。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  互医网所有信息仅供参考，不保证信息的绝对正确性和完整性，且不对因信息的不正
              确或遗漏导致的任何损失或损害承担责任。互医网所提供的任何健康资讯，仅供参考，不
              能替代医生和其他医务人员的建议，如自行使用互医网资料发生偏差，互医网不承担任何
              法律责任。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4.  因医生的咨询行为产生的医疗责任由医生承担，国家法律法规政策对此有规定的，以
              规定为准。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5.  互医网拒绝回复的问题，包括但不限于如下情况： 
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （1） 与个人医疗健康无关或者相关性较小的问题；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （2） 提问或者要求的回答可能违反相关法律法规的；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （3） 侵犯医生个人隐私及平台利益的；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （4） 未按提问要求提问，如提问时未指定医生，却要求具体医生回复的；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （5） 未按提问要求提问，如提问时未指定医生，却要求具体医生回复的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              6.  互医网平台所提供的任何各项电子信息，症状自查等服务仅供参考，如自行使用
              互医网平台中资料发生偏差，互医网概不负责，亦不负任何法律责任。 
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              7.  互医网保留对本声明作出不定时修改的权利。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              8.  互医网不对您所发布信息的删除或储存失败负责。互医网积极采用数据备份加密等
              措施保障您数据的安全，但不对由于因意外因素导致的数据损失和泄漏负责。互医网有
              权审查和监督您的行为是否符合本协议的要求，如果您违背了本协议的约定，互医网有
              权中断您的服务。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              9.  您同意因下列情形之一的，互医网不承担赔偿责任：
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （1） 患者或者其近亲属不配合进行符合咨询规范的咨询；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （2） 医务人员在紧急情况下已经尽到合理咨询义务；
            </span>
          </p>
          <p className={styles.sub_sub_content}>
            <span className={styles.content_del}>
            （3） 限于当时的医疗水平难以咨询。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              10.  如果您或第三方就相关服务购买了保险，因发生保险事故导致损失的，保险
              公司已经向您或第三方承担或许诺承担保险理赔责任后，互医网在此范围内不再承
              担赔偿责任。
            </span>
          </p>
          <p className={styles.sub_title}>
            十三、广告说明
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  互医网平台上为您的便利而提供的外部链接，包括但不限于任何广告内容链
              接，以及该链接所指向网页之所有内容，均系该网页所属第三方的所有者制作和提
              供（以下简称“第三方网页”）。第三方网页并非也不反映互医网之任何意见和主张，
              也不表示互医网同意或支持该第三方网页上的任何内容、主张或立场。互医网对第
              三方网页中内容之合法性、准确性、真实性、适用性、安全性和完整性等概不承担
              任何责任。任何单位或个人如需要第三方网页中内容（包括资讯、资料、消息、产
              品或服务介绍、报价等），并欲据此进行交易或其他行为前，应慎重辨别这些内容的
              合法性、准确性、真实性、适用性、完整性和安全性（包括下载第三方网页中内容
              是否会感染电脑病毒），并采取谨慎的预防措施。如您不确定这些内容是否合法、准
              确、真实、实用、完整和安全，建议您先咨询专业人士。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  任何单位或者个人因相信、使用第三方网页中信息、服务、产品等内容，或据此
              进行交易等行为，而引致的人身伤亡、财产毁损（包括因下载而感染电脑病毒）、名誉
              或商誉诽谤、版权或知识产权等权利的侵犯等事件，及因该等事件所造成的损害后果，
              互医网概不承担任何法律责任。无论何种原因，互医网不对任何非与互医网直接发生的
              交易和行为承担任何直接、间接、附带或衍生的损失和责任。
            </span>
          </p>
          <p className={styles.sub_title}>
            十四、协议修改
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  根据国家法律法规变化及网络运营需要，互医网有权对本协议条款不时地进行修
              改，修改后的协议一旦被张贴在互医网平台上即生效，并代替原来的协议，互医网将
              通过适当的方式向您通知变更信息。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  如您不同意更新后的协议，有权停止使用网络服务；如您继续使用互医网平台提
              供的服务，即视为同意接受更新后的协议。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可
              分的且并不影响任何其余条款的有效性和可执行性。当发生有关争议时，以最新的服
              务协议为准。
            </span>
          </p>
          <p className={styles.sub_title}>
            十五、通告送达
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  本协议项下所有的通知均可通过重要页面公告、电子邮件、手机短信或常规的信件
              传送等方式进行；该等通知于发送之日视为已送达。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  您对于互医网的通知应当通过互医网平台对外正式公布的通信地址、电子邮件地址等
              联系信息进行邮寄书面送达。
            </span>
          </p>
          <p className={styles.sub_title}>
            十六、法律管辖和适用
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  本协议签订于中华人民共和国上海市黄浦区。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  本协议之效力、解释、执行和争议的解决均适用中华人民共和国大陆地区适用之有效法
              律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按
              法律规定重新解释，而其它有效条款继续有效。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  如缔约方就本协议服务条款内容或其执行发生任何争议，双方应尽力友好协商解决；协
              商不成时，任何一方可提交本协议签订地有管辖权的人民法院诉讼裁决。
            </span>
          </p>
          <p className={styles.sub_title}>
            十七、其他
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1.  互医网尊重您的合法权利，本协议及互医网平台上发布的各类规则、声明等其他内容，均
              是为了更好地、更加便利地为您提供服务。互医网平台欢迎您和社会各界提出意见和建议，互
              医网将虚心接受并适时修改本协议及互医网平台的各类规则。 
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2.  您注册使用互医网平台即视为您完全接受本协议，在点击“登录”之前请您再次确认已知悉
              并完全理解本协议的全部内容。 
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3.  本服务条款中的标题仅为方便而设，在解释本条款时应被忽略。
            </span>
          </p>

          <p className={styles.sub_title}></p>
          <p className={styles.content_del}></p>
          <p className={styles.sub_content}></p>
          <span className={styles.content_del}>
            
          </span>
        </div>
        <div className={styles.btn_wrap}>
          <button className={styles.confirm_btn} onClick={() => props.onCloseModal()}>确定</button>
        </div>
      </div>
    </Modal>
  )


}

export default ProtocolModal