import React, {useEffect}  from 'react'
import './FourthFrame.css'
import { Images, Tools } from '../../../utils'

export default function FourthFrame(props) {

  return (
    <div className='five-contain'>
        <img className='five-img' alt="" src={Images.home.fourthGif}/>
        <div className="fourth-info">
          <div className="five-title">
            慢病管理
          </div>
          <div className="five-tip">
            获得医生专业定制化治疗方案，建立<br/>
            专属健康档案，全程为您保驾护航
          </div>
        </div>
    </div>
  );
}