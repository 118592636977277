import React, {useState,useEffect,useRef}  from 'react'
import  * as styles from './About.module.css'
import { Images, Tools} from '../../utils';
import { Footer } from '../../components';

function About() {
  return(
    <div className={styles.container}>
      <img className={styles.about_top} alt="" src={Images.home.aboutTop}/>
      <div className={styles.about_view}>
        <div className={styles.title}>公司介绍</div>
        <div className={styles.info_wrap}>
          <div className={styles.info_text}>
            <div>
              互医网是国内专业的就医经验分享与指导平台，于2019年成立。互医网积极响应“健康中国”
              国家战略，致力于促进“科技+医疗健康”发展，以慢病管理为切入口，汇集优质医疗资源，
              多层次渗透用户场景，全方位满足用户的健康管理需求。
            </div>
            <div className={styles.m_t_60}>
              目前，互医网已惠及超数万用户，并且正在加速构建就医分享、慢病管理、在线就医服务三位
              一体的医疗健康生态圈。我们的目标是让每个人过上健康的生活。为此，互医网正全力推广健
              康管理理念，用技术创新和场景创新的方式提供服务和支持，使更多人受益，并积极推进互联网医疗的创新实践，通过统筹线上和线下医疗资源，为用户打造无界医疗体验。
            </div>
          </div>
          <img className={styles.company} alt="" src={Images.home.company}/>
          
        </div>
        <div className={[styles.title, styles.m_t_150].join(' ')}>联系方式</div>
        <div className={styles.connect}>
          <div className={styles.connect_info}>
            <img className={styles.connect_icon} alt="" src={Images.home.comTel} />
            <div className={styles.connect_title}>公司电话</div>
            <div className={styles.connect_text}>021-63233968</div>
          </div>
          <div className={styles.connect_info}>
            <img className={styles.connect_icon} alt="" src={Images.home.cusTel} />
            <div className={styles.connect_title}>客服电话</div>
            <div className={styles.connect_text}>400-619-6023</div>
          </div>
          <div className={styles.connect_info}>
            <img className={styles.connect_icon} alt="" src={Images.home.comMail} />
            <div className={styles.connect_title}>公司邮箱</div>
            <div className={styles.connect_text}>jz_business@jinzhu.tech</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
      
    
  )
}

export default About
