import React, { useState, useEffect }  from 'react';
import './FirstFrame.css'
import { Images, Tools } from '../../../utils'

export default function FirstFrame() {
  const [iosShow ,iosOver]  = useState("none")
  const [iosColor,iosColorChange]  = useState("#40A6FF")

  const [androidShow ,androidOver] = useState("none")
  const [androidColor ,androidColorChange] = useState("#59CDFF")


  return (
    <div className='first-contain'>
      <img src={Images.home.firstGif} alt='' className='first-img' />
      <div className='first-title-wrap'> 
        <div className='first-title'>连接医护与患者的桥梁</div>
        <div className='first-sub'>A  bridge  connecting  doctors  and  patients</div>
      </div>
    
      <div className='first-download'>
        <div className='download-item'>
          <button 
            className='download-btn m-r-74' 
            style={{'background': iosColor}}
            onMouseOver={()=>{
              iosOver('flex')
              iosColorChange('rgb(15,143,255)')
            }} 
            onMouseLeave={()=>{
              iosOver('none')
              iosColorChange('#40A6FF')
            }}
            onClick={() => {
              window.open('https://apps.apple.com/cn/app/id1516210858',"_blank")
            }}
          >
            iOS版下载
          </button>
          <div style={{"display": iosShow}} className='d-icon'>
            <img src={Images.home.iosDown} alt='' />
            <div className="triangle"/>
          </div>
        </div>
        <div className='download-item'>
          < button 
            className='download-btn' 
            style={{'background': androidColor}}
            onMouseOver={()=>{
              androidOver('flex')
              androidColorChange('rgb(15,143,255)')
            }} 
            onMouseLeave={()=>{
              androidOver('none')
              androidColorChange('#59CDFF')
            }}
            onClick={() => {
              window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.hyw_app',"_blank")
            }}
          >
            安卓版下载
          </button>
          <div style={{"display": androidShow}} className='d-icon'>
            <img src={Images.home.androidDown} alt=''/>
            <div className="triangle"/>
          </div>
        </div>
      </div>
    </div>
  );
}