import React from 'react'
import {HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import HomePage from '../pages/Home/HomePage'
import AccountSetting from '../pages/PersonalCenter/AccountSetting/Index'
import {MenuItem} from '../components'
import Album from '../pages/Album/Index'
import NotePage from "../pages/Note/NotePage";
import MyNews from '../pages/PersonalCenter/MyNews/Index'
import NoteList from '@/pages/List/NoteList'
import NoteDetail from '@/pages/List/NoteDetail'
import Eval from '@/pages/Eval/Index'
import About from '../pages/About/About';
import Service from '../pages/Service/Service';
import Investment from '../pages/Investment/Investment';
import License from '../pages/License/License';
import Certificate from '../pages/Certificate/Certificate';
import ScrollToTop from './ScrollToTop';
import BsLicense from '../pages/BsLicense/BsLicense'

const BasicRoute = () => (
    <Router>
        <ScrollToTop />
        <MenuItem/>
        <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path='/home' component={HomePage} />
            <Route exact path='/noteList' component={NoteList} />
            <Route path='/noteList/:noteId' component={NoteDetail} />
            <Route path='/setting' component={AccountSetting} />
            <Route path='/album' component={Album} />
            <Route path='/note' component={NotePage} />
            <Route path='/mynews' component={MyNews} />
            <Route path='/eval' component={Eval} />
            <Route path="/empty" component={null} key="empty" />
            <Route path="/about" component={About}/>
            <Route path="/service" component={Service}/>
            <Route path="/investment" component={Investment}/>
            <Route path="/license" component={License}/>
            <Route path="/certificate" component={Certificate}/>
            <Route path="/bslicense" component={BsLicense}/>
        </Switch>

    </Router>
)

export default BasicRoute
