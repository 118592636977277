import Axios from './Axios'
import {HywApi} from './HywApi'

class album {
  
  getAlbum = (page,per_page) => {
    return new Promise((resolve, reject) => {
      Axios.get(HywApi.atlas + '?page=' + page +'&per_page=' + per_page)
          .then((res) => {
              console.log(res, HywApi.atlas, 'atlas');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.atlas, 'err----');
              reject(err)
          })
    })
  }
  deletePhoto = (atlas_id) => {
    let query = {
      atlas_id: atlas_id
    }
    return new Promise((resolve, reject) => {
      Axios.delete(HywApi.atlas, query)
          .then((res) => {
              console.log(res, HywApi.atlas, 'atlas_id');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.atlas, 'err----');
              reject(err)
          })
    })
  }
  getImgSize = () => {
    return new Promise((resolve, reject) => {
      Axios.get(HywApi.getImgSize)
          .then((res) => {
              console.log(res, HywApi.getImgSize, 'getImgSize');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.getImgSize, 'err----');
              reject(err)
          })
    })
  }
  
}

export const AlbumApi = new album()