import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';

class InputView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hosValue: ''
        }
    }

    inputRef = createRef();

    isOnComposition = false;

    componentDidMount() {
        this.setInputValue();
    }

    componentDidUpdate() {
        // console.log(this.inputRef?.current?.value, 'this.inputRef.current.value')
        this.setInputValue();
    }

    setInputValue = () => {
        this.inputRef.current.value = this.props.value || ''

    };

    handleComposition = evt => {
        // console.log(this.props, evt.type, 'this.props----')
        if (evt.type === 'compositionend') {
            this.isOnComposition = false;

            // 谷歌浏览器：compositionstart onChange compositionend
            // 火狐浏览器：compositionstart compositionend onChange
            if (navigator.userAgent.indexOf('Chrome') > -1) {
                this.onChange(evt);
            }

            return;
        }

        this.isOnComposition = true;
    };

    onChange = evt => {
        this.setState({
            hosValue: evt.target.value
        })
        if (!this.isOnComposition) {
            this.props.onChange(evt);
        }
    };
    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.isOnComposition) {
            if (this.inputRef?.current) {
                this.inputRef.current.value = nextProps.value
                this.setState({
                    hosValue: nextProps.value
                })
            }

        }
    }

    render() {
        let {onChange, value, ...params} = this.props

        const commonProps = {
            onChange: this.onChange,
            onCompositionStart: this.handleComposition,
            onCompositionUpdate: this.handleComposition,
            onCompositionEnd: this.handleComposition,
        };
        return <Input
            ref={this.inputRef}
            type="text"
            {...params}
            value={this.state.hosValue}
            {...commonProps}

        />
    }
}

InputView.propTypes = {
    onChange: PropTypes.func
};

InputView.defaultProps = {
    onChange: () => {}
};

export default InputView;
