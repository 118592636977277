import React from 'react';
import './HomePage.css';
import FirstFrame from './FirstFrame/FirstFrame';
import SecondFrame from './SecondFrame/SecondFrame';
import ThirdFrame from './ThirdFrame/ThirdFrame';
import FourthFrame from './FourthFrame/FourthFrame'
import SixthFrame from './SixthFrame/SixthFrame';
import FifthFrame from './FifthFrame/FifthFrame';
import {
  ProtocolModal,
  PrivacyModal,
  BindModal,
} from '../../components'

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
      
      protocolModal: false,
      privacyModal: false,
      bindModal: false,
    };
  }

  render() {

    return (
      <div className='home-wrap'>
        <FirstFrame />
        <SecondFrame />
        <ThirdFrame  /> 
        <FourthFrame />
        <FifthFrame />
        <SixthFrame/>
        <ProtocolModal
          isOpen={this.state.protocolModal}
          onCloseModal={() => {
            this.setState({
              protocolModal: false,
            })
          }}
        />
        <PrivacyModal
          isOpen={this.state.privacyModal}
          onCloseModal={() => {
            this.setState({
              privacyModal: false,
            })
          }}
        />
        <BindModal
          isOpen={this.state.bindModal}
          onCloseModal={() => {
            this.setState({
              bindModal: false,
            })
          }}
        />
      </div>
    );
  }
}


export default HomePage
