import React from 'react'
import { Spin } from 'antd';
import './Loading.css'

function Loading(props) {
    return (
        <div className="content">
            <Spin />
        </div>
    )
}

export default Loading