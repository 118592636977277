const host = process.env.REACT_APP_HOST

// 《互联网药品信息服务资格证书》
const JZCertificate = "https://qiniu.hywapp.com/hyw/certification/fc1d3563-7f91-4c88-89a3-f463dd8ffb7d.jpg"
// 《增值电信业务经营许可证》
const JZLicense = "https://qiniu.hywapp.com/hyw/certification/41752d5a-a370-11ed-8e4e-acde48001122.jpg"
// 《上海锦竺营业执照》
const JZBsLicense = "https://qiniu.hywapp.com/hyw/certification/646bdd6a-4d55-11ee-8759-acde48001122.jpg"

//隐私政策
const ConsultAgreement = (host == 'production') ?'https://qiniu.hywapp.com/doc/pc/hyw_statement_privacy_policy.html':'https://qiniu.hywapp.com/staging/doc/pc/hyw_statement_privacy_policy.html'
//用户协议
const UserAgreement = (host == 'production') ? 'https://qiniu.hywapp.com/doc/pc/hyw_service_policy_patient.html' : 'https://qiniu.hywapp.com/staging/doc/pc/hyw_service_policy_patient.html'

export {
  JZLicense,
  JZCertificate,
  JZBsLicense,
  ConsultAgreement,
  UserAgreement,
}