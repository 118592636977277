import { createStore , applyMiddleware , compose} from 'redux';
import thunk from 'redux-thunk';
import reducers from './rootReducer';
import {persistStore, persistCombineReducers} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleware = [ thunk ];
const config = {
    key: 'primary',
    storage,
}

let reducer = persistCombineReducers(config, reducers);

// export default () => {
//     let store = createStore(reducer)
//     let persistor = persistStore(store)
//     return { store, persistor }
// }
export const configureStore = () => {
    const store = createStore(
        reducer,
        compose(
            applyMiddleware(thunk),
            composeWithDevTools(
                applyMiddleware(...middleware)
            )
        )
    );
    const persistor = persistStore(store);

    return { persistor, store };
};
