import React,{useState,useEffect} from 'react';
import { message } from 'antd';
import {Tools} from '../../../utils'
import {LoginApi} from '../../../api'
import { UserAgreement, ConsultAgreement } from '../../../utils/Config'

function WechatFirstLogin(props)  {

  const [tel, onTelChange] = useState('');
  const [verCode, onVerCodeChange] = useState('');
  const [waitTime, changeWaitTime] = useState(60000);
  const [sendClick, changeSendClick] = useState(false);
  const [agreeable, setAgreeable] = useState(false);
  
  useEffect(()=>{
    let interval
    // 开始倒计时
    if (waitTime && sendClick) {
        interval = setInterval(() => {
            changeWaitTime(preSecond => {
                if (preSecond > 1000) {
                    return preSecond - 1000
                } else {
                    clearInterval(interval)
                    changeSendClick(false);
                    return 60000
                }
            })
        }, 1000)
    }
    return () => clearInterval(interval)
  },[waitTime]);

  const getMsgCode = () => {
    getTimer(() => getVifiCode())
  };

  // 获取验证
  const getVifiCode = async () => {
      try {
          let res = await LoginApi.getMsgCode(tel)
          console.log(res)
      }catch (e) {
          console.log(e,'error')
      }
  }

  const getTimer = (callback) => {
    if (!Tools.verifyTel(tel)) {
      return message.error('请输入正确手机号码')
    }

    changeSendClick(true);
    callback  && callback();
    changeWaitTime(waitTime - 1000);
  };

  

  //校验手机号
  const onTelTextChange = (val) => {
    onTelChange(val);
  };
  

  const onLoginClick = async() => {
    if(!Tools.verifyTel(tel)) {
      return message.error('请输入正确手机号码')
    }
    if(!verCode){
      return message.error('请输入验证码')
    }
    if(!agreeable) {
      return message.error('请阅读并勾选下方协议')
    }
    try {
      const res = await LoginApi.accountLogin(tel,verCode,props.wechatData)
      if(res && res.success) {
        localStorage.setItem("access_token", res.data.access_token)
        localStorage.setItem("userInfo", JSON.stringify(res.data.user))
        localStorage.setItem("isReal", res.data.user.identity_authentication)
        props.onCloseModal()
        props.linkClick()
      }
    }catch(e) {
      console.log(e, "err")
    }
  }

  const onAgreeClick = () => {
    setAgreeable(!agreeable)
  }
  const onProtocol = () => {
    window.open(UserAgreement);
  }
  const onPrivacy = () => {
    window.open(ConsultAgreement);
  }

  return (
      <div>
          <div>
            <div className='logo-wrap'>
              <div className='title-wrap'>
                <span className='modal-title'>请绑定手机号码</span>
              </div>
            </div>
            <div className='input-wrap'>
              <input 
                className='num-input' 
                placeholder='请输入手机号'
                max={11}
                type='number'
                value={tel}
                onChange={(e) => onTelTextChange(e.target.value)}
              />
            </div>
            <div className='input-wrap-bottom'>
              <input 
                className='code-input' 
                placeholder='请输入短信验证码'
                type='text'
                value={verCode}
                maxLength={6}
                onChange={(e) => {
                  onVerCodeChange(e.target.value)
                }}
              />
              <div className='line-icon' />
              <button 
                disabled={sendClick}
                className='code-btn'
                onClick={() => getMsgCode()}
              >
                {sendClick ? `${waitTime/1000}s后重发` : '获取验证码'}
              </button>
            </div>
            <div className='agree-wrap'>
              <div className={["agree-icon", agreeable ? "agree-enabled" : null].join(' ')} onClick={onAgreeClick}></div>
              <div className='agree-text'>已阅读并同意</div>
              <div className="agree-link" onClick={onProtocol}>
                《用户协议》
              </div>
              <div className="agree-link">、</div>
              <div className="agree-link" onClick={onPrivacy}>
                《隐私协议》
              </div>
            </div>
            <button 
              className='submit-button'
              onClick={() => onLoginClick()}>
              绑定
            </button>
          </div>
      </div>
  )
}

export default WechatFirstLogin