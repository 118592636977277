import React, {useState,useEffect,useRef}  from 'react'
import  * as styles from './BsLicense.module.css'
// import { Images } from '../../utils/Images';
import { JZBsLicense } from '../../utils/Config'

function BsLicense() {

  return(
    <div className={styles.container}>
      <img className={styles.license_img} alt='' src={JZBsLicense} />
    </div>
  )
}

export default BsLicense
