import React, {useEffect}  from 'react'
import './SixthFrame.css'
import { Images, Tools } from '../../../utils'
import { Footer } from '../../../components'

export default function SixthFrame(props) {
  return (
    <div className='six-contain'>
      <div className='contain-wrap'>
        <span className='download-title'>扫码下载APP</span>
        <div className='download-wrap'>
          <div className='download-item'>
            <span className='download-text'>iOS版</span>
            <img src={Images.home.iosDown} alt='' className='download-icon' />
          </div>
          <div className='download-item'>
            <span className='download-text'>安卓版</span>
            <img src={Images.home.androidDown} alt='' className='download-icon' />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}