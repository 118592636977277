import React from 'react'
import  * as styles from './HospitalCard.module.css'
import {Images, Tools} from '@/utils'

function HospitalCard(props) {
    const { detail } = props
    return (
        <div className={styles.cardContentContainner}>
        <div className={styles.cardContent}>
            <img className={styles.cardImage} src={detail.hospital.thumbnail || Images.note.hospitalDefault} alt=""/>
            <div className={styles.cardInfo}>
                <div className={styles.cardHospitalTitle}>{detail.hospital.name}</div>
                <div className={styles.cardHospitalLevel}>{detail.hospital.level}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{detail.hospital.area_name}</div>
                <div className={styles.cardHospitalTotal}>
                    医院评价：
                    {detail.is_commented ?
                        <div className={styles.cardHospitalTotalStatus}>
                            “{Tools.getHospitalTotal(detail.total)}”
                        </div> :
                        <div className={styles.cardHospitalCommented}>
                            作者暂未评价
                        </div>
                    }
                </div>
                <div className={styles.cardHospitalLabel}>{(detail.labels).map((res,index)=> {
                    if(index < 4) {
                        return (<div className={styles.cardHospitalLabelItem} key={res.id}>{res.name}</div>)
                    }
                })}</div>
            </div>


        </div>
            <div style={{marginTop: 36}}>
            {
                detail?.is_commented  && <div className={styles.cardScore}>
                    {
                        detail?.levels?.map((i, ind) => {
                            return (
                                <div className={styles.cardScoreItem} key={ind}>
                                    {i.label}:
                                    <img className={styles.cardScoreItemIcon} src={Tools.ScoreText[i.level]?.icon} alt=""/>
                                    <div className={styles.cardScoreItemText}>{Tools.ScoreText[i.level]?.text}</div>
                                </div>
                            )
                        })
                    }

                </div>
            }
            </div>
        </div>
    )
}

export default HospitalCard
