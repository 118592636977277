import React, {useState,useEffect,useRef}  from 'react'
import  * as styles from './Investment.module.css'
import { Images, Tools} from '../../utils';
import { Footer } from '../../components';



function Investment() {
  // useEffect(() => {
  //   Tools.setRem()
  // },[])
  // // 使用 useEffect 在组件挂载时添加事件监听器，并在组件卸载时移除
  // useEffect(() => {
  //   // 添加事件监听器
  //   window.addEventListener('resize', Tools.setRem);

  //   // 在组件卸载时移除事件监听器
  //   return () => {
  //     window.removeEventListener('resize', Tools.setRem);
  //   };
  // }, []); // 通过空数组作为第二个参数，确保 useEffect 只在组件挂载和卸载时执  

  return(
    <div className={styles.container}>
      <img className={styles.inv_top} alt="" src={Images.home.invTop}/>

      <div className={styles.inv_view}>
        <div className={styles.title}>入驻流程</div>
        <div className={styles.process_wrap}>
          <div className={styles.process_view}>
            <div className={styles.process_info}>
              <div className={styles.process_tag}>
                <div className={styles.tag_1}>01</div>
                <div className={styles.tag_2}>准备资料</div>
              </div>
              <div className={styles.process_text}>
                1、查询入驻所需资料 (不同的商家类型、经营品类所
                需入驻资质不同)
                <div className={styles.m_t_20}>
                  2、您可以事先了解《互医网商城商家入驻规则》，准备相关
                  资质
                </div>
              </div>
            </div>
            <img className={styles.arrow} alt="" src={Images.home.arrrow} />
            <div className={styles.process_info}>
              <div className={styles.process_tag}>
                <div className={styles.tag_1}>02</div>
                <div className={styles.tag_2}>商务洽谈</div>
              </div>
              <div className={styles.process_text}>
                1、确认您需要入驻的产品品类及店铺类型
                <div className={styles.m_t_20}>
                2、相关资料备齐后，请致电招商联系人员进行沟通
                </div>
              </div>
            </div>
            <img className={styles.arrow} alt="" src={Images.home.arrrow} />
            <div className={styles.process_info}>
              <div className={styles.process_tag}>
                <div className={styles.tag_1}>03</div>
                <div className={styles.tag_2}>资质审核</div>
              </div>
              <div className={styles.process_text}>
                1、商城质管对提交资质进行审核 (具体以实际审核时效为准)
                <div className={styles.m_t_20}>
                  2、5个工作日后无论是否通过招商人员会电联告知您审核结果
                </div>
              </div>
            </div>
            <img className={styles.arrow} alt="" src={Images.home.arrrow} />
            <div className={styles.process_info}>
              <div className={styles.process_tag}>
                <div className={styles.tag_1}>04</div>
                <div className={styles.tag_2}>线下商务</div>
              </div>
              <div className={styles.process_text}>
                1、签订入驻协议，线上商铺开通
                <div className={styles.m_t_20}>
                  2、后台操作培训，准备产品图片，产品上架事宜
                </div>
              </div>
            </div>
          </div>
          <div className={[styles.process_view,styles.m_t_50].join(" ")}>
            <div className={styles.type_tag}>
              <div className={styles.type_1}>招商品类</div>
              <div className={styles.type_2}>西药、中成药、中药等</div>
            </div>
            <div className={styles.type_tag}>
              <div className={styles.type_1}>招商范围</div>
              <div className={styles.type_2}>药店  品牌药企  保健生产企业  品牌器械生产企业等</div>
            </div>
          </div>
          <div className={styles.title_wrap}>
            <div className={styles.title_text}>招商入驻资质要求说明</div>
            <div className={styles.title_line}></div>
          </div>
          <div className={[styles.card_view, styles.m_t_50].join(" ")}>
            <div className={styles.flex_2}>
              <div className={styles.card_title}>
                会员类型
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                药品企业
              </div>
            </div>
            <div className={styles.flex_2}>
              <div className={styles.card_title}>
                基本证件
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                1、企业营业执照
                <br/>
                2、入驻协议
              </div>
            </div>
            <div className={styles.flex_3}>
              <div className={styles.card_title}>
              详细资料
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                1、基本证件<br/>
                2、药品生产许可证或药品经营许可证<br/>
                3、药品网络销售企业报告信息 (达成合作之后)<br/>
                4、药品注册批件 (质量标准、说明书、包装标签)<br/>
                5、新药证书(新药提供)
              </div>
            </div>
          </div>
          <div className={[styles.card_view, styles.h_310, styles.m_t_36].join(" ")}>
            <div className={styles.flex_2}>
              <div className={styles.card_title}>
                会员类型
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                器械企业
              </div>
            </div>
            <div className={styles.flex_2}>
              <div className={styles.card_title}>
                基本证件
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                1、企业营业执照
                <br/>
                2、入驻协议
              </div>
            </div>
            <div className={styles.flex_3}>
              <div className={styles.card_title}>
                详细资料
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                1、基本证件<br/>
                2、器械生产许可证或备案<br/>
                3、器械经营许可证或备案凭证<br/>
                4、医疗器械网络销售备案信息 (达成合作之后)<br/>
                <div className={styles.m_l_26}>
                    ·医疗器械注册证或备案凭证 (质量标准、说明书、包装标签、合格证)<br/>
                    ·若产品涉及专利，需提供专利相关证明文件
                </div>
              </div>
            </div>
          </div>
          <div className={[styles.card_view, styles.h_462, styles.m_t_36].join(" ")}>
            <div className={styles.flex_2}>
              <div className={styles.card_title}>
                会员类型
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                保健食品企业
              </div>
            </div>
            <div className={styles.flex_2}>
              <div className={styles.card_title}>
                基本证件
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                1、企业营业执照
                <br/>
                2、入驻协议
              </div>
            </div>
            <div className={styles.flex_3}>
              <div className={styles.card_title}>
                详细资料
              </div>
              <div className={[styles.card_tip, styles.m_t_24].join(" ")}>
                1、基本证件<br/>
                2、卫生许可证或食品经营许可证<br/>
                  
                3、.保健食品批准证书 (进口保健食品批准证书)
                    <div className={styles.m_l_26}>
                    ·备案凭证（普通食品)<br/>
                    ·婴幼儿配方乳粉产品配方<br/>
                    ·特殊医学用途配方食品酒类产品(酒类商品批发许可证)<br/>
                    </div>
                4、·卫生安全评价报告 (进口产品需通关单、出入境检验检疫卫生证书)
                    <div className={styles.m_l_26}>
                    ·产品中涉及新原料或食品添加剂或仅可在保健品中可使用的原料，<br/>
                    需提供国务院卫生部门出具的安全评估报告/审批文件;<br/>
                  </div>      
                5、样盒 (电子) 、说明书<br/>
                6、若产品涉及专利，需提供专利相关证明文件<br/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
      
    
  )
}

export default Investment
