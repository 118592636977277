import React from 'react';
import { BrowserRouter as Router, Link, useLocation} from 'react-router-dom';
import * as styles from './Footer.module.css'
import { Images } from '../../utils';

function Footer(props) {

  const onFillingClick = () => {
    window.open("https://beian.miit.gov.cn/");
  }
  const onLZ2Click = () => {
    window.open("https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020230525164914000010574369-SAIC_SHOW_310000-47e01b38bb8840bf8c465e1e5d70d68b286&signData=MEUCIEpnmij+dZA6tssb7zKSnswdGkiY5QjqDFNiSKLTQ8YQAiEAwUk2W1y4+VrCO5zpr7XRvt2O1xiC6BUb9ttaNXbZByo=", '_blank');
  }
  const onLZ3Click = () => {
    window.open("https://beian.mps.gov.cn/#/query/webSearch?code=31010102007693", '_blank');
  }

  return(
    <div className={styles.footer_view} style={props.style}>
      <div className={styles.footer_wrap}>
        <div className={styles.info_text}>
          上海锦竺信息科技有限公司版权所有
          <br /> 
          Copyright © jinzhuapp.com
          <div className={styles.footer_img_wrap}>
            <img 
              className={styles.footer_lz2} 
              src={Images.home.lz2} 
              alt=''
              onClick={onLZ2Click} 
            />
            <Link className={styles.tip_text} to='/bslicense'>
              上海锦竺营业执照
            </Link>
          </div>
          <div className={styles.footer_img_wrap} onClick={onLZ3Click}>
            <img 
              className={styles.footer_lz3} 
              src={Images.home.lz3} 
              alt=''
            />
            <Link className={styles.tip_text} to="">
              沪公网安备 31010102007693
            </Link>
          </div>
        </div>
        <div>
          <div className={styles.tip_text} onClick={onFillingClick}>沪ICP备2021013253号-1</div>
          <Link className={styles.tip_text} to='/license'>增值电信业务经营许可证：沪B2-20230089</Link>
          <br />
          <Link className={styles.tip_text} to='/certificate'>互联网药品信息服务资格证书：(沪) -经营性-2023-0178</Link>
          <br />
          <div className={[styles.tip_text, styles.point_none].join(" ")}>国家高新技术企业：GR202231007906</div>
          <div className={[styles.tip_text, styles.point_none].join(" ")}>2023年上海市创新型中小企业（上海市经信委）</div>
        </div>

      </div>
    </div>
  )
}

export default Footer