import Axios from './Axios'
import {HywApi} from './HywApi'

class myNews {
  getNews = (
    page,
    per_page,
  ) => {
    return new Promise((resolve, reject) => {
      Axios.get(HywApi.getNews+ '?page=' + page +'&per_page=' + per_page)
          .then((res) => {
              console.log(res, HywApi.getNews, 'getNews');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.getNews, 'err----');
              reject(err)
          })
    })
  }
  sendLike = (comment_id) => {
    return new Promise((resolve, reject) => {
      Axios.post(HywApi.commentOperation+ '/' + comment_id)
          .then((res) => {
              console.log(res, HywApi.commentOperation, 'commentOperation');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.commentOperation, 'err----');
              reject(err)
          })
    })
  }
  cancleLike = (comment_id) => {
    return new Promise((resolve, reject) => {
      Axios.delete(HywApi.commentOperation+ '/' + comment_id)
          .then((res) => {
              console.log(res, HywApi.commentOperation, 'commentOperation');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.commentOperation, 'err----');
              reject(err)
          })
    })
  }
  addComments = (
    owner_id,
    content,
    parent_id,
    source_id,
    classify,
  )=> {
    let query = {
      owner_id,
      content,
      parent_id,
      source_id,
      classify,
    }
    return new Promise((resolve, reject) => {
      Axios.post(HywApi.addComments,query)
          .then((res) => {
              console.log(res, HywApi.addComments, 'addComments');
              resolve(res)
          })
          .catch((err) => {
              console.log(err, HywApi.addComments, 'err----');
              reject(err)
          })
    })
  }
}

export const MyNewsApi = new myNews()