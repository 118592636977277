import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { configureStore } from '../src/redux/store'
import { Provider } from "react-redux";
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react'
import zhCN from 'antd/es/locale/zh_CN';
import logger from './utils/alifelogger';


const { persistor, store } = configureStore()


console.disableYellowBox = true
console.warn = () => {};

// 将BrowserLogger实例挂载到全局对象上，以便在整个应用中使用
window.__bl = logger;

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
