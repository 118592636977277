import React,  {useState,useEffect}  from 'react'
import './App';
import BasicRoute from './routers'
import { Tools } from './utils';

function App() {
  useEffect(() => {
    Tools.setRem()
  },[])
    // 使用 useEffect 在组件挂载时添加事件监听器，并在组件卸载时移除
  useEffect(() => {
    // 添加事件监听器
    window.addEventListener('resize', Tools.setRem);

    // 在组件卸载时移除事件监听器
    return () => {
        window.removeEventListener('resize', Tools.setRem);
    };
  }, []); // 通过空数组作为第二个参数，确保 useEffect 只在组件挂载和卸载时执 
  return (
      <div className="App">
        <BasicRoute />
      </div>
  );
}

export default App;
