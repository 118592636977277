import React from 'react';
import  * as styles from './Privacy.module.css'
import Modal from 'react-modal';
import {Images} from '../../utils'

const customStyles = {
  content : {
    
    display: 'flex',
    flex: 1,
    padding: 0,
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: 0, 
    left: 0, 
    right: 0, 
    bottom: 0,
    zIndex: 999,
  }
};

function PrivacyModal(props)  {
  
  return (
    <Modal
      isOpen={props.isOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={styles.protocol_modal}>
        <div className={styles.close_wrap}>
          <div className={styles.close_item} onClick={() => props.onCloseModal()}>
            <img className={styles.close_icon} alt='' src={Images.modal.closeIcon} />
          </div>
        </div>
        <div className={styles.title}>隐私政策</div>
        <div className={styles.content}
          onScroll={(e) => {
            e.preventDefault();
            e.stopPropagation()
            return false
          }}
          onTouchMove={(e) => {
            e.preventDefault();
            e.stopPropagation()
            return false
          }}
          onMouseMove={(e) => {
            e.preventDefault();
            e.stopPropagation()
            return false
          }}
        > 
          <p className={styles.sub_title}>【条约说明】</p>
          <p className={styles.content_del}>
            您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全
            保护措施，尽力保护您的个人信息安全可控。鉴此，互医网（或简称“我们”）制定本《法律声明及隐
            私权政策》（下称“本政策 /本隐私权政策”）并提醒您： 本政策适用于互医网提供的产品或服务。
            如我们关联公司的产品或服务中使用了互医网提供的产品或服务但未设独立法律声明及隐私权政策的，
            则本政策同样适用于该部分产品或服务。
          </p>
          <p className={styles.content_del}>
            需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于已另行独立设置法律声明
            及隐私权政策的互医网产品或服务。例如互医网运营的平台上卖家依托互医网平台向您提供服务时，您
            向卖家提供的个人信息不适用本政策。在使用互医网提供的各项产品或服务前，请您务必仔细阅读并透
            彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用互医网提供的各项产品
            或服务，即表示您已充分理解并同意本政策。
          </p>
          <p className={styles.content_del}>
            如对本声明和政策内容有任何疑问、意见或建议，您可通过在《互医网服务协议》中提供的客服联系
            方式与我们联系。
          </p>
          <p className={styles.sub_title}>
            第一部分 定义 
          </p>
          <p>
            <span className={styles.sub_title}>互医网</span>
            <span className={styles.content_del}>
              是指上海忞竺科技有限公司所发布的互医网网站及移动客户端，及其他现在或将来推出的基于互医
              网的其他服务平台。
            </span>
          </p>
          <p className={styles.content_del}>
            在本声明和政策中更多地称为“互医网”或“我们”。互医网各项服务的经营及责任主体以实际提供该
            服务的上述互医网公司主体为准。
          </p>
          <p>
            <span className={styles.sub_title}>关联公司</span>
            <span className={styles.content_del}>
              ：上海忞竺科技公司直接或者间接控股的公司，参股或形成经营、协作的，具有关联关系的企业
            </span>
          </p>
          <p>
            <span className={styles.sub_title}>用户</span>
            <span className={styles.content_del}>
              是指经过互医网注册程序后，使用互医网各项服务的单位或个人。在本协议中以下更多称为“您”或“用户”。
            </span>
          </p>
          <p>
            <span className={styles.sub_title}>个人信息</span>
            <span className={styles.content_del}>
              指以电子或者其他方式记录的能够单独或者与其他互医网合法持有的信息结合识别特定自然人身份或者反映
              特定自然人活动情况的各种信息。
            </span>
          </p>
          <p>
            <span className={styles.sub_title}>个人敏感信息</span>
            <span className={styles.content_del}>
              ：包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）
              儿童的个人信息等。
            </span>
          </p>
          <p>
            <span className={styles.sub_title}>个人信息删除</span>
            <span className={styles.content_del}>
              ：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
            </span>
          </p>
          <p>
            <span className={styles.sub_title}>业务数据</span>
            <span className={styles.content_del}>
              不同于个人信息，是指互医网的用户利用互医网的服务上传、下载、分发等通过互医网的技术服务处理的数据。
            </span>
          </p>
          <p className={styles.sub_title}>第二部分 法律声明 </p>
          <p className={styles.sub_title}>权利归属</p>
          <p className={styles.content_del}>
            1、除非互医网另行声明，互医网内的所有产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、
            照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括版权、商标权、专利权、商业秘密及其他
            相关权利）均归互医网服务提供者及/或其关联公司所有。
          </p>
          <p className={styles.content_del}>
            2、未经互医网服务提供者及/或其关联公司许可，不得以程序或设备监视、复制、传播、展示、镜像、上载、下
            载等方式擅自使用互医网内的任何内容。
          </p>
          <p className={styles.content_del}>
            3、互医网的文字、标识、徽记、产品和服务名称等均为互医网服务提供者及/或其关联公司在中国和其他国家的
            商标，未经许可，不得用于如宣传、展示等任何使用。
          </p>
          <p className={styles.sub_title}>责任限制</p>
          <p className={styles.content_del}>
            1、互医网运营的平台上的店铺、商品信息（包括但不限于店铺名称、公司名称、 联系人及联络信息、产品的描
            述和说明、相关图片、视频等）由平台上的经营者自行提供并上传，由平台上的经营者对其提供并上传的信息承
            担相应法律责任。互医网服务提供者对此另有约定的，将在相关的协议或其他法律文本中与您进行明确。
          </p>
          <p className={styles.content_del}>
            2、用户在互医网平台的论坛、社区以及其他应用上，自行上传、提供、发布相关信息，包括但不限于用户名称、
            公司名称、联系人及联络信息，相关图片、资讯等，该等信息均由用户自行提供，用户须对其提供的任何信息依
            法承担全部责任。
          </p>
          <p className={styles.content_del}>
            3、互医网转载的作品（包括论坛内容）出于传递更多信息之目的，并不意味我们赞同其观点或已经证实其内容
            的真实性。
          </p>
          <p className={styles.sub_title}>
            知识产权保护
          </p>
          <p className={styles.content_del}>
            1、我们尊重知识产权，反对并打击侵犯知识产权的行为。知识产权权利人若认为互医网内容（包括但不限于互
            医网用户发布的商品信息）侵犯其合法权益的，可以通过互医网的客服渠道进行投诉，我们将在收到知识产权
            权利人合格通知后依据相应的法律法规以及平台规则及时处理。
          </p>
          <p className={styles.sub_title}>
            第三部分 隐私权政策 
          </p>
          <p className={styles.content_del}>
            本隐私权政策部分将帮助您了解以下内容：
          </p>
          <p className={styles.content_del}>
            1、我们如何收集和使用您的个人信息
          </p>
          <p className={styles.content_del}>
            2、我们如何使用 Cookie 和同类技术
          </p>
          <p className={styles.content_del}>
            3、我们如何共享、转让、公开披露您的个人信息
          </p>
          <p className={styles.content_del}>
            4、我们如何保护您的个人信息
          </p>
          <p className={styles.content_del}>
            5、您如何管理您的个人信息
          </p>
          <p className={styles.content_del}>
            6、我们如何处理未成年人的个人信息
          </p>
          <p className={styles.content_del}>
            7、您的个人信息如何在全球范围转移
          </p>
          <p className={styles.content_del}>
            8、本隐私权政策如何更新
          </p>
          <p className={styles.content_del}>
            9、如何联系我们
          </p>
          <p className={styles.sub_title}>
            一、我们如何收集和使用您的信息
          </p>
          <p className={styles.content_del}>
          （一）我们在您使用服务过程中将收集的信息
          </p>
          <p className={styles.content_del}>
            为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态，
            我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
          </p>
          <p className={styles.content_del}>
            设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设
            备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信
            息和设备环境信息）、设备所在位置相关信息（例如IP 地址、GPS位置以及能够提供相
            关信息的Wi-Fi 接入点、蓝牙和基站等传感器信息）。
          </p>
          <p className={styles.content_del}>
            日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们
            服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器
            的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。
          </p>
          <p className={styles.content_del}>
            请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这
            类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，
            则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规
            定外，我们会将该类个人信息做匿名化、去标识化处理。
          </p>
          <p className={styles.content_del}>
            当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，
            以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
          </p>
          <p className={styles.content_del}>
          （二）我们通过间接获得方式收集到的您的个人信息
          </p>
          <p className={styles.content_del}>
            我们可能从第三方获取您授权共享的账户信息（头像、昵称、联系方式），并在您同意本声明
            和政策后将您的第三方账户与您的账户绑定，使您可以通过第三方账户直接登录并使用我们的
            互联网电子服务或商品。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，
            在符合相关法律和法规规定的前提下，使用您的这些个人信息。
          </p>
          <p className={styles.content_del}>
            您可通过同一账号在我们提供的链接入口使用我们关联公司提供的互联网电子服务或商品，为
            便于我们基于关联账号共同向您提供一站式服务并便于您统一进行管理，我们在互医网平台集
            中展示您的信息或推荐您感兴趣的信息。
          </p>
          <p className={styles.content_del}>
            当您通过我们互联网电子服务或商品使用上述服务时，您授权我们根据实际业务及合作需要从我
            们关联公司处接收、汇总、分析我们确认其来源合法或您授权同意其向我们提供的您的个人信息
            或交易信息。
          </p>
          <p className={styles.content_del}>
            如您拒绝提供上述信息或拒绝授权，可能无法使用我们关联公司的相应互联网电子服务或商品，
            或者无法展示相关信息，但不影响使用互医网的健康咨询等核心服务。
          </p>
          <p className={styles.content_del}>
            （三）收集信息的目的
          </p>
          <p className={styles.content_del}>
            1、帮助您成为我们的会员
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1） 您需要注册一个账户，以便于我们为您提供服务。当您注册时，请您至少提供手机号码、
              密码，我们将通过发送短信验证码的方式验证您的身份是否有效。仅需使用浏览、搜索等基本
              服务，您不需要注册成为我们的会员及提供上述信息。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2） 您的登录名包括但不仅限于您的手机号、邮箱账号、用户名。在登录互医网平台后，如
              果您提供以下额外信息补全个人资料，将有助于我们给您提供更个性化的服务，如您的真实姓
              名、性别、出生年月日、居住地、您本人的真实头像、生活习惯、健康状况等信息。若您不提
              供这些信息，将会影响到您使用个性化的会员服务，但不会影响使用互医网产品或服务的基本
              浏览、搜索、购买功能。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3） 您需要向我们提供真实姓名和有效身份证件（包括但不限于身份证、医保卡、护照）的号
              码和复印件，以便于我们进行实名认证。如果您不提供这些信息，可能会影响您对互医网部分
              核心业务功能的正常使用，但不会影响您进行基本的浏览、搜索。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4） 如成为我们平台的注册医生，您需要提供包括但不仅限于手机号码、邮箱账号、用户名，
              并创建密码。在注册过程中，如您提供以下额外信息，将有助于我们对您提供电子服务资格
              进行认证：您的真实姓名、性别、身份证正反面照片、联系信息（QQ/微信/邮箱地址）、医
              院电话、《执业医师资格证》、《医师执业证书》、《职称证》、您本人的真实头像。如果
              您未提供这些信息，将会影响到您在互医网进行的电子服务活动。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5） 在您主动注销账号时，我们将根据相关法律法规的要求尽快使其匿名或删除您的个人信息。
            </span>
          </p>
          <p className={styles.content_del}>
            2、为您展示和推送商品或服务
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1） 为便于向您提供我们的产品或服务，我们会记录您提供的收货人姓名、收货地址、邮政编码、
              收货人联系电话。为便于向您交付互联网电子服务或商品，您需提供收货人姓名、收货地址、邮
              政编码、收货人联系电话。如果我们委托第三方向您交付时，我们会在征得您同意后将上述信息
              共享给第三方。如果您拒绝提供此类信息，您理解并同意我们将无法完成相关交付服务。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2） 为更好地支付费用，使用互医网合作金融机构进行理财等服务，您需要提供银行卡号、该卡
              的预留手机号、身份证号码，并创建账户与支付口令。如您不提供上述信息，将无法正常使用互
              联网电子服务或商品相关的支付等功能，但不影响您使用互医网产品或服务的基本浏览、搜索功能。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3） 为完成付款，您可能需要提供支付账号并选择付款方式，以便我们了解您的支付状态。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4）  为便于您顺利使用互医网提供的试用服务，您需要提供联系方式、联系地址与机构相关信息。
              如您不提供上述信息将会影响您使用互医网提供的试用服务。
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5）  您可以通过互医网为其他人进行预约或咨询等服务，您需要提供该实际用户的前述个人信息。
              向互医网提供该实际用户的前述个人信息之前，您需确保您已经取得其授权同意。
            </span>
          </p>
          <p className={styles.content_del}>
            3、为您提供安全保障：
          </p>
          <p className={styles.content_del}>
            为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产
            安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，
            更准确地识别违反法律法规或互医网相关协议规则的情况，我们可能使用或整合您的会员信息、交易信
            息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合
            判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、
            处置措施。
          </p>
          <p className={styles.content_del}>
            4、其他用途：我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其
            他目的时，会事先征求您的同意。
          </p>
          <p className={styles.content_del}>
            5、征得授权同意的例外
          </p>
          <p className={styles.content_del}>
            根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              1）  与国家安全、国防安全有关的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              2）  与公共安全、公共卫生、重大公共利益有关的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              3）  与犯罪侦查、起诉、审判和判决执行等有关的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              4）  出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              5）  所收集的个人信息是您自行向社会公众公开的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              6）  从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              7）  根据您的要求签订合同所必需的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              8）  用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              9）  为合法的新闻报道所必需的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              10）  学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，
              对结果中所包含的个人信息进行去标识化处理的；
            </span>
          </p>
          <p className={styles.sub_content}>
            <span className={styles.content_del}>
              11）  法律法规规定的其他情形。
            </span>
          </p>
          <p className={styles.content_del}>
            如我们停止运营互医网产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以
            逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
          </p>
          <p className={styles.sub_title}>二、我们如何使用 Cookie 和同类技术</p>
          <p className={styles.content_del}>（一）Cookie </p>
          <p className={styles.content_del}>
            为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算
            机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码
            和字符。借助于 Cookie，互医网能够记住您的选择，提供个性化增强服务等。您可根据自己的情况，
            修改对Cookie的接受程度或拒绝我们的Cookie。有关详情，请参见AboutCookies.org。但如果您
            这么做，在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改
            用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
          </p>
          <p className={styles.content_del}>（二）网站信标和像素标签</p>
          <p className={styles.content_del}>
            除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的
            电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助
            我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。
          </p>
          <p className={styles.sub_title}>
            三、我们如何共享、转让、公开披露您的个人信息
          </p>
          <p className={styles.content_del}>（一）共享</p>
          <p className={styles.content_del}>
            我们不会与互医网服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
          </p>
          <p className={styles.content_del}>
            1、在您主动选择情况下共享：若您主动通过互医网购买商品和服务，我们会根据您的选择，将您的订单
            信息与交易有关的必要信息与相关商品和服务的提供者共享来实现您向其购买商品或服务的需求，并促
            使其可以完成后续的售后服务。您可以在相关商品和服务的提供者从事经营活动的主页面查询其营业执
            照登载的信息或者其营业执照的电子链接标识，以辨别您的交易对象身份。
          </p>
          <p className={styles.content_del}>
            2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。例如，如
            果我们委托第三方向您提供产品或服务时，我们会在征得您同意后将上述信息共享给第三方，如果您拒绝
            提供此类信息，我们将无法完成相关交付服务。
          </p>
          <p className={styles.content_del}>
            3、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法
            提出的要求，对外共享您的个人信息。
          </p>
          <p className={styles.content_del}>
            4、与关联公司间共享：为便于我们基于关联账户向您服务，推荐您可能感兴趣的信息或保护互医网关联
            公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只
            会共享必要的个人信息且受本隐私权政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联
            公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
          </p>
          <p className={styles.content_del}>
            5、与授权合作伙伴共享：我们会委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出
            于合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到为其履行职责所需信
            息，且不能将此信息用于任何其他目的。
          </p>
          <p className={styles.content_del}>
            目前，我们的授权合作伙伴包括以下类型：
          </p>
          <p className={styles.content_del}>
            （1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以
            识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分
            析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的
            个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我
            们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看
            到广告后安装了应用，或者向这些合作伙伴提供不具备个体辨识度的统计信息（例如“女性，20-25岁，
            位于深圳”），帮助他们了解其受众或顾客。
          </p>
          <p className={styles.content_del}>
            （2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其
            他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、
            提供客户服务、支付便利或进行学术研究和调查。
          </p>
          <p className={styles.content_del}>
            对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求他们按照我们
            的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。
          </p>
          <p className={styles.content_del}>（二）转让</p>
          <p className={styles.content_del}>
            我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          </p>
          <p className={styles.content_del}>
            1、在获取您的明确同意的情况下转让；
          </p>
          <p className={styles.content_del}>
            2、在互医网服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉
            及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求
            该公司、组织和个人重新向您征求授权同意。
          </p>
          <p className={styles.content_del}>
          （三）公开披露
          </p>
          <p className={styles.content_del}>
            我们仅会在以下情况下，公开披露您的个人信息：
          </p>
          <p className={styles.content_del}>
            1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
          </p>
          <p className={styles.content_del}>
            2、如果我们确定您出现违反法律法规或严重违反互医网相关协议规则的情况，或为保护互医网及其关联公司
            用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或互医网相关协议规则征得您同意的情况下披露
            关于您的个人信息，包括相关违规行为以及互医网已对您采取的措施。
          </p>
          <p className={styles.content_del}>
          （四）共享、转让、公开披露个人信息时事先征得授权同意的例外
          </p>
          <p className={styles.content_del}>
            以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
          </p>
          <p className={styles.content_del}>
            1、与国家安全、国防安全有关的；
          </p>
          <p className={styles.content_del}>
            2、与公共安全、公共卫生、重大公共利益有关的；
          </p>
          <p className={styles.content_del}>
            3、与犯罪侦查、起诉、审判和判决执行等有关的；
          </p>
          <p className={styles.content_del}>
            4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          </p>
          <p className={styles.content_del}>
            5、您自行向社会公众公开的个人信息；
          </p>
          <p className={styles.content_del}>
            6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。 根据法律规
            定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，
            不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征
            得您的同意。
          </p>
          <p className={styles.sub_title}>
            四、我们如何保护您的个人信息安全
          </p>
          <p className={styles.content_del}>
            （一）我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息
            遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据
            （如信用卡信息）时受 SSL（Secure Socket Layer）协议加密保护；我们同时对互医网网站提供
            HTTPS（Hyper Text Transfer Protocol over Secure Socket Layer）协议安全浏览方式；
            我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；
            我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护
            培训课程，加强员工对于保护个人信息重要性的认识。
          </p>
          <p className={styles.content_del}>
            （二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、
            制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。
          </p>
          <p className={styles.content_del}>
            （三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的
            所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
          </p>
          <p className={styles.content_del}>
            （四）互联网并非绝对安全的环境，我们强烈建议您不要使用非互医网推荐的通信方式发送个人信息。
            您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以
            自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等
            相关信息的第三方。
          </p>
          <p className={styles.content_del}>
            在使用互医网服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信
            息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发
            现自己的个人信息尤其是您的账户信息发生泄露，请您立即联络互医网客服，以便我们根据您的申请
            采取相应措施。
          </p>
          <p className={styles.content_del}>
            在使用互医网服务进行网上健康咨询等服务时，您不可避免地要向服务方或潜在的服务对方披露自己
            的个人信息，如年龄、健康情况、病史等信息。请您妥善保护自己的个人信息，仅在必要的情形下向
            他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络互医网客服，以
            便我们根据您的申请采取相应措施。
          </p>
          <p className={styles.content_del}>
            请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个
            人敏感信息，如您在评价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的
            服务时共享甚至公开分享相关信息。
          </p>
          <p className={styles.content_del}>
            请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。
            如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，
            导致您的合法权益受损，我们将承担相应的法律责任。
          </p>
          <p className={styles.content_del}>
            （五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情
            况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补
            救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人
            信息主体时，我们会采取合理、有效的方式发布公告。
          </p>
          <p className={styles.content_del}>
            同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
          </p>
          <p className={styles.sub_title}>
            五、您如何管理您的个人信息
          </p>
          <p className={styles.content_del}>
            您可以通过以下方式访问及管理您的个人信息：
          </p>
          <p className={styles.content_del}>
          （一）访问您的个人信息
          </p>
          <p className={styles.content_del}>
            您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：
          </p>
          <p className={styles.content_del}>
            账户信息——如果您希望访问或编辑您的账户中的个人基本资料，以便互医网为您提供更个性化的优质
            服务，您可以通过登录账户通过“个人资料”执行此类操作。
          </p>
          <p className={styles.content_del}>
            健康档案信息：您可以在“个人中心”中查阅或清除您的相关记录。
          </p>
          <p className={styles.content_del}>
            银行卡管理：您可以登录账号，通过“个人中心”进行银行卡的解绑、绑定。
          </p>
          <p className={styles.content_del}>
            预约、咨询记录：您可以登录账号，通过“个人中心”查看了解历史记录。
          </p>
          <p className={styles.content_del}>
            删除您的订单记录、交易记录（对于部分未实现系统化删除功能的记录，您可联系互医网客
            服进行删除。
          </p>
          <p className={styles.content_del}>
            如果您无法通过上述路径访问该等个人信息，您可以随时通过互医网客服与我们取得联系。我们
            将在15天内回复您的访问请求。
          </p>
          <p className={styles.content_del}>
            对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（七）响应您的上
            述请求”中的相关安排向您提供。
          </p>
          <p className={styles.content_del}>
            （二）更正或补充您的个人信息
          </p>
          <p className={styles.content_del}>
            当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过
            “（一）访问您的个人信息”中列明的方式提出更正或补充申请。
          </p>
          <p className={styles.content_del}>
          （三）删除您的个人信息
          </p>
          <p className={styles.content_del}>
            您可以通过“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。
          </p>
          <p className={styles.content_del}>
            在以下情形中，您可以向我们提出删除个人信息的请求：
          </p>
          <p className={styles.content_del}>
            1、如果我们处理个人信息的行为违反法律法规；
          </p>
          <p className={styles.content_del}>
            2、如果我们收集、使用您的个人信息，却未征得您的明确同意；
          </p>
          <p className={styles.content_del}>
            3、如果我们处理个人信息的行为严重违反了与您的约定；
          </p>
          <p className={styles.content_del}>
            4、如果您不再使用我们的产品或服务，或您主动注销了账户；
          </p>
          <p className={styles.content_del}>
            5、如果我们永久不再为您提供产品或服务。
          </p>
          <p className={styles.content_del}>
            若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时
            删除，除非法律法规另有规定，或这些主体获得您的独立授权。
          </p>
          <p className={styles.content_del}>
            当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删
            除这些信息。
          </p>
          <p className={styles.content_del}>
          （四）改变您授权同意的范围
          </p>
          <p className={styles.content_del}>
            每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第三部分第一条”）。除此之外，
            对于额外个人信息的收集和使用，您可以与互医网客服联系给予或收回您的授权同意。
          </p>
          <p className={styles.content_del}>
            当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而
            开展的个人信息处理。
          </p>
          <p className={styles.content_del}>
            （五）个人信息主体注销账户
          </p>
          <p className={styles.content_del}>
            在符合互医网单项服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项互医网服务帐
            号可能被注销或删除。当帐号注销或被删除后，与该帐号相关的、该单项服务项下的全部服务资料和数
            据将依照单项服务的服务协议约定删除或匿名化处理。
          </p>
          <p className={styles.content_del}>
            （六）约束信息系统自动决策
          </p>
          <p className={styles.content_del}>
            在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些
            决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害互医网商业秘密或其他用户
            权益、社会公共利益的前提下提供申诉方法。
          </p>
          <p className={styles.content_del}>
            （七）响应您的上述请求
          </p>
          <p className={styles.content_del}>
            为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的
            身份，然后再处理您的请求。
          </p>
          <p className={styles.content_del}>
            我们将在15天内做出答复。如您不满意，还可以通过互医网客服发起投诉。
          </p>
          <p className={styles.content_del}>
            对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一
            定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）
            、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
          </p>
          <p className={styles.content_del}>
            在以下情形中，按照法律法规要求，我们将无法响应您的请求：
          </p>
          <p className={styles.content_del}>
            1、与国家安全、国防安全有关的；
          </p>
          <p className={styles.content_del}>
            2、与公共安全、公共卫生、重大公共利益有关的；
          </p>
          <p className={styles.content_del}>
            3、与犯罪侦查、起诉、审判和执行判决等有关的；
          </p>
          <p className={styles.content_del}>
            4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；
          </p>
          <p className={styles.content_del}>
            5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
          </p>
          <p className={styles.content_del}>
            6、涉及商业秘密的。
          </p>
          <p className={styles.sub_title}>
            六、我们如何处理未成年人的个人信息
          </p>
          <p className={styles.content_del}>
            如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人的，建议您请您
            的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或
            向我们提供信息。
          </p>
          <p className={styles.content_del}>
            对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法
            规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
          </p>
          <p className={styles.content_del}>
            根据当地监管部门的相关规定，互医网仅对满足特定年龄要求的用户提供互联网电子服务或商品。
          </p>
          <p className={styles.sub_title}>
            七、您的个人信息如何在全球范围转移
          </p>
          <p className={styles.content_del}>
            我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：
          </p>
          <p className={styles.content_del}>
            1、法律法规有明确规定；
          </p>
          <p className={styles.content_del}>
            2、获得您的明确授权；
          </p>
          <p className={styles.content_del}>
            3、您通过互联网进行跨境交易等个人主动行为。
          </p>
          <p className={styles.content_del}>
            针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。
          </p>
          <p className={styles.sub_title}>
            八、本隐私权政策如何更新
          </p>
          <p className={styles.content_del}>
            我们的隐私权政策可能变更。
          </p>
          <p className={styles.content_del}>
            未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。
          </p>
          <p className={styles.content_del}>
            我们会在专门页面上发布对隐私权政策所做的任何变更。
          </p>
          <p className={styles.content_del}>
            对于重大变更，我们还会提供更为显著的通知（包括我们会通过互医网公示的方式进行通知甚至向
            您提供弹窗提示）。
          </p>
          <p className={styles.content_del}>
            本政策所指的重大变更包括但不限于：
          </p>
          <p className={styles.content_del}>
            1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用
            方式等；
          </p>
          <p className={styles.content_del}>
            2、我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；
          </p>
          <p className={styles.content_del}>
            3、个人信息共享、转让或公开披露的主要对象发生变化；
          </p>
          <p className={styles.content_del}>
            4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
          </p>
          <p className={styles.content_del}>
            5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
          </p>
          <p className={styles.content_del}>
            6、个人信息安全影响评估报告表明存在高风险时。
          </p>
          <p className={styles.sub_title}>
            九、如何联系我们 
          </p>
          <p className={styles.content_del}>
            您可以通过以下方式与我们联系，我们将在收到您相关联系信息后30个工作日内予以回复： 
          </p>
          <p className={styles.content_del}>
            1、如对本政策内容有任何疑问、意见或建议，您可通过互医网客服提出；
          </p>
          <p className={styles.content_del}>
            2、如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以
            通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
          </p>
        </div>
        <div className={styles.btn_wrap}>
          <button className={styles.confirm_btn} onClick={() => props.onCloseModal()}>确定</button>
        </div>
      </div>
    </Modal>
  )


}

export default PrivacyModal