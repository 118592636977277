import React, {useState, useEffect} from 'react'
import {NoteApi,MyNewsApi} from '@/api/index'
import {Images, Tools} from '@/utils'
import  * as styles from './NoteDetail.module.css'
import { Tabs, List, Pagination,message, Divider} from 'antd';
import {HospitalCard, DoctorCard, ReplyCard, ProcessCard} from './components/index'
import { Loading, Footer,ToTop } from '@/components'


const { TabPane } = Tabs;

function NoteDetail(props) {
    const { noteId } = props.match.params
    const [detail, setDetail] = useState({})
    const [loading, setLoading] = useState(false)

    const [commentsData, setCommentsData] = useState([])
    const [totalCount, setTotalCount] = useState(1)
    const [commentsCount ,setCommentCount] = useState(1)
    const [page, setPage] = useState(1)

    useEffect(()=> {
        setLoading(true)
        NoteApi.fetchNoteDetail(noteId).then((res)=>{
            setLoading(false)
            if(res.success) {
                setDetail(res.data)
            }
        })
        window.scrollTo(0,0)
    }, [])

    const formatUpdateTime = (time) => {
        if(!time) {
            return ''
        }
        const d = new Date(time)
        return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`
    }

    useEffect(() => {
        getComment()
    },[page])


    const getComment = async() => {
        try {

            const res = await NoteApi.getComments(page, '10', noteId, 'note')
            if(res?.success) {
                const ary = initData(res.data.comments,1)
                setTotalCount(res.data.totalCount)
                setCommentCount(res.paginationMeta.totalCount)
                setCommentsData(ary)
            }
        } catch (error) {

        }
    }

    const initData = (data,page) => {
        let newAry = [] ;
        if(Array.isArray(data) && data.length > 0) {
            newAry = data.map((item) => {
                item.show_reply = false
                if(item?.reply_comments && item.reply_comments.length > 0) {
                    item.reply_comments = item.reply_comments.map(inner => {
                        inner.show_reply = false
                        return inner
                    })
                }
                item.page = page
                return item
            })
            return newAry
        }
        return newAry
    }

    const onCommentsPageChange = (page) => {
        setPage(page)
    }
    const onReplyPageChange = async(page, data) => {
        try {
            const res = await NoteApi.getCommentReply(page,5,data.id)
            if(res?.success) {
                const ary = initReply(res.data)
                const newData = commentsData.map(item => {
                    if(data.id === item.id) {
                        item.replies = res.paginationMeta.totalCount
                        item.reply_comments = ary
                        item.page = page
                    }
                    return item
                })
                setCommentsData(newData)
            }
        } catch (error) {

        }
    }
    const initReply = (data) => {
        let newAry = [] ;
        if(Array.isArray(data) && data.length > 0) {
            newAry = data.map((item) => {
                item.show_reply = false
                return item
            })
            return newAry
        }
        return newAry
    }

    const onLikeClick = async(info,parentInfo,inside) => {
        try{
            if(!info.is_support) {
                const res = await MyNewsApi.sendLike(info.id)
                if(res && res.success){
                    message.info('点赞成功')
                    if(!inside) {
                        const ary = commentsData.map(item => {
                            if(item.id === info.id) {
                                item.is_support = true
                                item.supports = item.supports + 1
                            }
                            return item
                        })
                        setCommentsData(ary)
                    }else {
                        const ary = commentsData.map(item => {
                            if(item.id === parentInfo.id) {
                                item.reply_comments = item.reply_comments.map(inner => {
                                    if(inner.id === info.id) {
                                        inner.is_support = true
                                        inner.supports = inner.supports + 1
                                    }
                                    return inner
                                })
                            }
                            return item
                        })
                        setCommentsData(ary)
                    }
                }
            }else {
                const res = await MyNewsApi.cancleLike(info.id)
                if(res && res.success){
                    message.info('取消点赞')
                    if(!inside) {
                        const ary = commentsData.map(item => {
                            if(item.id === info.id) {
                                item.is_support = false
                                item.supports = item.supports - 1
                            }
                            return item
                        })
                        setCommentsData(ary)
                    }else {
                        const ary = commentsData.map(item => {
                            if(item.id === parentInfo.id) {
                                item.reply_comments = item.reply_comments.map(inner => {
                                    if(inner.id === info.id) {
                                        inner.is_support = false
                                        inner.supports = inner.supports - 1
                                    }
                                    return inner
                                })
                            }
                            return item
                        })
                        setCommentsData(ary)
                    }
                }
            }
        }catch(e) {
            console.log(e, 'err')
        }
    }
    const onShowReplyClick = (info,parentInfo,inside,show) => {
        if(!inside) {
            const ary = commentsData.map(item => {
                if(item.id === info.id) {
                    item.show_reply = show
                }
                return item
            })
            setCommentsData(ary)
        }else {
            const ary = commentsData.map(item => {
                if(item.id === parentInfo.id) {
                    item.reply_comments = item.reply_comments.map(inner => {
                        if(inner.id === info.id) {
                            inner.show_reply = show
                        }
                        return inner
                    })
                }
                return item
            })
            setCommentsData(ary)
        }
    }
    const onSendClick = async(val,info,parentInfo,inside) => {
        const id = info.id;
        const values = val[id]
        if(values === '' || values === null || values === undefined ) {
            return message.info('不说两句吗？')
        }
        try {
            let owner_id = noteId,
            content = values,
            parent_id = info.id,
            source_id = !inside ? info.id : parentInfo.id,
            classify = info.classifies ;
            const res = await MyNewsApi.addComments(owner_id,content,parent_id,source_id,classify)
            if(res?.success) {
                message.info('回复成功')
                onShowReplyClick(info,parentInfo,inside,false)
                getComment()
            }

        } catch (error) {

        }
    }

    const RenderItem = (props) => {
        const data = props.info
        return (
            <div>
                <ReplyCard
                    info={data}
                    inside={false}
                    parentInfo={null}
                    onLikeClick={(a,b,c) => onLikeClick(a,b,c)}
                    onShowReplyClick={(a,b,c,d) => onShowReplyClick(a,b,c,d)}
                    onSendClick={(a,b,c,d) => onSendClick(a,b,c,d)}
                />
                {
                    Array.isArray(data?.reply_comments) && data?.reply_comments.length > 0 &&
                    <div className={styles.comments_reply}>
                        <div className={styles.reply_count}>{data.replies+'条回复'}</div>
                        <List
                            dataSource={data?.reply_comments}
                            renderItem={inner =>(
                                <ReplyCard
                                    key={inner.id}
                                    info={inner}
                                    inside={true}
                                    parentInfo={data}
                                    onLikeClick={(a,b,c) => onLikeClick(a,b,c)}
                                    onShowReplyClick={(a,b,c,d) => onShowReplyClick(a,b,c,d)}
                                    onSendClick={(a,b,c,d) => onSendClick(a,b,c,d)}
                                />
                            )}
                        />
                        {data.replies > 5 && <div className={styles.page_view}>
                            <Pagination
                                defaultCurrent={data.page}
                                pageSize={5}
                                pageSizeOptions={5}
                                total={data.replies}
                                showSizeChanger={false}
                                onChange={page => onReplyPageChange(page, data)}
                            />
                        </div>}
                    </div>
                }
            </div>
        )
    }

    const CommentList = () => {
        return (
            <div>
                <div className={styles.comments_count}>{'全部评论（'+ totalCount + '）'}</div>
                <List
                    dataSource={commentsData}
                    renderItem={item =>(
                        <RenderItem
                            key={item.id}
                            info={item}
                        />
                    )}
                />
                {commentsCount >5 && <div className={styles.page_view}>
                    <Pagination
                        defaultCurrent={page}
                        pageSizeOptions='10'
                        total={commentsCount}
                        showSizeChanger={false}
                        onChange={page => onCommentsPageChange(page)}
                    />
                </div>}

            </div>

        )
    }





    return (
        <div className={styles.container}>
            <div className={styles.detailContainer}>
                {loading ? <Loading />:
                    <div>
                        <div className={styles.detailTitle}>{detail.title}</div>
                        <div className={styles.detailUserInfo}>
                            <img className={styles.detailUserAvatar} alt='' src={detail.user?.avatar || Images.note.userDefaultAvatar} />
                            <div className={styles.detailUserName}>{detail.user?.name}</div>
                            <div className={styles.detailUserReadCount}>阅读量 {detail.read_count}</div>
                            <div className={styles.detailUserUpdateTime}>最后更新于{formatUpdateTime(detail.update_time)}</div>
                        </div>
                        <Tabs defaultActiveKey="0" className={styles.detailTabs}>
                                {(detail.article || []).map((res, index) => {
                                    return (
                                        <TabPane tab={<div><div className={styles.tabTitle}>{res.title}</div><div className={styles.tabCreateTime}>{Tools.formatDate(res.create_time)}发布</div></div>} key={index}>
                                            <div className={styles.detailTabPane}>
                                                {
                                                    res.treatInfo?.treat_time  &&
                                                    <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>就诊时间:</div>
                                                        <div>{res.treatInfo?.treat_time}</div>
                                                    </div>
                                                }

                                                    <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>症状表现:</div>
                                                        <div>{((res.treatInfo?.symptom || []).map((res)=> {return res.name})).toString()}</div>
                                                    </div>
                                                    <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>确诊疾病:</div>
                                                        <div>{(res.treatInfo?.disease || []).map((res)=> {return res.name})}</div>
                                                    </div>
                                                {
                                                    res.treatInfo?.see_doc_rea && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>就诊原因:</div>
                                                        <div>{res.treatInfo?.see_doc_rea}</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.sec_hos_rea && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>选择该医院原因:</div>
                                                        <div>{res.treatInfo?.sec_hos_rea}</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.appointment && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>预约方式:</div>
                                                        <div>{res.treatInfo?.appointment}</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.transport &&  <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>交通方式:</div>
                                                        <div>{res.treatInfo?.transport}</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.data?.join('、') && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>携带材料:</div>
                                                        <div>{res.treatInfo?.data?.join('、')}</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.question?.length > 0 && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>问诊问题:</div>
                                                        <div style={{display: 'flex', }}>
                                                            {res.treatInfo?.question?.map((i, ind) => {
                                                                return (
                                                                    <span key={ind} className={styles.questionSpan}>{i}</span>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    ((res.treatInfo?.cost??0) + (res.treatInfo?.medical_cost??0) > 0) && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>本次就医花费:</div>
                                                        <div>{(res.treatInfo?.cost??0) + (res.treatInfo?.medical_cost??0)}元</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.medical_cost && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>医保:</div>
                                                        <div>{res.treatInfo?.medical_cost??0}元</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.cost && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>自费:</div>
                                                        <div>{res.treatInfo?.cost??0}元</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.spend_time &&  <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>累计就诊时长:</div>
                                                        <div>{res.treatInfo?.spend_time}</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo.treat_method?.length > 0 &&  <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>治疗方式:</div>
                                                        <div>{res.treatInfo.treat_method.map(i=> i.name).join("、")}</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.now_state && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom}>目前病情状况:</div>
                                                        <div>{Tools.getNowState(res.treatInfo?.now_state)}</div>
                                                    </div>
                                                }
                                                {
                                                    res.treatInfo?.friendly_reminder && <div className={styles.detailTabPaneItemMolecular}>
                                                        <div className={styles.detailTabPaneItemAtom} style={{color: '#FF8533'}}>友情提醒:</div>
                                                        <div style={{color: '#FF8533'}}>{(res.treatInfo?.friendly_reminder)}</div>
                                                    </div>
                                                }


                                            </div>
                                            <HospitalCard detail={res.hospital} />
                                            <DoctorCard detail={res.doctor} />
                                            <ProcessCard detail={res.experience} />
                                            <div style={{marginTop: 30}}>
                                                <div style={{fontSize: 16, color: '#162533', fontWeight: '500'}}>个人感受：</div>
                                                <div style={{fontSize: 16, color: '#696E7E', lineHeight: '22px', marginTop: 4}}>{res?.roast??'作者暂未评价'}</div>
                                            </div>
                                            <Divider />
                                            <div className={styles.detailTypes}>
                                                {(res.types || []).map((res)=> {
                                                    return (
                                                        <div key={res.id} className={styles.detailTypesItem}>
                                                            #{res.name}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: res.content }} />
                                            <div className={styles.detailEndFlag}>————  本文结束  ————</div>
                                        </TabPane>
                                    )
                                })}
                        </Tabs>
                    </div>
                }
                {Array.isArray(commentsData) && commentsData.length > 0 && <CommentList />}

            </div>
            <Footer style={{ marginBottom: '44px'}} />
            <ToTop onToTopClick={() => window.scrollTo(0, 0)}/>
        </div>
    )
}


export default NoteDetail
