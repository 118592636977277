import React, {useState,useEffect}  from 'react';
import styles from './Bind.module.css'
import Modal from 'react-modal';
import {Tools, Images} from '../../utils'
import {LoginApi} from '../../api'

const customStyles = {
  content : {
    
    display: 'flex',
    flex: 1,
    padding: 0,
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: 0, 
    left: 0, 
    right: 0, 
    bottom: 0,
    zIndex: 999,
  }
};

function BindModal(props)  {

  const [tel, onTelChange] = useState('');
  const [verCode, onVerCodeChange] = useState('');
  const [waitTime, changeWaitTime] = useState(60000);
  const [sendClick, changeSendClick] = useState(false);
  const [btnDisabled, changeBtnDisabled] = useState(true);
  const [errTip, setErrTip] = useState('');

  useEffect(()=>{
    let interval
    // 开始倒计时
    if (waitTime && sendClick) {
        interval = setInterval(() => {
            changeWaitTime(preSecond => {
                if (preSecond > 1000) {
                    return preSecond - 1000
                } else {
                    clearInterval(interval)
                    changeSendClick(false);
                    return 60000
                }
            })
        }, 1000)
    }
    console.log(waitTime, "当前时间")
    return () => clearInterval(interval)
  },[waitTime]);

  const getMsgCode = () => {
    getTimer(() => getVifiCode())
  };

  // 获取验证
  const getVifiCode = async () => {
      try {
          let res = await LoginApi.getMsgCode(tel)
          console.log(res)
      }catch (e) {
          console.log(e,'error')
      }
  }

  const getTimer = (callback) => {
    if (!Tools.verifyTel(tel)) {
      setErrTip('*请输入正确手机号码')
      return
    }

    changeSendClick(true);
    callback  && callback();
    changeWaitTime(waitTime - 1000);
  };

  // 修改按钮状态
  const allChangeBtnDisabled = (a,b) => {
    if(a !=='' && a.trim().length > 0 && b!=='' && b.trim().length > 0) {
        changeBtnDisabled(false)
    }else {
        changeBtnDisabled(true)
    }
  };

  //校验手机号
  const onTelTextChange = (val) => {
    
    // setErrTip('')
    onTelChange(val);
    allChangeBtnDisabled(val,verCode);
  };
  const onTelTextBlur = () => {
    if(!Tools.verifyTel(tel)) {
      setErrTip('*请输入正确手机号码')
    }
  }

  const onLoginClick = async() => {
    try {
      const res = await LoginApi.accountLogin(tel,verCode)
      if(res && res.success) {
        console.log(res.data ,'登录')
        localStorage.setItem("access_token", res.data.access_token)
      }
    }catch(e) {
      console.log(e, "err")
    }
    
  }
  
  // render() {
    return (
      <Modal
        preventScroll
        isOpen={props.isOpen}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className={styles.modal_view}>
          <div className={styles.close_wrap}>
            <div className={styles.close_item} onClick={() => props.onCloseModal()}>
              <img className={styles.close_icon} alt='' src={Images.modal.closeIcon} />
            </div>
          </div>
          <div className={styles.content_wrap}>
            <div className={styles.title}>请绑定手机号码</div>
            <div 
              className={styles.input_wrap}
              style={{ borderColor: errTip !== '' ?  '#F16765' : '#DCDFE6'}}
            >
              <input 
                className={styles.num_input}
                placeholder='请输入手机号'
                max={11}
                type='number'
                value={tel}
                onChange={(e) => onTelTextChange(e.target.value)}
                onFocus={() => {
                  setErrTip('')
                }}
                onBlur={() => onTelTextBlur()}
              />
            </div>
            <div className={styles.input_wrap_bottom}>
              <input 
                className={styles.code_input }
                placeholder='请输入短信验证码'
                type='text'
                value={verCode}
                maxLength={6}
                onChange={(e) => {
                  onVerCodeChange(e.target.value)
                  allChangeBtnDisabled(e.target.value,tel);
                }}
              />
              <div className={styles.line_icon} />
              <button 
                disabled={sendClick}
                className={styles.code_btn}
                onClick={() => getMsgCode()}
              >
                {sendClick ? `${waitTime/1000}s后重发` : '获取验证码'}
              </button>
            </div>
            <div className={styles.err_tip}>{errTip}</div>
            <button 
              disabled={btnDisabled}
              className={styles.submit_btn}
              onClick={() => onLoginClick()}
            >
              绑定
            </button>
            
          </div>
        </div>
      </Modal>
    )
  // }


}

export default BindModal