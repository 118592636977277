import React from 'react'
import { List, Pagination, Input, Form,message } from 'antd';
import  * as styles from './ReplyCard.module.css'
import {Images, Tools} from '@/utils'



const { TextArea } = Input;


function ReplyCard(props) {
    const [form] = Form.useForm();
    let {info, parentInfo, inside} = props
  
    return(
        <div className={styles.reply_item}>
            <div className={styles.header_view}>
                <img className={styles.avatar} src={info?.user?.avatar ? info.user.avatar : Images.home.defaultAvatar} alt='' />
                <div className={styles.name_wrap}>
                    <span className={styles.user_name}>{info.user.name}</span>
                    <span className={styles.user_time}>{Tools.getTimeFromNow(info.create_time)}</span>
                </div>
            </div>
            {
                info.parent !== undefined && info.parent !== null
                ?<div className={styles.reply_wrap}>
                    <span className={styles.reply_del}>
                        {info.content+ "//@"}
                        <span className={styles.reply_tip}>{info.parent.user.name}</span>
                        {"："+info.parent.content}
                    </span>
                
                </div>
                :<div className={styles.reply_wrap}>
                    <span className={styles.reply_del}>{info.content}</span>
                </div>
            }
            <div className={styles.like_reply}>
                <div 
                    className={styles.like_wrap}
                    onClick={() => props.onLikeClick(info,parentInfo,inside)}
                >
                    <img 
                        className={styles.like_icon} 
                        src={info.is_support ? Images.news.likeIcon : Images.news.unlikeIcon} 
                        alt='' 
                    />
                    <span 
                        className={styles.like_text}
                        style={{color: info.is_support ? '#40A6FF' : '#162533'}}
                    >
                        {Tools.overTenThousand(info.supports)}
                    </span>
                </div>
                <span 
                    className={styles.like_text}
                    onClick={() => props.onShowReplyClick(info,parentInfo,inside,true)}
                >回复</span>
            </div>
            {info.show_reply && 
                <div className={styles.input_wrap}>
                    <Form 
                        preserve={false} 
                        form={form} 
                        onFinish={(values) => props.onSendClick(values,info,parentInfo,inside)}
                        className='antd-form'
                    >
                        <Form.Item label="reply" name={info.id}>
                            <TextArea
                                placeholder={"回复" + info.user.name}
                                autoSize={{ minRows: 1, maxRows: 5 }}
                                style={{fontSize: '16px', color: '#162533', lineHeight: '22px'}}
                            />
                        </Form.Item>
                        <div className={styles.btn_wrap}>
                            <button 
                                className={styles.btn_item} 
                                style={{backgroundColor: '#DCDFE6'}}
                                onClick={() => props.onShowReplyClick(info,parentInfo,inside,false)}
                            >
                                取消
                            </button>
                            <Form.Item>
                                <button className={styles.btn_item} >发送</button>
                            </Form.Item>
                            
                        </div>
                    </Form>
                </div>
            }
            
        </div>
    )
}

export default ReplyCard
